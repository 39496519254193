import { formatDistanceStrict } from "date-fns";
import PropTypes from "prop-types";
import React from "react";

import Card, { CardContent, CardMeta } from "../OfferCardWeb/Card";
import CardMedia from "../OfferCardWeb/CardMedia";
import DefaultImage from "../OfferCardWeb/DefaultImage";
import Grid from "../OfferCardWeb/Grid";
import Icon from "../OfferCardWeb/Icon";
import OfferCard from "../OfferCardWeb/OfferCardWeb.styles";
import PointsBadge from "../OfferCardWeb/PointsBadge";
import Space from "../OfferCardWeb/Space";
import CardActionBar from "./CardActionBar";

/**
 * @render react
 * @name OfferCard
 * @description Achievement Offer Card
 * @example
 * <OfferCardComponent />
 */

const pluralize = (value, unit) => `${value} ${unit}${value === 1 ? "" : "s"}`;

const OneClickOfferCardComponent = ({ actions, offer }) => {
  return (
    <Card height="100%">
      {offer.image ? <CardMedia image={offer.image} /> : <DefaultImage />}
      <CardContent>
        {offer.compensation && (
          <OfferCard.PointsBadgeContainer>
            <PointsBadge>{pluralize(offer.compensation, "point")}</PointsBadge>
          </OfferCard.PointsBadgeContainer>
        )}
        <Grid.Flex>
          <Grid.Box flex={1}>
            <CardContent.Title>{offer.title}</CardContent.Title>
          </Grid.Box>
          <Grid.Box ml={2}>
            <Icon.Menu />
          </Grid.Box>
        </Grid.Flex>
        <Space component="div" mr={5}>
          <CardContent.Body dangerouslySetInnerHTML={{ __html: offer.body }} />
          {offer.question && (
            <Space mt={3}>
              <CardContent.Body>
                <strong>{offer.question}</strong>
              </CardContent.Body>
            </Space>
          )}
          <Space mt={5}>
            <CardMeta>
              {offer.expiryDate &&
                `Expires in ${formatDistanceStrict(
                  new Date(offer.expiryDate),
                  new Date()
                )}`}
            </CardMeta>
          </Space>
        </Space>
      </CardContent>
      {actions && (
        <Grid.Box flex="0 0 auto">
          <CardActionBar actions={actions} />
        </Grid.Box>
      )}
    </Card>
  );
};

OneClickOfferCardComponent.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      children: PropTypes.string,
    })
  ),
  offer: PropTypes.shape({
    body: PropTypes.string,
    compensation: PropTypes.number,
    duration: PropTypes.string,
    expiryDate: PropTypes.string,
    image: PropTypes.string,
    question: PropTypes.string,
    sponsored: PropTypes.bool,
    title: PropTypes.string,
  }),
};

export default OneClickOfferCardComponent;
