import * as yup from "yup";

export const validationSchema = yup.object({
  active: yup.boolean(),
  name: yup.string().required("Please enter a deep link name"),
  url: yup
    .string()
    .url("This url is not valid")
    .required("Please enter a valid url"),
  min_app_version: yup
    .string()
    .matches(/^\d+\.\d+\.\d+$/, "Version must match the pattern X.Y.Z")
    .nullable(),
  max_app_version: yup
    .string()
    .matches(/^\d+\.\d+\.\d+$/, "Version must match the pattern X.Y.Z")
    .nullable(),
});
