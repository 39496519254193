import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { serialise } from "kitsu-core";

import { useClient } from "../../ClientContext";
import { JsonApiResponse } from "../types";
import { EditableExperiment, Experiment } from "./types";

const useUpdateExperiment = (
  options?: UseMutationOptions<
    JsonApiResponse<Experiment>,
    Error,
    EditableExperiment
  >
) => {
  const queryClient = useQueryClient();
  const client = useClient();

  return useMutation<JsonApiResponse<Experiment>, Error, EditableExperiment>(
    ({ id, ...payload }: EditableExperiment) =>
      client.put(
        `/experiment_configs/${id}`,
        serialise("experiment_config", payload)
      ),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["experiment_configs"]);
        options?.onSuccess?.(data, variables, context);
      },
    }
  );
};
export default useUpdateExperiment;
