import { Card, Col, Row, Space, Typography } from "antd";
import { Form, InputNumber, Switch } from "formik-antd";
import React, { useMemo } from "react";

import ScheduleBuilder from "../../../components/ScheduleBuilder";
import SchedulePreview from "../../../components/SchedulePreview";
import AirshipEventsTable from "../../../tables/AirshipEventsTable";
import { RecurringTabFormProps } from "../EditRecurringForm";
import { getAirshipTableData } from "../EditRecurringForm.utils";

const DeliveryFormTab: React.FC<RecurringTabFormProps> = ({
  formik,
  outcomeDiary,
}) => {
  const airshipTableData = useMemo(
    () => getAirshipTableData(outcomeDiary.program?.data.identifier),
    [outcomeDiary.program?.data.identifier]
  );

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <Col span={14}>
        <Card title="Tier">
          <Form.Item label="Tier" name="tier">
            <InputNumber name="tier" />
          </Form.Item>
        </Card>
      </Col>
      <Card title="Timing">
        <Row>
          <Col span={8} style={{ marginBottom: 24 }}>
            <ScheduleBuilder
              alternateFieldPaths={{
                starts: "start_at",
                until: "end_at",
              }}
              formik={formik}
              onClear={() => {
                formik.setFieldValue("entry_schedule", null);
                formik.setFieldValue("start_at", null);
                formik.setFieldValue("end_at", null);
              }}
              path="entry_schedule[0]"
            />
          </Col>
          <Col offset={1} span={12}>
            <SchedulePreview
              end_at={formik.values.end_at}
              schedule={formik.values.entry_schedule}
              start_at={formik.values.start_at}
            />
          </Col>
        </Row>
        <Form.Item
          label="Each occurence expires after:"
          name="expiration_hours"
        >
          <Row align="middle">
            <InputNumber name="expiration_hours" />
            <Typography.Text style={{ marginLeft: 10 }}>Hours</Typography.Text>
          </Row>
        </Form.Item>
        <Row>
          <Col offset={2} span={10}>
            <Typography.Text type="secondary">
              Please note: a Recurring Engagement will remain in a user's
              carousel until it has expired, regardless of the end date.
            </Typography.Text>
          </Col>
        </Row>
      </Card>
      <Col span={14}>
        <Card title="Airship Custom Events">
          <Form.Item label="Program Identifier" name="program_identifier">
            {outcomeDiary.program?.data.identifier}
          </Form.Item>
          <Form.Item label="Offer Identifier" name="offer_identifier">
            {outcomeDiary.identifier}
          </Form.Item>
          <Card title="Available Airship Custom Events">
            <AirshipEventsTable data={airshipTableData} />
          </Card>
        </Card>
      </Col>
      <Card title="Labels">
        <Form.Item
          label="Revenue Generation"
          name="revenue_generating"
          tooltip="Please check box for offers that are linked to revenue contracts"
        >
          <Switch name="revenue_generating" />
        </Form.Item>
      </Card>
    </Space>
  );
};
export default DeliveryFormTab;
