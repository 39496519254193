import { DatePicker, Row } from "antd";
import { useField } from "formik";
import { Form } from "formik-antd";
import moment from "moment";
import React from "react";

import { Schedule } from "../../../client";
import { ScheduleBuilderProps } from "../ScheduleBuilder";

const YearView: React.FC<ScheduleBuilderProps> = ({ path }) => {
  const [monthField, , monthHelpers] = useField<Schedule["month"]>(
    `${path}.month`
  );
  const [dayField, , dayHelpers] = useField<Schedule["mday"]>(`${path}.mday`);
  const date =
    monthField.value && dayField.value
      ? moment(
          `${monthField.value[0]}-${
            dayField.value[0]
          }-${new Date().getFullYear()}`,
          "MM-DD-YYYY"
        )
      : undefined;
  return (
    <Form.Item label="Repeat On" name={`${path}.month`}>
      <Row>
        <DatePicker
          allowClear
          name={`${path}.month`}
          onChange={(value) => {
            if (value) {
              monthHelpers.setValue([value.month() + 1]);
              dayHelpers.setValue([value.date()]);
            }
          }}
          showTime={false}
          value={date}
        />
      </Row>
    </Form.Item>
  );
};

export default YearView;
