import { get } from "lodash";
import styled from "styled-components";

const CardContent = styled.div.attrs({
  "data-testid": "card-content",
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 24px;
  position: relative;
`;

CardContent.Title = styled.h1.attrs({
  "data-testid": "card-title",
})`
  color: ${(props) => get(props, "theme.palette.base.dark")};
  font-size: 20px;
  line-height: ${26 / 20};
  margin-bottom: 16px;
`;

CardContent.SubTitle = styled.h2.attrs({
  "data-testid": "card-title",
})`
  color: rgba(42, 63, 83, 0.4);
  font-size: 16px;
  font-family: PublicSans-Regular;
  line-height: ${26 / 20};
  margin-top: 0px;
  margin-bottom: 8px;
`;

CardContent.Body = styled.div.attrs({
  "data-testid": "card-body",
})`
  color: ${(props) => get(props, "theme.palette.base.medium")};
  flex: 1;
  font-size: 14px;
  line-height: ${18 / 14};

  strong {
    font-family: PublicSans-SemiBold;
  }

  > :last-child {
    margin-bottom: 0;
  }
`;

export default CardContent;
