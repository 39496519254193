import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { OfferStatsCollection, OfferStatsParams } from "./types";

const useOfferInteractionCounts = <
  TData extends {} = JsonApiResponse<OfferStatsCollection>
>(
  offerId: string,
  params: OfferStatsParams,
  queryOptions?: UseQueryOptions<
    JsonApiResponse<OfferStatsCollection>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();
  return useQuery<
    JsonApiResponse<OfferStatsCollection>,
    PortalClientError,
    TData
  >(
    ["offerInteractionCounts", offerId, params],
    () =>
      client.get(`/offers/${offerId}/interaction_counts`, {
        params,
      }),
    {
      refetchOnWindowFocus: false,
      ...queryOptions,
    }
  );
};
export default useOfferInteractionCounts;
