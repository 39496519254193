import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { Offer } from "./types";

const useSendAppOffers = (
  offerId: string,
  queryOptions?: UseMutationOptions<JsonApiResponse<Offer>, PortalClientError>
) => {
  const client = useClient();

  return useMutation<JsonApiResponse<Offer>, PortalClientError>(
    () => client.post(`/offers/${offerId}/send_app_offer_from_csv`),
    queryOptions
  );
};

export default useSendAppOffers;
