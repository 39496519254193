import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosRequestConfig } from "axios";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { InsightType } from "./types";

const defaultAxiosConfig = {
  params: {
    "page[size]": 1000,
  },
};

const useInsightTypes = <TData extends {} = JsonApiResponse<InsightType[]>>(
  options?: UseQueryOptions<
    JsonApiResponse<InsightType[]>,
    PortalClientError,
    TData
  >,
  axiosConfig?: AxiosRequestConfig
) => {
  const client = useClient();

  const combinedAxiosConfig = Object.assign(defaultAxiosConfig, axiosConfig);

  return useQuery<JsonApiResponse<InsightType[]>, PortalClientError, TData>(
    ["insight_types"],
    () => client.get("/insight_types", combinedAxiosConfig),
    options
  );
};

export default useInsightTypes;
