import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { useClient, useQueryClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { InsightType, UpdateInsightType } from "./types";

const useUpdateInsightType = (
  insightTypeId: string,
  options?: UseMutationOptions<
    JsonApiResponse<InsightType>,
    PortalClientError,
    UpdateInsightType
  >
) => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<
    JsonApiResponse<InsightType>,
    PortalClientError,
    UpdateInsightType
  >((payload) => client.patch(`/insight_types/${insightTypeId}`, payload), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["insight_types"]);
      options?.onSuccess?.(data, variables, context);
    },
  });
};

export default useUpdateInsightType;
