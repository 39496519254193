import { Col, Row, Select } from "antd";
import { useField } from "formik";
import { Form } from "formik-antd";
import React from "react";

import { Schedule } from "../../../client";
import { ScheduleBuilderProps } from "../ScheduleBuilder";
import { daysInMonth, daysInWeek, getOrdinalName } from "../utils";

type MonthSelectionType = "first" | "last" | "on" | undefined;

const getInitialType = (
  dayValue?: any,
  mDayValue?: number[]
): MonthSelectionType => {
  if (!dayValue && !mDayValue) {
    return undefined;
  } else if (mDayValue && Array.isArray(mDayValue)) {
    return "on";
  } else if (dayValue) {
    return dayValue[getKey(dayValue)][0] === 1 ? "first" : "last";
  }
};

/**
 * Takes a day object and returns the key representing the day of the week. { "3": [1] } => 3
 * @param value object to retrieve the key from
 * @returns the first key on an object, or 0 if value is undefined
 */
export const getKey = (value?: {}): number =>
  value ? parseInt(Object.keys(value)[0]) : 0;

const MonthView: React.FC<ScheduleBuilderProps> = ({ path }) => {
  const [dayField, , dayHelpers] = useField<Schedule["day"]>(`${path}.day`);
  const [mDayField, , mDayHelpers] = useField<Schedule["mday"]>(`${path}.mday`);
  const [type, setType] = React.useState(
    getInitialType(dayField.value, mDayField.value)
  );
  return (
    <Form.Item label="Repeat On" name={`${path}.day`}>
      <Row>
        <Col span={6}>
          <Select
            onChange={(value: MonthSelectionType) => {
              setType(value);
              if (value === "on") {
                mDayHelpers.setValue([1]);
                dayHelpers.setValue(undefined);
              } else {
                dayHelpers.setValue({
                  [getKey(dayField.value)]: [value === "first" ? 1 : -1],
                });
                mDayHelpers.setValue(undefined);
              }
            }}
            value={type}
          >
            <Select.Option value="first">The first</Select.Option>
            <Select.Option value="last">The last</Select.Option>
            <Select.Option value="on">On the...</Select.Option>
          </Select>
        </Col>
        <Col offset={1} span={6}>
          {type === "on" && ( // On a specific date (e.g. "13th")
            <Select
              onChange={(value) => mDayHelpers.setValue([value])}
              value={(mDayField.value ?? [1])[0]}
            >
              {daysInMonth.map((date: number) => (
                <Select.Option key={date} value={date}>
                  {getOrdinalName(date)}
                </Select.Option>
              ))}
            </Select>
          )}
          {(type === "first" || type === "last") && ( // On the first/last occurrance in month (e.g. "The first monday")
            <Select<any>
              onChange={(value) => {
                dayHelpers.setValue({
                  [value]: [type === "first" ? 1 : -1],
                });
              }}
              style={{ textTransform: "capitalize" }}
              value={
                dayField.value
                  ? daysInWeek[getKey(dayField.value)].key
                  : undefined
              }
            >
              {daysInWeek.map((day, index) => (
                <Select.Option
                  key={day.key}
                  style={{ textTransform: "capitalize" }}
                  value={index}
                >
                  {day.key}
                </Select.Option>
              ))}
            </Select>
          )}
        </Col>
      </Row>
    </Form.Item>
  );
};

export default MonthView;
