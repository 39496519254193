import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { UserActionPayload } from "./types";

const useResetUserPassword = (
  options?: UseMutationOptions<void, PortalClientError, UserActionPayload>
) => {
  const client = useClient();

  return useMutation<void, PortalClientError, UserActionPayload>(
    ({ id }) => client.post(`/users/${id}/reset_password`),
    {
      ...options,
    }
  );
};
export default useResetUserPassword;
