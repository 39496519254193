import { Menu } from "antd";

import { OutcomeDiary } from "../../client";
import ActivateRecurringModal from "../../modals/ActivateRecurringModal";

const EditRecurringActions = (outcomeDiary: OutcomeDiary) => {
  return (
    <Menu>
      <Menu.Item>
        <ActivateRecurringModal
          activate={!!outcomeDiary.draft}
          outcomeDiaryId={outcomeDiary.id}
          renderButton={(open) => (
            <div onClick={open} role="button">
              {outcomeDiary.draft ? "Activate" : "Deactivate"}
            </div>
          )}
        />
      </Menu.Item>
    </Menu>
  );
};
export default EditRecurringActions;
