import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { serialise } from "kitsu-core";

import { useClient } from "../../ClientContext";
import { JsonApiResponse } from "../types";
import { Experiment, CreateExperiment } from "./types";

const useCreateExperiment = (
  options?: UseMutationOptions<
    JsonApiResponse<Experiment>,
    Error,
    CreateExperiment
  >
) => {
  const queryClient = useQueryClient();
  const client = useClient();

  return useMutation<JsonApiResponse<Experiment>, Error, CreateExperiment>(
    (payload) =>
      client.post(
        "/experiment_configs",
        serialise("experiment_config", payload)
      ),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["experiment_configs"]);
        options?.onSuccess?.(data, variables, context);
      },
    }
  );
};
export default useCreateExperiment;
