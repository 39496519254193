import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { PortalClientError, CreateApplication } from "../../../client";
import { useClient } from "../../ClientContext";
import { JsonApiResponse } from "../types";
import { Application } from "./types";

const useCreateApplication = (
  queryOptions?: UseMutationOptions<
    JsonApiResponse<Application>,
    PortalClientError,
    CreateApplication
  >
) => {
  const queryClient = useQueryClient();
  const client = useClient();

  return useMutation<
    JsonApiResponse<Application>,
    PortalClientError,
    CreateApplication
  >(
    (payload) => {
      return client.post(`/applications`, payload);
    },
    {
      ...queryOptions,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["applications"]);
        queryOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};

export default useCreateApplication;
