import { Box, Radio } from "@evidation-shared/eve";

import OptionGroup from "./OptionGroup";
import type { Option, RadioOptionGroupProps } from "./OptionGroup.types";

const RadioOptionGroup = (props: RadioOptionGroupProps) => {
  const {
    disabled,
    name,
    onChange,
    options,
    variant = "default",
    value = "",
  } = props;

  const handleChange = (selectedValue: string) => {
    onChange?.(selectedValue);
  };

  return (
    <OptionGroup {...props}>
      <Radio.Group name={name} onChange={handleChange} value={value}>
        {options.map((option: Option) => {
          return (
            <Box key={option.value.toString()} w="100%">
              <Radio
                isDisabled={disabled || option.disabled}
                my={variant === "bordered" ? 1 : 2}
                value={option.value}
                variant={variant}
              >
                {option.label}
              </Radio>
            </Box>
          );
        })}
      </Radio.Group>
    </OptionGroup>
  );
};

export default RadioOptionGroup;
