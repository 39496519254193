import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { UserNote } from "./types";

const useUserNotes = <TData extends {} = JsonApiResponse<UserNote[]>>(
  id: string,
  queryOptions?: UseQueryOptions<
    JsonApiResponse<UserNote[]>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<UserNote[]>, PortalClientError, TData>(
    ["userNotes", id],
    () => client.get(`/users/${id}/notes`),
    {
      refetchOnWindowFocus: false,
      ...queryOptions,
    }
  );
};

export default useUserNotes;
