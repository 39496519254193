import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { Program, UpdateProgram } from "./types";

const useCreateProgram = (
  options?: UseMutationOptions<
    JsonApiResponse<Program>,
    PortalClientError,
    UpdateProgram
  >
) => {
  const client = useClient();

  return useMutation<
    JsonApiResponse<Program>,
    PortalClientError,
    UpdateProgram
  >((payload) => client.post(`/programs`, payload), options);
};

export default useCreateProgram;
