import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { JsonApiResponse } from "../types";
import { ComputeReachPayload, ComputeReachResult } from "./types";

const useComputeReach = (
  offerIdentifier: string,
  options?: UseMutationOptions<
    JsonApiResponse<ComputeReachResult>,
    Error,
    ComputeReachPayload
  >
) => {
  const client = useClient();

  return useMutation<
    JsonApiResponse<ComputeReachResult>,
    Error,
    ComputeReachPayload
  >(
    (variables) =>
      client.get(`/offers/${offerIdentifier}/compute_reach`, {
        params: variables,
      }),
    options
  );
};
export default useComputeReach;
