import { Col, Form, Row } from "antd";
import { useFormikContext } from "formik";
import { Input } from "formik-antd";
import React from "react";

import { OutcomeQuestion } from "../../../client";
import { snakeCase } from "../../../utils/format";
import { OutcomeQuestionConfigurationProps } from "./OutcomeQuestionConfiguration";

const ButtonsOutcomeQuestion: React.FunctionComponent<
  OutcomeQuestionConfigurationProps
> = ({ disabled = false }) => {
  const { setFieldValue } = useFormikContext<OutcomeQuestion>();
  return (
    <Form.Item
      help="These buttons will be displayed similarly to one-click offer cards"
      label="Buttons"
      name="configuration.buttons"
      style={{ marginBottom: 24 }}
    >
      <Row>
        <Col span={11}>
          <Input
            disabled={disabled}
            name="configuration.buttons[0].label"
            onChange={(e) =>
              setFieldValue(
                "configuration.buttons[0].value",
                snakeCase(e.target.value)
              )
            }
            placeholder='"Yes" label'
          />
        </Col>
        <Col offset={2} span={11}>
          <Input
            disabled={disabled}
            name="configuration.buttons[1].label"
            onChange={(e) =>
              setFieldValue(
                "configuration.buttons[1].value",
                snakeCase(e.target.value)
              )
            }
            placeholder='"No" label'
          />
        </Col>
      </Row>
    </Form.Item>
  );
};

export default ButtonsOutcomeQuestion;
