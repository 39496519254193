import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { Image } from "./types";

const useImages = <TData extends {} = JsonApiResponse<Image[]>>(
  kind: string,
  queryOptions?: UseQueryOptions<
    JsonApiResponse<Image[]>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<Image[]>, PortalClientError, TData>(
    ["images", kind],
    () => client.get(`/images`, { params: { kind } }),
    {
      refetchOnWindowFocus: false,
      ...queryOptions,
    }
  );
};

export default useImages;
