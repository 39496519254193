import Head from "@uiw/react-head";

import useWorkspaceSettings from "../client/hooks/auth/useWorkspaceSettings";

/**
 * Loads the website favicon using the workspace settings endpoint.
 */
const FaviconLoader = () => {
  const workspaceSettingsQuery = useWorkspaceSettings();
  if (!workspaceSettingsQuery.data) {
    return null;
  }
  return (
    <Head.Link
      href={workspaceSettingsQuery.data.admin_favicon_url}
      rel="icon"
    />
  );
};

export default FaviconLoader;
