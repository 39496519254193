import { Box, Checkbox, FormControl, VStack } from "@evidation-shared/eve";
import React from "react";

import { getUpdatedValue } from "./CheckboxGroup.utils";
import { CheckboxGroupProps } from "./Survey.types";

const CheckboxGroup = ({
  options,
  variant = "bordered",
}: CheckboxGroupProps) => {
  const [formValue, setFormValue] = React.useState([]);

  // @ts-ignore
  const handleChange = (values) => {
    const updatedValue = getUpdatedValue(values, options);
    // @ts-ignore
    setFormValue(updatedValue);
  };

  return (
    <FormControl>
      <Checkbox.Group onChange={handleChange} value={formValue}>
        <VStack space={variant === "default" ? 4 : 2} width="100%">
          {options.map((option) => {
            return (
              <Box key={option.label} w="100%">
                <Checkbox value={option.value} variant={variant}>
                  {option.label}
                </Checkbox>
              </Box>
            );
          })}
        </VStack>
      </Checkbox.Group>
    </FormControl>
  );
};

export default CheckboxGroup;
