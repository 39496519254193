import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import {
  getOutcomeResponseScreenerValue,
  removeCircularDiaryReferences,
} from "../../../utils/outcomeDiaries";
import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { OutcomeDiary } from "./types";

const useOutcomeDiary = <TData extends {} = JsonApiResponse<OutcomeDiary>>(
  outcomeDiaryID: string,
  queryOptions?: UseQueryOptions<
    JsonApiResponse<OutcomeDiary>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<OutcomeDiary>, PortalClientError, TData>(
    ["outcome_diaries", outcomeDiaryID],
    () => client.get(`/outcome_diaries/${outcomeDiaryID}`),
    {
      refetchOnWindowFocus: false,
      ...queryOptions,
      select: (outcomeDiary) => {
        const transformed: JsonApiResponse<OutcomeDiary> = {
          data: {
            ...removeCircularDiaryReferences(outcomeDiary.data),
            outcome_question_responses_for_screener_value:
              getOutcomeResponseScreenerValue(outcomeDiary.data),
          },
        };
        return (
          queryOptions?.select ? queryOptions.select(transformed) : transformed
        ) as TData;
      },
    }
  );
};

export default useOutcomeDiary;
