import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { useClient, useQueryClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { UserActionPayload } from "./types";

const useUserCCPADelete = (
  queryOptions?: UseMutationOptions<void, PortalClientError, UserActionPayload>
) => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<void, PortalClientError, UserActionPayload>(
    ({ id }) => client.post(`/users/${id}/start_ccpa_delete`),
    {
      ...queryOptions,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["users", variables.id]);
        queryOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};
export default useUserCCPADelete;
