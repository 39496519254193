import { Offer, OfferSurvey, Image } from "../client";

export interface QueryComponent<T> {
  children: (params: T) => React.ReactNode;
}

export interface RenderPropsBase {
  hasError: boolean;
  isFetching: boolean;
  isComplete: boolean;
}

export interface FormProps<T = undefined> {
  onError?: () => void;
  onSuccess?: (response?: T) => void;
}

export interface AddableOffer {
  card_action_description?: string;
  card_action_url?: string;
  card_body?: string;
  card_header?: string;
  compensation_kind?: string;
  compensation_label?: string;
  complete_on_click?: boolean;
  email_body?: string;
  email_header_image_path?: Image;
  email_subject?: string;
  feed_description?: string;
  identifier: string;
  image_filename?: string;
  kind: string;
  points?: number;
  seconds_to_complete?: number;
  success_content_heading?: string;
  success_content_text?: string;
  success_navigation_label?: string;
  survey?: OfferSurvey;
  targeted?: boolean;
  targeting_rule?: string;
  tier?: number;
}

export interface EditableOffer extends Offer {
  minutes_to_complete?: number;
  file?: BinaryType;
}

export enum OfferStatus {
  Draft = "draft",
  Live = "live",
  Expired = "expired",
}

export enum SurveyQuestionKind {
  OneClick = "one-click",
  Checkbox = "checkbox",
  Radio = "radio",
  ProgramEnrollment = "program-enrollment",
  TextField = "text-field",
  Numerical = "numerical",
  DatePicker = "date-picker",
}

export interface Device {
  device_id?: string;
  device_id_type?: string;
  id: string;
  platform?: string;
}
