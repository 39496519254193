import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { serialise } from "kitsu-core";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { DeepLink, UpdateDeepLinkVaribales } from "./types";
import deepLinkKeys from "./utils";

const useUpdateDeepLink = (
  queryOptions?: UseMutationOptions<
    JsonApiResponse<DeepLink>,
    PortalClientError,
    UpdateDeepLinkVaribales
  >
) => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<
    JsonApiResponse<DeepLink>,
    PortalClientError,
    UpdateDeepLinkVaribales
  >(
    ({ id, ...payload }) =>
      client.put(`/deep_links/${id}`, serialise("deep_link", payload)),
    {
      ...queryOptions,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(deepLinkKeys.list());

        queryOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};

export default useUpdateDeepLink;
