import { Button, notification, Space } from "antd";
import { Formik, FormikProps } from "formik";
import { Form, Input } from "formik-antd";
import { serialise } from "kitsu-core";
import React from "react";
import * as yup from "yup";

import Notifications from "../assets/data/notifications";
import { Program, useCreateProgram } from "../client";
import { snakeCase } from "../utils/format";

const validationSchema = yup.object({
  identifier: yup.string().required("An identifier is required"),
  name: yup.string().required("A name is required"),
});

const initialValues: Program = {
  id: "",
  identifier: "",
  name: "",
  type: "program",
  consent:
    "This program does not provide real-time health monitoring and is not meant to provide medical advice. Please work with your healthcare provider to address any symptoms or questions you may have.",
};

interface AddProgramFormProps {
  onSuccess: (program: Program) => void;
}

const AddProgramForm: React.FC<AddProgramFormProps> = ({ onSuccess }) => {
  const { mutateAsync: createProgram, isLoading } = useCreateProgram({
    onSuccess: (newProgram) => {
      notification.success({
        message: "Success",
        description: Notifications.AddProgram.success,
      });
      onSuccess?.(newProgram.data);
    },
    onError: () => {
      notification.error({
        message: "Error",
        description: Notifications.AddProgram.error,
      });
    },
  });

  var handleSubmit = async (values: Program) => {
    await createProgram(serialise("program", values));
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ dirty, isValid, setFieldValue }: FormikProps<Program>) => (
        <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
          <Form.Item label="Name" name="name">
            <Input
              name="name"
              onChange={(event) =>
                setFieldValue("identifier", snakeCase(event.target.value))
              }
              placeholder="Enter Program Name"
            />
          </Form.Item>
          <Form.Item label="Identifier" name="identifier">
            <Input
              name="identifier"
              onChange={(event) =>
                setFieldValue("identifier", snakeCase(event.target.value))
              }
              placeholder="Enter Program Identifier"
            />
          </Form.Item>
          <Form.Item
            name="buttons"
            wrapperCol={{ push: 8, span: 8, flex: "center" }}
          >
            <Space size="large">
              <Button danger htmlType="reset">
                Reset
              </Button>
              <Button
                disabled={isLoading || !isValid || !dirty}
                htmlType="submit"
                type="primary"
              >
                Next
              </Button>
            </Space>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
};
export default AddProgramForm;
