import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { BulkUnenrollPayload, BulkUnenrollResponse } from "./types";

const useBulkUnenroll = (
  programId: string,
  queryOptions?: UseMutationOptions<
    JsonApiResponse<BulkUnenrollResponse>,
    PortalClientError,
    BulkUnenrollPayload
  >
) => {
  const client = useClient();

  return useMutation<
    JsonApiResponse<BulkUnenrollResponse>,
    PortalClientError,
    BulkUnenrollPayload
  >((payload) => {
    const formData = new FormData();
    formData.append("file", payload.file);
    formData.append("dry_run", `${payload.dry_run}`);
    return client.patch(`/programs/${programId}/bulk_unenroll`, formData);
  }, queryOptions);
};

export default useBulkUnenroll;
