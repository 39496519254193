import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { IndexSearchProps, JsonApiResponse } from "../types";
import { UseEventsParams, Event } from "./types";

const useEvents = (
  params: IndexSearchProps<UseEventsParams>,
  queryOptions?: UseQueryOptions<JsonApiResponse<Event[]>, PortalClientError>
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<Event[]>, PortalClientError>(
    ["events", params],
    () =>
      client.get("/events", {
        params,
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      ...queryOptions,
    }
  );
};

export default useEvents;
