import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { OutcomeDiary, UpdateAttachOutcomeQuestion } from "./types";

const useAttachOutcomeQuestionToDiary = (
  outcomeDiaryID: string,
  options?: UseMutationOptions<
    JsonApiResponse<OutcomeDiary>,
    PortalClientError,
    UpdateAttachOutcomeQuestion
  >
) => {
  const client = useClient();

  return useMutation<
    JsonApiResponse<OutcomeDiary>,
    PortalClientError,
    UpdateAttachOutcomeQuestion
  >(
    (payload) =>
      client.patch(`/outcome_diaries/${outcomeDiaryID}/add_question`, payload),
    options
  );
};

export default useAttachOutcomeQuestionToDiary;
