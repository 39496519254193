import * as React from "react";

const DefaultImage = (props) => (
  <svg
    fill="none"
    height={180}
    width={343}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path d="M0 0h343v180H0z" fill="url(#b)" />
      <path
        clipRule="evenodd"
        d="M126.574 149.067h105.207V34.429H126.574v114.638Zm-6.969 7.593h119.143V26.837H119.605V156.66Z"
        fill="#fff"
        fillRule="evenodd"
      />
      <mask
        height={131}
        id="c"
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "alpha",
        }}
        width={120}
        x={119}
        y={26}
      >
        <path
          clipRule="evenodd"
          d="M119.605 154.661a2 2 0 0 0 2 2h115.143a2 2 0 0 0 2-2V28.836a2 2 0 0 0-2-2H121.605a2 2 0 0 0-2 2v125.825Zm6.969-7.594a2 2 0 0 0 2 2h101.207a2 2 0 0 0 2-2V36.429a2 2 0 0 0-2-2H128.574a2 2 0 0 0-2 2v110.638Z"
          fill="#fff"
          fillRule="evenodd"
        />
      </mask>
      <g mask="url(#c)">
        <path
          clipRule="evenodd"
          d="M231.621 36.427a2 2 0 0 0-2-1.998H128.906a2 2 0 0 0-2 2v110.638a2 2 0 0 0 2 2H229.71a2 2 0 0 0 2-2.001l-.089-110.639ZM119.816 154.66a2 2 0 0 0 2 2h117.225a2 2 0 0 0 2-2V28.836a2 2 0 0 0-2-2H121.816a2 2 0 0 0-2 2V154.66Z"
          fill="#3956A7"
          fillRule="evenodd"
        />
      </g>
      <path
        d="M128.772 149.317h101.021v-.5H128.772v.5Zm103.271-2.25V36.428h-.5v110.639h.5Zm-2.25-112.889H128.772v.5h101.021v-.5Zm-103.271 2.25v110.639h.5V36.428h-.5Zm-4.706 120.481h114.933v-.5H121.816v.5Zm117.183-2.25V28.836h-.5V154.66h.5Zm-2.25-128.073H121.816v.5h114.933v-.5Zm-117.183 2.25V154.66h.5V28.836h-.5Zm2.25-2.25a2.25 2.25 0 0 0-2.25 2.25h.5c0-.966.784-1.75 1.75-1.75v-.5Zm117.183 2.25a2.25 2.25 0 0 0-2.25-2.25v.5c.966 0 1.75.784 1.75 1.75h.5Zm-2.25 128.073a2.25 2.25 0 0 0 2.25-2.25h-.5a1.75 1.75 0 0 1-1.75 1.75v.5Zm-114.933-.5a1.75 1.75 0 0 1-1.75-1.75h-.5a2.25 2.25 0 0 0 2.25 2.25v-.5Zm6.956-122.23a2.25 2.25 0 0 0-2.25 2.25h.5c0-.967.784-1.75 1.75-1.75v-.5Zm103.271 2.25a2.25 2.25 0 0 0-2.25-2.25v.5c.967 0 1.75.783 1.75 1.75h.5Zm-2.25 112.888a2.25 2.25 0 0 0 2.25-2.25h-.5a1.75 1.75 0 0 1-1.75 1.75v.5Zm-101.021-.5a1.75 1.75 0 0 1-1.75-1.75h-.5a2.25 2.25 0 0 0 2.25 2.25v-.5Z"
        fill="#000"
      />
      <path
        clipRule="evenodd"
        d="M126 150h107V34H126v116Z"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M217.52 26.859h-76.684v-3.95a2.303 2.303 0 0 1 2.303-2.302h72.078a2.303 2.303 0 0 1 2.303 2.303v3.949Z"
        fill="#AFC3EE"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M217.52 26.859h-76.684v-3.95a2.303 2.303 0 0 1 2.303-2.302h72.078a2.303 2.303 0 0 1 2.303 2.303v3.949Z"
        fill="#AFC3EE"
        fillRule="evenodd"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
      />
      <path
        clipRule="evenodd"
        d="M208.988 20.592h-59.621v-6.29A2.303 2.303 0 0 1 151.67 12h55.015a2.303 2.303 0 0 1 2.303 2.303v6.289Z"
        fill="#AFC3EE"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M208.988 20.592h-59.621v-6.29A2.303 2.303 0 0 1 151.67 12h55.015a2.303 2.303 0 0 1 2.303 2.303v6.289Z"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
      />
      <path
        clipRule="evenodd"
        d="M206.155 38.034H152.2a2.833 2.833 0 0 1-2.833-2.833v-8.365h59.621v8.365a2.833 2.833 0 0 1-2.833 2.833Z"
        fill="#AFC3EE"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M206.155 38.034H152.2a2.833 2.833 0 0 1-2.833-2.833v-8.365h59.621v8.365a2.833 2.833 0 0 1-2.833 2.833Z"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
      />
      <path
        clipRule="evenodd"
        d="M132.109 68.532c0 .644.522 1.165 1.166 1.165h23.282c.643 0 1.165-.521 1.165-1.165V50.236c0-.644-.522-1.165-1.165-1.165h-23.282c-.644 0-1.166.521-1.166 1.165v18.296Z"
        fill="#D5DFF6"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M167.066 63.947c.241.19.58.19.822 0 1.079-.844 3.949-3.173 4.567-4.504.764-1.646.156-3.978-1.842-4.625-2-.647-3.137 1.156-3.137 1.156s-1.136-1.803-3.135-1.156c-1.999.647-2.607 2.98-1.842 4.626.617 1.33 3.488 3.659 4.567 4.503ZM180.433 63.947c.242.19.58.19.822 0 1.079-.844 3.949-3.173 4.567-4.504.764-1.646.157-3.978-1.842-4.625-2-.647-3.136 1.156-3.136 1.156s-1.137-1.803-3.136-1.156-2.606 2.98-1.842 4.626c.617 1.33 3.488 3.659 4.567 4.503ZM193.8 63.947c.242.19.58.19.822 0 1.079-.844 3.949-3.173 4.567-4.504.764-1.646.157-3.978-1.842-4.625-1.999-.647-3.136 1.156-3.136 1.156s-1.137-1.803-3.136-1.156-2.606 2.98-1.842 4.626c.618 1.33 3.488 3.659 4.567 4.503ZM207.171 63.947c.242.19.58.19.822 0 1.079-.844 3.949-3.173 4.567-4.504.765-1.646.157-3.978-1.842-4.625-1.999-.647-3.136 1.156-3.136 1.156s-1.137-1.803-3.136-1.156-2.606 2.98-1.842 4.626c.618 1.33 3.488 3.659 4.567 4.503Z"
        fill="#3956A7"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M220.538 63.947c.242.19.581.19.823 0 1.078-.844 3.948-3.173 4.567-4.504.764-1.646.156-3.978-1.843-4.625-1.999-.647-3.136 1.156-3.136 1.156s-1.137-1.803-3.136-1.156c-1.998.647-2.606 2.98-1.842 4.626.618 1.33 3.489 3.659 4.567 4.503Z"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M132.109 101.423c0 .644.522 1.166 1.166 1.166h23.282c.643 0 1.165-.522 1.165-1.166V83.127c0-.643-.522-1.165-1.165-1.165h-23.282c-.644 0-1.166.522-1.166 1.165v18.296Z"
        fill="#D5DFF6"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M167.066 96.84c.241.189.58.189.822 0 1.079-.845 3.949-3.174 4.567-4.504.764-1.646.156-3.979-1.842-4.625-2-.648-3.137 1.156-3.137 1.156s-1.136-1.804-3.135-1.156c-1.999.646-2.607 2.979-1.842 4.625.617 1.33 3.488 3.659 4.567 4.504ZM180.433 96.84c.242.189.58.189.822 0 1.079-.845 3.949-3.174 4.567-4.504.764-1.646.157-3.979-1.842-4.625-2-.648-3.136 1.156-3.136 1.156s-1.137-1.804-3.136-1.156c-1.999.646-2.606 2.979-1.842 4.625.617 1.33 3.488 3.659 4.567 4.504ZM193.8 96.84c.242.189.58.189.822 0 1.079-.845 3.949-3.174 4.567-4.504.764-1.646.157-3.979-1.842-4.625-1.999-.648-3.136 1.156-3.136 1.156s-1.137-1.804-3.136-1.156c-1.999.646-2.606 2.979-1.842 4.625.618 1.33 3.488 3.659 4.567 4.504Z"
        fill="#3956A7"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M207.171 96.84c.242.189.58.189.822 0 1.079-.845 3.949-3.174 4.567-4.504.765-1.646.157-3.979-1.842-4.625-1.999-.648-3.136 1.156-3.136 1.156s-1.137-1.804-3.136-1.156c-1.999.646-2.606 2.979-1.842 4.625.618 1.33 3.488 3.659 4.567 4.504ZM220.538 96.84c.242.189.581.189.823 0 1.078-.845 3.948-3.174 4.567-4.504.764-1.646.156-3.979-1.843-4.625-1.999-.648-3.136 1.156-3.136 1.156s-1.137-1.804-3.136-1.156c-1.998.646-2.606 2.979-1.842 4.625.618 1.33 3.489 3.659 4.567 4.504Z"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M132.109 134.855c0 .644.522 1.166 1.166 1.166h23.282c.643 0 1.165-.522 1.165-1.166v-18.296c0-.643-.522-1.165-1.165-1.165h-23.282c-.644 0-1.166.522-1.166 1.165v18.296Z"
        fill="#D5DFF6"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M167.066 130.272c.241.189.58.189.822 0 1.079-.845 3.949-3.174 4.567-4.504.764-1.646.156-3.979-1.842-4.625-2-.648-3.137 1.156-3.137 1.156s-1.136-1.804-3.135-1.156c-1.999.646-2.607 2.979-1.842 4.625.617 1.33 3.488 3.659 4.567 4.504ZM180.433 130.272c.242.189.58.189.822 0 1.079-.845 3.949-3.174 4.567-4.504.764-1.646.157-3.979-1.842-4.625-2-.648-3.136 1.156-3.136 1.156s-1.137-1.804-3.136-1.156c-1.999.646-2.606 2.979-1.842 4.625.617 1.33 3.488 3.659 4.567 4.504ZM193.8 130.272c.242.189.58.189.822 0 1.079-.845 3.949-3.174 4.567-4.504.764-1.646.157-3.979-1.842-4.625-1.999-.648-3.136 1.156-3.136 1.156s-1.137-1.804-3.136-1.156c-1.999.646-2.606 2.979-1.842 4.625.618 1.33 3.488 3.659 4.567 4.504ZM207.171 130.272c.242.189.58.189.822 0 1.079-.845 3.949-3.174 4.567-4.504.765-1.646.157-3.979-1.842-4.625-1.999-.648-3.136 1.156-3.136 1.156s-1.137-1.804-3.136-1.156c-1.999.646-2.606 2.979-1.842 4.625.618 1.33 3.488 3.659 4.567 4.504ZM220.538 130.272a.668.668 0 0 0 .823 0c1.078-.845 3.948-3.174 4.567-4.504.764-1.646.156-3.979-1.843-4.625-1.999-.648-3.136 1.156-3.136 1.156s-1.137-1.804-3.136-1.156c-1.998.646-2.606 2.979-1.842 4.625.618 1.33 3.489 3.659 4.567 4.504Z"
        fill="#3956A7"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="m86.867 112.957-5.905 11.786-4.911 9.412-3.614 6.274-.934 1.823s-.952 1.228-3.3 1.4c-2.349.172-3.987.173-5.404-.01-1.416-.184-2.266-.226-1.835-.857.431-.629 1.264-.984 1.264-.984s-3.413-1.212-4.43-2.362c-1.015-1.151-1.423-1.948-.134-2.379 1.288-.431 3.056-.832 4.868-.518 1.813.314 3.015 1.283 3.966.805.95-.478 3.022-4.975 3.022-4.975l5.801-12.502 4.437-10.592 7.11 3.679ZM122.649 100.081l5.667 4.789 5.33 4.658-2.774 6.599-12.29-8.267s1.722-8.213 4.067-7.779ZM104.976 74.979s-.336 2.967.065 3.892c.4.923 1.382 2.097 2.381 2.967.997.87 1.077 1.053.437 1.766-.639.714-1.055 1.1-.951 1.996.103.895.416 2.247-.479 2.742-.893.495-3.692 1.144-3.692 1.144s-.059 1.389.205 1.849c.263.46 1.132 1.517 1.705 1.577 0 0 .949 2.351-1.047 3.622-1.995 1.27-6.813.493-8.09-.884-1.278-1.38-.98-1.896-.98-1.896s1.892-1.544 1.965-2.91c.072-1.365-.237-6.13-.237-6.13-1.925 1.89-3.219-1.056-3.219-2.395 0-1.338.304-3.76 2.458-3.144 2.153.615 2.336-1.66 3.686-2.878 1.352-1.218 5.793-1.318 5.793-1.318Z"
        fill="#AF6E38"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M88.838 136.917s-3.438 39.07-6.357 50.969c0 0 2.767 2.744 9.046 1.459l10.413-42.291s.903 15.096.806 21.841c-.098 6.746-.385 13.276.002 17.667 0 0 3.559 1.819 9.225.213 0 0 4.637-26.783 2.377-52.791 0 0-17.336 3.824-25.512 2.933Z"
        fill="#000"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M104.221 92.67s.608.292 4.586.333c3.978.042 8.863 2.126 11.796 5.08 2.933 2.952 2.915 2.681 2.915 2.681s-.511 3.624-1.606 5.095a65.238 65.238 0 0 1-2.175 2.755l-5.342-3.183s.851 6.672.993 12.423c.144 5.752.354 15.428-.29 15.843 0 0-5.749 1.949-12.908 2.522-7.16.573-14.032.697-14.032.697s.117-10.687.189-14.461c.07-3.774 0-12.074 0-12.074l-2.21 3.79s-2.688-.389-4.331-1.312c-1.643-.923-2.665-2.289-2.665-2.289s2.784-6.674 4.516-9.38c1.732-2.705 3.899-4.705 7.205-5.823 3.307-1.118 4.434-2.274 4.434-2.274s1.014 3.09 4.093 3.25c3.079.162 4.517-.842 4.832-3.673Z"
        fill="#3956A7"
        fillRule="evenodd"
      />
      <path
        d="M105.031 75.573s-.472 2.941.346 4.134c.817 1.193 3.471 2.765 2.691 3.555-.78.79-1.274 1.553-1.163 2.337.11.784.462 2.472-1.165 3.21-1.627.74-4.459.563-4.459.563M97.333 82.072c-.917-1.236-1.387-2.594-2.984-1.778-1.596.816-1.113 5.987 2.033 4.36l.339 5.459M96.717 90.112s.119 3.9-6.175 5.332c-6.293 1.432-9.575 10.768-11.826 16.494-2.252 5.725-4.21 9.618-5.61 12.686-2.836 6.213-4.825 11.062-5.809 12.171-.495.56-1.22.787-1.91.596-1.966-.544-6.32-1.529-8-.137-2.2 1.825 3.83 4.293 5.56 4.703 0 0-1.98.282-2.138 1.146-.1.548 2.852.579 5.782.764 1.853.117 4.431-.465 5.34-2.244 1.45-2.837 4.155-7.27 7.38-13.628 1.793-3.533 5.1-10.035 6.498-12.901 3.917-8.021 5.228-9.631 5.228-9.631"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.805}
      />
      <path
        d="M102.59 89.383s-.262 2.84 2.212 3.508c2.474.67 7.726-1.622 15.192 4.68a513.614 513.614 0 0 1 13.653 11.958M113.907 101.912s2.382 14.01 1.39 31.793c0 0-10.455 3.394-26.95 3.203l.105-27.03M114.352 105.08l16.522 11.045"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.805}
      />
      <path
        d="M88.838 136.917s-3.438 39.07-6.357 50.969c0 0 2.767 2.744 9.046 1.459l10.413-42.291s.903 15.096.806 21.841c-.098 6.746-.385 13.276.002 17.667 0 0 3.559 1.819 9.225.213 0 0 4.637-26.783 2.377-52.791M104.115 92.614s.527 2.938-2.685 3.742c-3.213.802-5.7-1.313-6.282-3.127M123.424 100.486s-.057 4.021-3.553 8.218M79.324 110.382s1.033 2.868 7.044 3.576"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.805}
      />
      <path
        clipRule="evenodd"
        d="M101.979 75.892s2.129.25 4.468-1.462c2.338-1.712 1.42-3.841-2.923-4.3-4.343-.46-8.728.194-11.623 3.758-2.895 3.563-3.488 9.004 1.562 8.594 0 0-.364-2.7 2.175-2.359l1.612 1.83s1.368-.591 1.284-2.324c-.084-1.733-.919-3.863 3.445-3.737Z"
        fill="#000"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M101.979 75.892s2.129.25 4.468-1.462c2.338-1.712 1.42-3.841-2.923-4.3-4.343-.46-8.728.194-11.623 3.758-2.895 3.563-3.488 9.004 1.562 8.594 0 0-.364-2.7 2.175-2.359l1.612 1.83s1.368-.591 1.284-2.324c-.084-1.733-.919-3.863 3.445-3.737Z"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.805}
      />
      <path
        clipRule="evenodd"
        d="m132.484 108.13-14.061-7.83s-2.655-3.915-4.731-6.252c-2.076-2.337-2.797-7.026-4.037-5.469-1.24 1.558 1.546 5.728 1.546 5.728s-3.62 3.24-2.082 5.993c1.536 2.752 3.25 4.985 5.43 5.659 2.18.675 10.765 7.777 14.715 9.801 3.95 2.025 9.946-2.346 3.22-7.63Z"
        fill="#AF6E38"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="m132.484 108.13-14.061-7.83s-2.655-3.915-4.731-6.252c-2.076-2.337-2.797-7.026-4.037-5.469-1.24 1.558 1.546 5.728 1.546 5.728s-3.62 3.24-2.082 5.993c1.536 2.752 3.25 4.985 5.43 5.659 2.18.675 10.765 7.777 14.715 9.801 3.95 2.025 9.946-2.346 3.22-7.63Z"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.805}
      />
    </g>
    <defs>
      <radialGradient
        cx={0}
        cy={0}
        gradientTransform="matrix(0 90 -130.79 0 171.5 90)"
        gradientUnits="userSpaceOnUse"
        id="b"
        r={1}
      >
        <stop stopColor="#AFC3EE" />
        <stop offset={1} stopColor="#fff" />
      </radialGradient>
      <clipPath id="a">
        <path d="M0 0h343v180H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);

export default DefaultImage;
