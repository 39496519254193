import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { AwardBonusPointsPayload } from "./types";

const useAwardBonusPoints = (
  queryOptions?: UseMutationOptions<
    void,
    PortalClientError,
    AwardBonusPointsPayload
  >
) => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<void, PortalClientError, AwardBonusPointsPayload>(
    ({ id, ...payload }) => client.post(`/users/${id}/bonus_points`, payload),
    {
      ...queryOptions,
      onSuccess: (data, payload, context) => {
        queryClient.invalidateQueries(["users", payload.id]);
        queryClient.invalidateQueries(["events"]);
        queryOptions?.onSuccess?.(data, payload, context);
      },
    }
  );
};
export default useAwardBonusPoints;
