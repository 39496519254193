import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { CreateSurveyQuestion, SurveyQuestion } from "./types";

const useCreateSurveyQuestion = (
  offerId: string,
  queryOptions?: UseMutationOptions<
    JsonApiResponse<SurveyQuestion>,
    PortalClientError,
    CreateSurveyQuestion
  >
) => {
  const client = useClient();

  return useMutation<
    JsonApiResponse<SurveyQuestion>,
    PortalClientError,
    CreateSurveyQuestion
  >(
    (payload) => client.post(`/offers/${offerId}/survey_question`, payload),
    queryOptions
  );
};

export default useCreateSurveyQuestion;
