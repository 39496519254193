import { Button, notification } from "antd";
import { Formik, FormikProps } from "formik";
import { Form, Input } from "formik-antd";
import React from "react";

import Notifications from "../../assets/data/notifications";
import { useCreateDeepLink, CreateDeepLinkVariables } from "../../client/hooks";
import { validationSchema } from "../EditDeepLinkForm";
import { AddExperimentFormProps } from "./AddDeepLinkForm.types";

const AddDeepLinkForm: React.FunctionComponent<AddExperimentFormProps> = ({
  onSuccess,
}) => {
  const { mutateAsync: addDeepLink, isLoading } = useCreateDeepLink({
    onSuccess: () => {
      notification.success({
        message: "Success",
        description: Notifications.AddDeepLink.success,
      });
    },
    onError: (error) => {
      notification.error({
        message: "Error",
        description:
          Notifications.AddDeepLink.error + error.errors?.[0]?.detail,
      });
    },
  });

  const handleSubmit = async (values: CreateDeepLinkVariables) => {
    const deepLink = await addDeepLink(values);
    onSuccess?.(deepLink.data);
  };

  return (
    <Formik
      initialValues={{
        name: "",
        url: window.env.REACT_APP_WEB_DOMAIN,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ isValid }: FormikProps<CreateDeepLinkVariables>) => (
        <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
          <Form.Item label="Name" name="name">
            <Input name="name" placeholder="Enter Deep Link Name" />
          </Form.Item>
          <Form.Item label="Url" name="url">
            <Input name="url" />
          </Form.Item>
          <div style={{ display: "flex" }}>
            <Button
              disabled={isLoading || !isValid}
              htmlType="submit"
              style={{ margin: "0 auto" }}
              type="primary"
            >
              Submit
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default AddDeepLinkForm;
