import { format } from "date-fns";
import { isNil } from "lodash";

const DATE_FORMAT = "MMMM d, yyyy";

export const formatDateTime = (date: number | Date) =>
  date && format(date, `${DATE_FORMAT}, h:mma`);

export const formatDate = (date: number | Date) =>
  date && format(date, DATE_FORMAT);

export const customFormat = (date: number | Date, type: string) =>
  format(date, type);

export const formatDateTimeWithTimezone = (
  datetime: string | number,
  timezone: string
) => {
  const dateTime = formatDateTime(new Date(datetime));
  return `${dateTime} (${timezone || "N/A"})`;
};

export const timeSorter = (
  time1: string | null,
  time2: string | null
): number => {
  return nilSafeDateFetch(time1) - nilSafeDateFetch(time2);
};

const nilSafeDateFetch = (time: string | null): number => {
  return new Date(isNil(time) ? 0 : time).getTime();
};

/**
 * Convert a Date into a local Date without adusting the time based on timezone.
 * Useful when a date is stored in UTC without a time component.
 * @param date
 * @returns
 */
export const adjustDateWithoutTimezone = (date: Date) => {
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
};
