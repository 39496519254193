import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { Offer, CloneOfferPayload } from "./types";

const useCloneOffer = (
  offerId: string,
  queryOptions?: UseMutationOptions<
    JsonApiResponse<Offer>,
    PortalClientError,
    CloneOfferPayload
  >
) => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<
    JsonApiResponse<Offer>,
    PortalClientError,
    CloneOfferPayload
  >((payload) => client.post(`/offers/${offerId}/clone`, payload), {
    ...queryOptions,
    onSuccess: (data, variables, context) => {
      if (queryClient.getQueryData(["offers"])) {
        queryClient.setQueryData<JsonApiResponse<Offer[]>>(
          ["offers"],
          (offers) => ({ data: offers ? [data.data, ...offers.data] : [] })
        );
      }
      queryOptions?.onSuccess?.(data, variables, context);
    },
  });
};

export default useCloneOffer;
