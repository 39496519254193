import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { Offer } from "./types";

const useSendTestAppOffers = (
  offerId: string,
  queryOptions?: UseMutationOptions<
    JsonApiResponse<Offer>,
    PortalClientError,
    string
  >
) => {
  const client = useClient();

  return useMutation<JsonApiResponse<Offer>, PortalClientError, string>(
    (adminId: string) =>
      client.post(`/offers/${offerId}/send_app_offer`, { user_ids: [adminId] }),
    queryOptions
  );
};

export default useSendTestAppOffers;
