import React from "react";

import { Profile, useProfile } from "../../client";

type AuthorizedState = "authorized" | "authorizing" | "unauthorized";

/**
 *
 * @param roles
 */
export const useAuthorizedState = (roles: string[]) => {
  const [authorizedState, setAuthorizedState] =
    React.useState<AuthorizedState>("authorizing");

  const { data: admin } = useProfile({
    retry: false,
    staleTime: 60 * 60 * 1000,
    select: (data) => data.data,
  });

  React.useEffect(() => {
    if (admin) {
      const authorized = isAuthorized(admin, roles);
      setAuthorizedState(authorized ? "authorized" : "unauthorized");
    }
  }, [admin, roles]);

  return [authorizedState, setAuthorizedState];
};

/**
 *
 * @param admin
 * @param roles
 */
export const isAuthorized = (admin: Profile, roles: string[]) => {
  const intersection = admin.roles.filter((role) => roles.includes(role.name));

  if (intersection.length > 0) {
    return true;
  }

  return false;
};
