import { Box, Button, Heading, Text } from "@evidation-shared/eve";
import React from "react";

import { OutcomeQuestion } from "../../../client";
import { OutcomeQuestionType } from "../../../client";
import OutcomeDiaryInput from "./OutcomeDiaryInput";

type OutcomeDiaryInputProps = {
  outcomeQuestion: OutcomeQuestion;
};

const OutcomeDiaryQuestion: React.FunctionComponent<OutcomeDiaryInputProps> = ({
  outcomeQuestion,
}) => {
  const DiaryQuickTip = React.useMemo(() => {
    return outcomeQuestion.configuration.quick_tip?.body ? (
      <React.Fragment>
        {/* @ts-ignore */}
        <Text fontSize="md" fontWeight="semibold" lineHeight="md">
          {outcomeQuestion.configuration.quick_tip?.title}
        </Text>
        {/* @ts-ignore */}
        <Text fontSize="md" lineHeight="md">
          {outcomeQuestion.configuration.quick_tip?.body}
        </Text>
      </React.Fragment>
    ) : undefined;
  }, [outcomeQuestion]);

  return (
    <Box
      bgColor="white"
      borderColor="black"
      borderWidth={1}
      flexDirection="column"
      flexGrow={1}
      height={600}
      justifyContent="space-between"
      ml={2}
      p={4}
      pb={8}
    >
      {/* @ts-ignore */}
      <Heading marginBottom={6} size="heading-5">
        {outcomeQuestion.configuration.question}
      </Heading>
      <OutcomeDiaryInput question={outcomeQuestion} />
      <Box mt="auto">
        {outcomeQuestion.configuration.quick_tip && (
          <Box my={4}>{DiaryQuickTip}</Box>
        )}
        {outcomeQuestion.ui_element === OutcomeQuestionType.Buttons ? (
          <Box flexDirection="row">
            {/* @ts-ignore */}
            {outcomeQuestion.configuration.buttons?.map((button, index) => (
              <Box flexGrow={1} justifyContent="center" key={index}>
                <Button ml={2} mr={2}>
                  {button.label}
                </Button>
              </Box>
            ))}
          </Box>
        ) : (
          <Button>Submit</Button>
        )}
      </Box>
    </Box>
  );
};

export default OutcomeDiaryQuestion;
