import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { OutcomeDiary, UpdateOutcomeDiary } from "./types";

const useCreateOutcomeDiary = (
  options?: UseMutationOptions<
    JsonApiResponse<OutcomeDiary>,
    PortalClientError,
    UpdateOutcomeDiary
  >
) => {
  const client = useClient();

  return useMutation<
    JsonApiResponse<OutcomeDiary>,
    PortalClientError,
    UpdateOutcomeDiary
  >((payload) => client.post(`/outcome_diaries`, payload), options);
};

export default useCreateOutcomeDiary;
