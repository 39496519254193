import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { OutcomeQuestion, UpdateOutcomeQuestion } from "./types";

const useUpdateOutcomeQuestion = (
  outcomeQuestionID: string,
  options?: UseMutationOptions<
    JsonApiResponse<OutcomeQuestion>,
    PortalClientError,
    UpdateOutcomeQuestion
  >
) => {
  const client = useClient();

  return useMutation<
    JsonApiResponse<OutcomeQuestion>,
    PortalClientError,
    UpdateOutcomeQuestion
  >(
    (payload) => client.put(`/outcome_questions/${outcomeQuestionID}`, payload),
    options
  );
};

export default useUpdateOutcomeQuestion;
