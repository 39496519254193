import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { Offer } from "./types";

type GetOffersParams = {
  page?: number;
  size?: number;
  search?: string;
};

const defaultParams = {
  size: 50,
};

const useGetOffers = <TData extends {} = JsonApiResponse<Offer[]>>(
  params: GetOffersParams = defaultParams,
  queryOptions?: UseQueryOptions<
    JsonApiResponse<Offer[]>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<Offer[]>, PortalClientError, TData>(
    ["offers"],
    () => client.get("/offers", { params }),
    {
      staleTime: 5 * 60 * 1000,
      ...queryOptions,
    }
  );
};

export default useGetOffers;
