import { Spin } from "antd";
import React from "react";

import useCognitoConfig from "../../client/hooks/auth/useCognitoConfig";
import AmplifyManager from "../AmplifyManager";

export type ConfigContextValue = {
  poolId: string;
  poolDomain: string;
  clientId: string;
};

export const CognitoConfigContext = React.createContext<ConfigContextValue>({
  poolId: "",
  poolDomain: "",
  clientId: "",
});

/**
 * Fetches the branding and authentication configuration information before
 *   rendering children inside an `AmplifyManager`. In the meantime, renders a
 *   loading spinner.
 */
const CognitoConfigManager: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const landingConfig = useCognitoConfig();

  if (landingConfig.isLoading) {
    return <Spin size="large" spinning />;
  }

  if (landingConfig.isError) {
    // @todo: Replace with a real error page.
    return (
      <div>Something went wrong! Could not load Cognito configuration.</div>
    );
  }

  const configValue: ConfigContextValue = {
    poolId: landingConfig.data.data.pool_id,
    poolDomain: landingConfig.data.data.pool_domain,
    clientId: landingConfig.data.data.client_id,
  };

  return (
    <CognitoConfigContext.Provider value={configValue}>
      <AmplifyManager
        clientId={configValue.clientId}
        poolId={configValue.poolId}
      >
        {children}
      </AmplifyManager>
    </CognitoConfigContext.Provider>
  );
};
export default CognitoConfigManager;
