import { OutcomeQuestionType } from "../../client";

export const getOutcomeQuestionName = (kind: OutcomeQuestionType): string => {
  return outcomeQuestionNames[kind] ?? `Unknown (${kind})`;
};

export const outcomeQuestionNames: { [key in OutcomeQuestionType]: string } = {
  checkbox_group: "Checkboxes",
  radiobuttons: "Radio Buttons",
  buttons: "Buttons",
};
