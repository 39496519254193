import React from "react";

const Space = ({ children, component, ...props }) => {
  return (
    <div
      style={{
        marginLeft: (props.ml ?? 0) * 4,
        marginTop: (props.mt ?? 0) * 4,
      }}
    >
      {children}
    </div>
  );
};

export default Space;
