import { notification } from "antd";
import React from "react";

import Notifications from "../assets/data/notifications";
import { OutcomeQuestion, useCreateOutcomeQuestion } from "../client";
import OutcomeQuestionForm from "../forms/OutcomeQuestionForm";
import defaultOutcomeQuestion from "../forms/OutcomeQuestionForm/defaults";
import { customFormat } from "../utils/date";
import Modal, { ModalProps } from "./Modal";

interface AddOutcomeQuestionProps extends ModalProps {
  onSuccess?: (values: OutcomeQuestion) => void;
}

const AddOutcomeQuestionModal: React.FC<AddOutcomeQuestionProps> = ({
  onSuccess,
  ...modalProps
}) => {
  const mutation = useCreateOutcomeQuestion({
    onSuccess: () => {
      notification.success({
        message: "Success",
        description: Notifications.AddOutcomeQuestion.success,
      });
    },
    onError: () => {
      notification.error({
        message: "Error",
        description: Notifications.AddOutcomeQuestion.error,
      });
    },
  });

  return (
    <Modal
      destroyOnClose
      footer={null}
      title="Add Outcome Question"
      width={1200}
      {...modalProps}
    >
      {(close: () => void) => (
        <OutcomeQuestionForm
          mutation={mutation}
          onSuccess={(response?: OutcomeQuestion) => {
            close();
            if (response && onSuccess) {
              onSuccess(response);
            }
          }}
          outcomeQuestion={{
            ...defaultOutcomeQuestion["checkbox_group"],
            identifier: `${customFormat(new Date(), "yyyyMMdd")}_`,
          }}
        />
      )}
    </Modal>
  );
};
export default AddOutcomeQuestionModal;
