import { Card, Descriptions, PageHeader, Result, Space, Spin } from "antd";
import React from "react";
import { RouteComponentProps } from "react-router-dom";

import { useOutcomeDiary } from "../client";
import EditRecurringForm from "../forms/EditRecurringForm";

interface EditRecurringProps {
  id: string;
}
const EditRecurringView: React.FC<RouteComponentProps<EditRecurringProps>> = ({
  history,
  match,
}) => {
  const { data: outcomeDiary, status } = useOutcomeDiary(match.params.id, {
    select: (data) => data.data,
  });

  if (status === "loading") {
    return (
      <Space
        align="center"
        style={{
          width: "100%",
          justifyContent: "center",
          padding: "20px",
        }}
      >
        <Spin size="large" spinning />
      </Space>
    );
  } else if (status === "error" || !outcomeDiary) {
    return (
      <Result
        status="error"
        subTitle="There was a problem loading this recurring engagement, please try again."
        title={`Error loading RE (ID: ${match.params.id})`}
      />
    );
  }

  return (
    <PageHeader
      onBack={() => history.push("/recurring")}
      subTitle={`${outcomeDiary.id} - ${outcomeDiary.identifier}`}
      title="Edit Recurring Engagement"
    >
      <Card>
        <Descriptions bordered column={4} size="small">
          <Descriptions.Item label="ID">{outcomeDiary.id}</Descriptions.Item>
          <Descriptions.Item label="Name">
            {outcomeDiary.identifier}
          </Descriptions.Item>
          <Descriptions.Item label="Created By">
            {outcomeDiary.created_by}
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <EditRecurringForm outcomeDiary={outcomeDiary} />
    </PageHeader>
  );
};
export default EditRecurringView;
