import { SettingOutlined } from "@ant-design/icons";
import { Switch, Dropdown, Menu } from "antd";

import {
  FeatureToggles,
  useFeatureToggleContext,
} from "../client/FeatureToggleContext";

type GetMenuItemsType = (
  toggles: FeatureToggles,
  updateFeatureToggle: (key: string, value: boolean) => void
) => React.ReactNode;

const getMenuItems: GetMenuItemsType = (toggles, updateFeatureToggle) => {
  const keys = Object.keys(toggles ?? {});
  return keys.length ? (
    keys.map((key) => (
      <Menu.Item
        key={key}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        {key}
        <Switch
          checked={toggles?.[key]}
          onChange={() => updateFeatureToggle(key, !toggles?.[key])}
          style={{ marginLeft: "50px" }}
        />
      </Menu.Item>
    ))
  ) : (
    <Menu.Item>No Available Feature Flags</Menu.Item>
  );
};

const FeatureToggleDropdown: React.FC = () => {
  const { featureToggles, updateFeatureToggle } = useFeatureToggleContext();
  return (
    <Dropdown
      overlay={<Menu>{getMenuItems(featureToggles, updateFeatureToggle)}</Menu>}
      placement="topRight"
      trigger={["click"]}
    >
      <SettingOutlined
        style={{
          fontSize: 24,
          color: "#ffffffa6",
          bottom: 20,
          right: 20,
          position: "absolute",
        }}
      />
    </Dropdown>
  );
};

export default FeatureToggleDropdown;
