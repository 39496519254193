import { useQuery } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { JsonApiResponse } from "../types";

type WorkspaceConfigResponse = {
  admin_login_text: string;
  admin_logo_url: string;
  admin_favicon_url: string;
  admin_welcome_message: string;
};

/**
 * Fetches the workspace config for this instance of Maestro and returns its
 *   data as returned by React Query.
 */
const useWorkspaceSettings = () => {
  const client = useClient();
  const query = useQuery(
    ["workspace-settings"],
    () =>
      client.get<any, JsonApiResponse<WorkspaceConfigResponse>>(
        "/workspace_settings"
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime:
        window.env.REACT_APP_PORTAL_ENVIRONMENT === "production" ? 15 : 0,
      // Don't retry infinitely. If it failed twice, it likely isn't going to
      //   work.
      retry: 1,
      select: (data) => data.data,
    }
  );

  return query;
};

export default useWorkspaceSettings;
