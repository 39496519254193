import { FormControl } from "@evidation-shared/eve";

import type { OptionGroupProps } from "./OptionGroup.types";

const OptionGroup = ({
  caption,
  error,
  children,
  label,
  required,
  ...props
}: OptionGroupProps) => {
  return (
    <FormControl isRequired={required}>
      {label && <FormControl.Label>{label}</FormControl.Label>}
      {typeof children === "function" ? children(props) : children}
      {caption && <FormControl.HelperText>{caption}</FormControl.HelperText>}
      {error && <FormControl.ErrorMessage>{error}</FormControl.ErrorMessage>}
    </FormControl>
  );
};

export default OptionGroup;
