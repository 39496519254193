import React from "react";
import { RouteProps, Route } from "react-router-dom";

import { useAuthorizedState } from "./utils";

interface AuthorizedRouteProps extends RouteProps {
  roles?: string[];
}

const AuthorizedRoute: React.FunctionComponent<AuthorizedRouteProps> = ({
  roles = [],
  ...props
}) => {
  const [authorizedState] = useAuthorizedState([...roles, "superadmin"]);

  if (authorizedState === "authorizing") {
    return null;
  }

  if (authorizedState === "unauthorized") {
    return (
      <div>
        <h1>Unauthorized</h1>
      </div>
    );
  }

  return <Route {...props} />;
};

export default AuthorizedRoute;
