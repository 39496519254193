import { Modal as AntModal, ModalProps as AntModalProps } from "antd";
import React from "react";

export interface ModalProps extends AntModalProps {
  content?: (close: () => void) => React.ReactElement;
  onClickCancel?: (close: () => void) => void;
  onClickOk?: (close: () => void) => void;
  renderButton?: (open: () => void) => React.ReactElement;
  visibleDefault?: boolean;
}
const Modal: React.FC<ModalProps> = ({
  children,
  renderButton = () => {},
  visibleDefault = false,
  onClickCancel,
  onClickOk,
  ...antModalProps
}) => {
  const [visible, setVisible] = React.useState(visibleDefault);
  const open: () => void = () => setVisible(true);
  const close: () => void = () => setVisible(false);
  return (
    <React.Fragment>
      {renderButton(open)}
      <AntModal
        onCancel={() => onClickCancel?.(close) ?? close()}
        onOk={() => onClickOk?.(close) ?? close()}
        visible={visible}
        {...antModalProps}
      >
        {typeof children === "function" ? children(close) : children}
      </AntModal>
    </React.Fragment>
  );
};
export default Modal;
