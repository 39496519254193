import { LoadingOutlined } from "@ant-design/icons";
import { Tag, Calendar, Col, Row, Card } from "antd";
import moment from "moment";
import React, { useCallback } from "react";

import { Schedule, useComputeDates } from "../../client";

type SchedulePreviewProps = {
  schedule?: Schedule[];
  start_at?: string;
  end_at?: string;
};
const SchedulePreview: React.FunctionComponent<SchedulePreviewProps> = ({
  schedule,
  start_at,
  end_at,
}) => {
  const { data, isFetching } = useComputeDates(
    {
      schedule,
      start_at,
      end_at,
    },
    {
      enabled: !!schedule && !!schedule?.[0].every,
      select: (data) => data.data,
    }
  );

  const dates = React.useMemo(() => {
    return data?.dates.map((date) => moment(date));
  }, [data]);

  const dateCellRender = useCallback(
    (value: moment.Moment) => {
      const foundDate = dates?.find((date) => date.isSame(value, "day"));
      return foundDate ? (
        <Row>
          <Col
            span={24}
            style={{ background: "#3956A7", borderRadius: 10, height: 15 }}
          ></Col>
        </Row>
      ) : undefined;
    },
    [dates]
  );

  const monthCellRender = useCallback(
    (value: moment.Moment) => {
      const foundDates = dates?.filter((date) => date.isSame(value, "month"));
      return foundDates?.length ? (
        <Tag color="#3956A7">{foundDates.length} events</Tag>
      ) : undefined;
    },
    [dates]
  );

  return (
    <Card
      extra={
        isFetching ? <LoadingOutlined spin style={{ fontSize: 22 }} /> : false
      }
      title="Preview"
    >
      <Calendar
        dateCellRender={dateCellRender}
        monthCellRender={monthCellRender}
      />
    </Card>
  );
};

export default SchedulePreview;
