import {
  Box,
  CloseOutlined,
  Heading,
  HStack,
  StarFilled,
  Text,
  VStack,
} from "@evidation-shared/eve";

const RatingCard = () => {
  return (
    <Box
      backgroundColor="white"
      maxWidth="343px"
      padding={4}
      position="relative"
      shadow={0}
    >
      <VStack space={2}>
        <HStack justifyContent="space-between">
          {/* @ts-ignore https://achievements.atlassian.net/browse/APLUS-2172 */}
          <Heading size="heading-5">How was this card?</Heading>
          <CloseOutlined size="22px" />
        </HStack>
        {/* @ts-ignore https://achievements.atlassian.net/browse/APLUS-2172 */}
        <Text color="secondary" fontSize="md" lineHeight="md">
          Help us deliver more great offers by rating this one.
        </Text>
      </VStack>
      <HStack marginTop="10px" space={2}>
        <StarFilled color="gray.200" size="24px" />
        <StarFilled color="gray.200" size="24px" />
        <StarFilled color="gray.200" size="24px" />
        <StarFilled color="gray.200" size="24px" />
        <StarFilled color="gray.200" size="24px" />
      </HStack>
    </Box>
  );
};

export default RatingCard;
