import { Button, Card, Col, notification } from "antd";
import { Formik, FormikProps } from "formik";
import { Form, Input, Switch } from "formik-antd";

import Notifications from "../../assets/data/notifications";
import { useUpdateDeepLink } from "../../client";
import { validationSchema } from "./EditDeepLinkForm.schema";
import {
  EditDeepLinkFormParams,
  EditDeepLinkFormValues,
} from "./EditDeepLinkForm.types";

const EditDeepLinkForm: React.FunctionComponent<EditDeepLinkFormParams> = ({
  onSuccess,
  deepLink,
}) => {
  const { mutate: updateDeepLink, isLoading } = useUpdateDeepLink({
    onSuccess: () => {
      notification.success({
        message: "Success",
        description: Notifications.UpdateDeepLink.success,
      });

      onSuccess?.();
    },
    onError: (error) => {
      notification.error({
        message: "Error",
        description:
          Notifications.UpdateDeepLink.error + error.errors?.[0]?.detail,
      });
    },
  });

  const handleSubmit = async (values: EditDeepLinkFormValues) => {
    updateDeepLink({
      id: deepLink.id,
      active: values.active,
      name: values.name,
      url: values.url,
      min_app_version: values.min_app_version || null,
      max_app_version: values.max_app_version || null,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        active: deepLink.active,
        name: deepLink.name,
        url: deepLink.url,
        min_app_version: deepLink.min_app_version,
        max_app_version: deepLink.max_app_version,
      }}
      onSubmit={handleSubmit}
      validateOnMount
      validationSchema={validationSchema}
    >
      {({
        dirty,
        isValid,
        isSubmitting,
      }: FormikProps<EditDeepLinkFormValues>) => (
        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 8 }}>
          <Col span={14}>
            <Card title="Settings">
              <Form.Item label="Name" name="name">
                <Input name="name" placeholder="Application Name" />
              </Form.Item>
              <Form.Item label="URL" name="url">
                <Input name="url" />
              </Form.Item>
              <Form.Item label="Min App Version" name="min_app_version">
                <Input name="min_app_version" />
              </Form.Item>
              <Form.Item label="Max App Version" name="max_app_version">
                <Input name="max_app_version" />
              </Form.Item>
              <Form.Item label="Active" name="active">
                <Switch name="active" />
              </Form.Item>
              <Form.Item name="buttons" wrapperCol={{ push: 8, span: 8 }}>
                <Button
                  disabled={isLoading || !isValid || isSubmitting || !dirty}
                  htmlType="submit"
                  style={{ margin: "0 4px" }}
                  type="primary"
                >
                  Save
                </Button>
              </Form.Item>
            </Card>
          </Col>
        </Form>
      )}
    </Formik>
  );
};

export default EditDeepLinkForm;
