import { PageHeader, Button } from "antd";
import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";

import ProgramTable from "../tables/ProgramTable";
import AddProgramView from "./AddProgramView";

const ProgramListView: React.FC<RouteComponentProps> = ({ history }) => {
  return (
    <React.Fragment>
      <PageHeader
        extra={
          <Button onClick={() => history.push("/programs/add")} type="primary">
            Add
          </Button>
        }
        title="Programs"
      >
        <ProgramTable />
      </PageHeader>
      <Switch>
        <Route component={AddProgramView} path="/programs/add" />
      </Switch>
    </React.Fragment>
  );
};
export default ProgramListView;
