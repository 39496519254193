import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { OutcomeDiary } from "./types";

const useOutcomeDiaries = <TData extends {} = JsonApiResponse<OutcomeDiary[]>>(
  options?: UseQueryOptions<
    JsonApiResponse<OutcomeDiary[]>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<OutcomeDiary[]>, PortalClientError, TData>(
    ["outcome_diaries"],
    () => client.get("/outcome_diaries"),
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export default useOutcomeDiaries;
