import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { ImageKind } from "./types";

const useImageKinds = (
  queryOptions?: UseQueryOptions<
    JsonApiResponse<ImageKind[]>,
    PortalClientError,
    string[]
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<ImageKind[]>, PortalClientError, string[]>(
    ["image_kinds"],
    () => client.get("/images/kinds"),
    {
      refetchOnWindowFocus: false,
      select: (images) => images.data.map((image) => image.kind),
      ...queryOptions,
    }
  );
};

export default useImageKinds;
