import React from "react";

import {
  FeatureToggleContext,
  FeatureToggleProviderProps,
  FeatureToggles,
} from "./FeatureToggleContext.types";
import {
  activeFeatureToggles,
  filterLocalFeatureToggles,
  StorageKey,
} from "./FeatureToggleContext.utils";

const Context = React.createContext<FeatureToggleContext | undefined>(
  undefined
);

const FeatureToggleProvider: React.FunctionComponent<
  FeatureToggleProviderProps
> = ({ children, value }) => {
  const [featureToggles, setFeatureToggles] = React.useState<FeatureToggles>(
    value ?? {
      ...activeFeatureToggles,
      ...filterLocalFeatureToggles(activeFeatureToggles),
    }
  );

  const updateFeatureToggle = (key: string, value: boolean) => {
    const updatedFeatureToggles = {
      ...featureToggles,
      [key]: value,
    };

    setFeatureToggles(updatedFeatureToggles);
  };

  React.useEffect(() => {
    localStorage.setItem(StorageKey, JSON.stringify(featureToggles));
  }, [featureToggles]);

  return (
    <Context.Provider
      value={{
        featureToggles,
        setFeatureToggles,
        updateFeatureToggle,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useFeatureToggleContext = (): FeatureToggleContext => {
  const context = React.useContext(Context);

  if (!context) {
    throw new Error(
      "FeatureToggleContext must be used within FeatureToggleProvider"
    );
  }

  return context;
};

const useFeatureToggle = (
  toggleName: keyof typeof activeFeatureToggles
): boolean => {
  const { featureToggles } = useFeatureToggleContext();

  // always return false in production
  if (window.env.REACT_APP_PORTAL_ENVIRONMENT === "production") {
    return false;
  }

  return featureToggles[toggleName] ?? false;
};

export { FeatureToggleProvider, useFeatureToggleContext, useFeatureToggle };

export default Context;
