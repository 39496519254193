import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { User } from "./types";

const useUser = <TData extends {} = JsonApiResponse<User>>(
  id: string,
  queryOptions?: UseQueryOptions<
    JsonApiResponse<User>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<User>, PortalClientError, TData>(
    ["users", id],
    () => client.get(`/users/${id}`),
    {
      refetchOnWindowFocus: false,
      ...queryOptions,
    }
  );
};

export default useUser;
