import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { DeepLinksData, UseDeepLinkParams } from "./types";
import deepLinkKeys from "./utils";

const useDeepLinks = <TData extends {} = DeepLinksData>(
  params?: UseDeepLinkParams,
  queryOptions?: UseQueryOptions<DeepLinksData, PortalClientError, TData>
) => {
  const client = useClient();

  return useQuery<DeepLinksData, PortalClientError, TData>(
    deepLinkKeys.list(params),
    () => client.get(`/deep_links`, { params }),
    {
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
      ...queryOptions,
    }
  );
};

export default useDeepLinks;
