import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosRequestConfig } from "axios";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { Admin } from "./types";

const defaultAxiosConfig = {
  params: {
    per_page: 1000, // number of items per page
  },
};

const useAdmins = <TData extends {} = JsonApiResponse<Admin[]>>(
  queryOptions?: UseQueryOptions<
    JsonApiResponse<Admin[]>,
    PortalClientError,
    TData
  >,
  axiosConfig?: AxiosRequestConfig
) => {
  const client = useClient();

  const combinedAxiosConfig = Object.assign(defaultAxiosConfig, axiosConfig);

  return useQuery<JsonApiResponse<Admin[]>, PortalClientError, TData>(
    ["admins"],
    () => client.get("/admins", combinedAxiosConfig),
    queryOptions
  );
};

export default useAdmins;
