import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { Program } from "./types";

const useProgram = <TData extends {} = JsonApiResponse<Program>>(
  programId: string,
  options?: UseQueryOptions<JsonApiResponse<Program>, PortalClientError, TData>
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<Program>, PortalClientError, TData>(
    ["programs", programId],
    () => client.get("/programs/" + programId),
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export default useProgram;
