import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { CreateOfferPayload, Offer } from "./types";

const useCreateOffer = (
  queryOptions?: UseMutationOptions<
    JsonApiResponse<Offer>,
    PortalClientError,
    CreateOfferPayload
  >
) => {
  const client = useClient();

  return useMutation<
    JsonApiResponse<Offer>,
    PortalClientError,
    CreateOfferPayload
  >((payload) => client.post(`/offers`, payload), queryOptions);
};

export default useCreateOffer;
