import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { serialise } from "kitsu-core";

import { useClient } from "../../ClientContext";

const useResetOfferCompletion = (
  offerId: string,
  options?: UseMutationOptions<String, Error, number>
) => {
  const client = useClient();

  return useMutation<String, Error, number>(
    (userId: number) =>
      client.patch(
        `/offers/${offerId}/reset_user_completion`,
        serialise("user", { id: userId })
      ),
    options
  );
};
export default useResetOfferCompletion;
