import { get } from "lodash";
import styled from "styled-components";

const CardFooter = styled.div`
  border-top: 1px solid ${(props) => get(props, "theme.palette.base.light")};
  font-size: 12px;
  line-height: ${16 / 12};
  padding: 24px;
`;

export default CardFooter;
