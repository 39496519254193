import { Image } from "../images";
import { Program } from "../programs";
import { PortalPayload, PortalRelationship } from "../types";

export interface OutcomeDiaryBase {
  card_action_description?: string;
  card_body?: string;
  card_color?: string | null;
  card_label?: string;
  completion_minutes?: number;
  created_at?: string;
  created_by?: string;
  end_at?: string;
  expiration_hours?: number;
  feed_description?: string;
  id: string;
  identifier: string;
  introduction_body?: string;
  introduction_title?: string;
  draft?: boolean;
  no_outcomes_to_complete_text?: string;
  points_for_completion?: number;
  rateable?: boolean;
  revenue_generating?: boolean;
  reminder_schedule?: Schedule[];
  schedule_next_outcome_at?: string;
  show_in_offer_carousel?: boolean;
  start_at?: string;
  success_content_heading?: string;
  success_content_text?: string;
  surveymonkey_filter?: BinaryType | null;
  surveymonkey_url?: string;
  type: "outcome_diary";
}

export interface OutcomeDiary extends OutcomeDiaryBase {
  diary_card_image?: PortalRelationship<Image>;
  diary_questions?: PortalRelationship<DiaryQuestion[]>;
  diary_success_content_image?: PortalRelationship<Image>;
  entry_schedule?: Schedule[];
  expired?: boolean;
  outcome_questions?: PortalRelationship<OutcomeQuestion[]>;
  outcome_question_responses_for_screener_value?: string;
  program?: PortalRelationship<Program>;
  surveymonkey_response?: SurveyMonkeyResponse;
}

export interface OutcomeDiaryPayload extends OutcomeDiaryBase {
  entry_schedule?: Schedule[] | null;
  program_id?: string;
  diary_card_image_id?: number;
  diary_success_content_image_id?: number;
  outcome_question_responses_for_screener?: SurveyMonkeyResponseScreener | null;
}

export interface DiaryQuestion {
  created_at?: string;
  id: string;
  outcome_question?: PortalRelationship<OutcomeQuestion>;
  position: number;
  required: boolean;
  type: "diary_question";
  ui_element_override?: OutcomeQuestionType;
  updated_at?: string;
}
export interface OutcomeQuestion {
  configuration: OutcomeQuestionConfiguration;
  created_at: string;
  diary_questions?: PortalRelationship<DiaryQuestion[]>;
  id: string;
  identifier: string;
  outcome_diaries?: PortalRelationship<OutcomeDiary[]>;
  response_schema: OutcomeQuestionResponseSchema;
  type: "outcome_question";
  ui_element: OutcomeQuestionType;
  updated_at?: string;
}

export enum OutcomeQuestionType {
  CheckboxGroup = "checkbox_group",
  RadioButtons = "radiobuttons",
  Buttons = "buttons",
}

export interface BaseOutcomeQuestionConfiguration {
  question: string;
  quick_tip?: {
    title: string;
    body: string;
  };
}

export interface CheckboxOutcomeQuestionConfiguration
  extends BaseOutcomeQuestionConfiguration {
  checkboxes: OutcomeDiaryAnswer[];
}

export interface ButtonsOutcomeQuestionConfiguration
  extends BaseOutcomeQuestionConfiguration {
  buttons: OutcomeDiaryAnswer[];
}

export type OutcomeQuestionConfiguration =
  | BaseOutcomeQuestionConfiguration
  | CheckboxOutcomeQuestionConfiguration
  | ButtonsOutcomeQuestionConfiguration;

export type OutcomeDiaryAnswer = {
  exclusive?: boolean;
  label: string;
  value: string;
};

interface OutcomeQuestionResponseSchema {
  description?: string;
  type: string;
  minimum?: number;
  maximum?: number;
  items?: any;
  enum?: string[];
}

type SurveyMonkeyResponse = SurveyMonkeyResponseScreener & {
  user: { postal_code: string[] };
};

interface SurveyMonkeyResponseScreener {
  [key: string]: {
    type: "string";
    enum: string[];
  };
}

export interface Schedule {
  at: number[][];
  day?: { [key in number]: [1 | -1] } | number[];
  mday?: number[];
  every: "day" | "week" | "month" | "year";
  month?: number[];
  starts?: string;
  until?: string;
  total?: number;
  interval?: number;
}

interface AttachOutcomeQuestion {
  outcome_question_id: string;
}

/**
 * Payload types for POST/PUT to portal request in json:api schema.
 */
export type UpdateOutcomeDiary = PortalPayload<
  "outcome_diary",
  OutcomeDiaryPayload
>;

export type UpdateOutcomeQuestion = PortalPayload<
  "outcome_question",
  OutcomeQuestion
>;

export type UpdateAttachOutcomeQuestion =
  PortalRelationship<AttachOutcomeQuestion>;

export type SurveyMonkeyFilterPayload = {
  file: BinaryType;
  filter_field: string;
};

export interface SchedulerPayload {
  schedule?: Schedule[];
  start_at?: string;
  end_at?: string;
}

export interface SchedulerResponse {
  dates: string[];
  id: string;
  type: "MontroseSchedule";
}

export interface IssuePROPayload {
  userId: string;
  outcomeDiaryId: string;
}
