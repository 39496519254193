import React from "react";
import { Helmet } from "react-helmet-async";

import useWorkspaceSettings from "../client/hooks/workspaceSettings/useWorkspaceSettings";

const Head: React.FC = () => {
  const { data, isLoading } = useWorkspaceSettings();
  if (isLoading || !data?.data.admin_favicon_url) {
    return (
      <Helmet>
        <link href="./favicon.ico" rel="shortcut icon" />
        <link href="./favicon.ico" rel="favicon" />
        <link href="./favicon.ico" rel="icon" />
      </Helmet>
    );
  }
  return (
    <Helmet>
      <link href={data?.data.admin_favicon_url} rel="shortcut icon" />
      <link href={data?.data.admin_favicon_url} rel="favicon" />
      <link href={data?.data.admin_favicon_url} rel="icon" />
    </Helmet>
  );
};

export default Head;
