import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { Experiment } from "./types";

const useExperiment = <TData extends {} = JsonApiResponse<Experiment>>(
  id: string,
  queryOptions?: UseQueryOptions<
    JsonApiResponse<Experiment>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<Experiment>, PortalClientError, TData>(
    ["experiment_configs", id],
    () => client.get(`/experiment_configs/${id}`),
    {
      refetchOnWindowFocus: false,
      ...queryOptions,
    }
  );
};

export default useExperiment;
