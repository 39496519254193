import PropTypes from "prop-types";
import React from "react";

import CardMedia from "./CardMedia";

const CardMediaComponent = ({ height, image }) => (
  <CardMedia height={height} image={image} />
);

CardMediaComponent.propTypes = {
  height: PropTypes.number,
  image: PropTypes.string,
};

CardMediaComponent.defaultProps = {
  height: 140,
};

export default CardMediaComponent;
