import { useQuery } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";

type CognitoConfigResponse = {
  id: string;
  type: string;
  pool_id: string;
  pool_domain: string;
  client_id: string;
};

/**
 * Fetches the Cognito config for this instance of Maestro and returns its data
 *   as returned by React Query.
 */
const useCognitoConfig = () => {
  const client = useClient();
  return useQuery<JsonApiResponse<CognitoConfigResponse>, PortalClientError>(
    ["cognito-config"],
    async () => {
      const response = await client.get<
        any,
        JsonApiResponse<CognitoConfigResponse>
      >("/workspace_settings/cognito");
      if (response.data.id !== "cognito") {
        throw new Error(
          `Invalid Cognito config \`id\` value: ${response.data.id}`
        );
      }
      if (response.data.type !== "workspace_cognito") {
        throw new Error(
          `Invalid Cognito config \`type\` value: ${response.data.type}`
        );
      }
      return response;
    },
    {
      refetchOnWindowFocus: false,
      cacheTime:
        window.env.REACT_APP_PORTAL_ENVIRONMENT === "production" ? 15 : 0,
      // Don't retry infinitely. If it failed twice, it's likely isn't going to
      //   work.
      retry: 1,
    }
  );
};

export default useCognitoConfig;
