import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { Profile } from "./types";

const useProfile = <TData extends {} = JsonApiResponse<Profile>>(
  options?: UseQueryOptions<JsonApiResponse<Profile>, PortalClientError, TData>
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<Profile>, PortalClientError, TData>(
    ["profile"],
    () => client.get("/profile"),
    options
  );
};

export default useProfile;
