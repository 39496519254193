import { get, isEqual, keys } from "lodash";

export const getChangedValues = <T extends Object>(
  initialValues?: T | null,
  values?: T | null
): Partial<T> => {
  return keys(values).reduce<T>((accumulator: T, key: string) => {
    if (isEqual(get(initialValues, key), get(values, key))) {
      return accumulator;
    }
    return {
      ...accumulator,
      [key]: get(values, key),
    };
  }, {} as any);
};
