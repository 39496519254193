import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { PortalClientError, UpdateApplication } from "../../../client";
import { useClient } from "../../ClientContext";
import { JsonApiResponse } from "../types";
import { Application } from "./types";

const useUpdateApplication = (
  id: string,
  queryOptions?: UseMutationOptions<
    JsonApiResponse<Application>,
    PortalClientError,
    UpdateApplication
  >
) => {
  const queryClient = useQueryClient();
  const client = useClient();

  return useMutation<
    JsonApiResponse<Application>,
    PortalClientError,
    UpdateApplication
  >(
    (payload) => {
      return client.put(`/applications/${id}`, payload);
    },
    {
      ...queryOptions,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["applications"]);
        queryOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};

export default useUpdateApplication;
