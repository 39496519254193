import React from "react";
import { RouteComponentProps } from "react-router-dom";

import { DeepLink } from "../client/hooks";
import AddDeepLinkForm from "../forms/AddDeepLinkForm";
import Modal, { ModalProps } from "./Modal";

interface EditDeepLinksModalProps extends ModalProps, RouteComponentProps {
  deepLink: DeepLink;
}

const AddDeepLinkModal: React.FunctionComponent<EditDeepLinksModalProps> = ({
  history,
}) => {
  return (
    <Modal
      footer={null}
      onCancel={() => history.push("/deep_links")}
      open
      title="Add Deep Link"
      width={700}
    >
      <AddDeepLinkForm
        onSuccess={(deepLink) => history.push(`/deep_links/${deepLink?.id}`)}
      />
    </Modal>
  );
};
export default AddDeepLinkModal;
