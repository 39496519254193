import { Spin } from "antd";
import React, { useEffect, useState } from "react";

import { useAmplify } from "../AmplifyManager";

export type SessionInfo =
  | {
      isSignedIn: true;
      accessToken: string;
    }
  | {
      isSignedIn: false;
      accessToken: null;
    };

export const SessionContext = React.createContext<SessionInfo>({
  isSignedIn: false,
  accessToken: null,
});

type Props = {
  children: React.ReactElement;
  /**
   * Called when the session changes.
   */
  onChange?: (value: SessionInfo) => any;
};

/**
 * Centralizes Cognito session management and provides a context that makes it
 *   easy to get the current session information.
 */
const SessionManager: React.FC<Props> = ({ children, onChange }) => {
  const [contextValue, setContextValue] = useState<
    SessionInfo | "loading" | "error"
  >("loading");
  const { auth, hub } = useAmplify();

  useEffect(() => {
    if (contextValue !== "loading" && contextValue !== "error") {
      onChange?.(contextValue);
    }
  }, [contextValue, onChange]);

  useEffect(() => {
    const initContext = async () => {
      try {
        const token = await auth.fetchAccessToken();
        if (token) {
          setContextValue({
            isSignedIn: true,
            accessToken: token,
          });
        } else {
          setContextValue({
            isSignedIn: false,
            accessToken: null,
          });
        }
      } catch (error) {
        console.error(error);
        setContextValue("error");
        return;
      }
    };
    initContext();

    return hub.listen("auth", async ({ payload }) => {
      if (
        payload.event === "signedIn" ||
        payload.event === "signInWithRedirect"
      ) {
        const token = await auth.fetchAccessToken();
        setContextValue({
          isSignedIn: true,
          accessToken: token!,
        });
      } else if (payload.event === "signedOut") {
        setContextValue({
          isSignedIn: false,
          accessToken: null,
        });
      }
      // @todo: Do we need to handle "signInWithRedirect_failure"?
    });
  }, [auth, hub]);

  if (contextValue === "loading") {
    return <Spin size="large" spinning />;
  }

  if (contextValue === "error") {
    // @todo: Replace with a real error page.
    return <div>Something went wrong!</div>;
  }

  return (
    <SessionContext.Provider value={contextValue}>
      {children}
    </SessionContext.Provider>
  );
};
export default SessionManager;
