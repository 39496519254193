import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { Experiment } from "./types";

const useExperiments = <TData extends {} = JsonApiResponse<Experiment[]>>(
  queryOptions?: UseQueryOptions<
    JsonApiResponse<Experiment[]>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<Experiment[]>, PortalClientError, TData>(
    ["experiment_configs"],
    () => client.get("/experiment_configs"),
    {
      refetchOnWindowFocus: false,
      ...queryOptions,
    }
  );
};

export default useExperiments;
