import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { Image, UpdateImagePayload } from "./types";

const useUpdateImage = (
  options?: UseMutationOptions<
    JsonApiResponse<Image>,
    PortalClientError,
    UpdateImagePayload
  >
) => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<
    JsonApiResponse<Image>,
    PortalClientError,
    UpdateImagePayload
  >(({ id, ...payload }) => client.put(`/images/${id}`, payload), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["images", data.data.type]);
      options?.onSuccess?.(data, variables, context);
    },
  });
};

export default useUpdateImage;
