import * as yup from "yup";

export const schema = yup.object({
  admin_login_text: yup.string(),
  admin_welcome_message: yup.string(),
  admin_favicon_url: yup.lazy((value) =>
    value instanceof File || value === undefined
      ? yup
          .mixed()
          .test(
            "fileType",
            "You can only upload jpeg, jpg or png images.",
            (value) =>
              value &&
              ["image/jpeg", "image/jpg", "image/png"].includes(value.type)
          )
      : yup.string()
  ),
  admin_logo_url: yup.lazy((value) =>
    value instanceof File || value === undefined
      ? yup
          .mixed()
          .test(
            "fileType",
            "You can only upload jpeg, jpg or png images.",
            (value) =>
              value &&
              ["image/jpeg", "image/jpg", "image/png"].includes(value.type)
          )
      : yup.string()
  ),
});
