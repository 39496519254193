import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { serialise } from "kitsu-core";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { CreateDeepLinkVariables, DeepLink } from "./types";
import deepLinkKeys from "./utils";

const useCreateDeepLink = (
  queryOptions?: UseMutationOptions<
    JsonApiResponse<DeepLink>,
    PortalClientError,
    CreateDeepLinkVariables
  >
) => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<
    JsonApiResponse<DeepLink>,
    PortalClientError,
    CreateDeepLinkVariables
  >((payload) => client.post(`/deep_links`, serialise("deep_link", payload)), {
    ...queryOptions,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(deepLinkKeys.list());

      queryOptions?.onSuccess?.(data, variables, context);
    },
  });
};

export default useCreateDeepLink;
