import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { OutcomeQuestion, UpdateOutcomeQuestion } from "./types";

const useCreateOutcomeQuestion = (
  options?: UseMutationOptions<
    JsonApiResponse<OutcomeQuestion>,
    PortalClientError,
    UpdateOutcomeQuestion
  >
) => {
  const client = useClient();

  return useMutation<
    JsonApiResponse<OutcomeQuestion>,
    PortalClientError,
    UpdateOutcomeQuestion
  >((payload) => client.post(`/outcome_questions`, payload), options);
};

export default useCreateOutcomeQuestion;
