import styled from "styled-components";
import {
  alignItems,
  alignSelf,
  color,
  flex,
  flexDirection,
  flexWrap,
  fontSize,
  justifyContent,
  order,
  space,
  width,
} from "styled-system";

const Box = styled("div")(
  {
    boxSizing: "border-box",
  },
  space,
  color,
  width,
  fontSize,
  flex,
  order,
  alignSelf,
  (props) => props.css
);

// GRID
const Grid = styled(Box)`
  max-width: ${(props) => props.theme.gridSize || "1200px"};
  width: 100%;
`;

Grid.defaultProps = {
  mx: "auto",
};

Grid.displayName = "Grid";

// GRID.BOX
Grid.Box = styled("div")(
  {
    boxSizing: "border-box",
  },
  space,
  color,
  width,
  fontSize,
  flex,
  order,
  alignSelf,
  (props) => props.css
);

Grid.Box.displayName = "Grid.Box";

Grid.Box.propTypes = {
  ...space.propTypes,
  ...color.propTypes,
  ...width.propTypes,
  ...fontSize.propTypes,
};

// GRID.FLEX
Grid.Flex = styled(Box)(
  {
    display: "flex",
  },
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent
);

Grid.Flex.displayName = "Grid.Flex";

Grid.Flex.propTypes = {
  ...flexWrap.propTypes,
  ...flexDirection.propTypes,
  ...alignItems.propTypes,
  ...justifyContent.propTypes,
};

export default Grid;
