import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { UserEmailChange } from "./types";

const useUserEmailChanges = <
  TData extends {} = JsonApiResponse<UserEmailChange[]>
>(
  id: string,
  queryOptions?: UseQueryOptions<
    JsonApiResponse<UserEmailChange[]>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<UserEmailChange[]>, PortalClientError, TData>(
    ["userEmailChanges", id],
    () => client.get(`/users/${id}/email_changes`),
    {
      refetchOnWindowFocus: false,
      ...queryOptions,
    }
  );
};

export default useUserEmailChanges;
