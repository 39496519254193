import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { AddableAdmin, Admin } from "./types";

const useAddAdmin = (
  queryOptions?: UseMutationOptions<
    JsonApiResponse<Admin>,
    PortalClientError,
    AddableAdmin
  >
) => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<JsonApiResponse<Admin>, PortalClientError, AddableAdmin>(
    (payload) => client.post(`/admins`, payload),
    {
      ...queryOptions,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["admins"]);
        queryOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};

export default useAddAdmin;
