import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { Program } from "./types";

const usePrograms = <TData extends {} = JsonApiResponse<Program[]>>(
  options?: UseQueryOptions<
    JsonApiResponse<Program[]>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<Program[]>, PortalClientError, TData>(
    ["programs"],
    () => client.get("/programs"),
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export default usePrograms;
