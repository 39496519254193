import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { AwardRefereePointsPayload } from "./types";

const useAwardRefereePoints = (
  queryOptions?: UseMutationOptions<
    void,
    PortalClientError,
    AwardRefereePointsPayload
  >
) => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<void, PortalClientError, AwardRefereePointsPayload>(
    ({ id, ...payload }) => client.post(`/users/${id}/referee_points`, payload),
    {
      ...queryOptions,
      onSuccess: (data, payload, context) => {
        queryClient.invalidateQueries(["users", payload.id]);
        queryOptions?.onSuccess?.(data, payload, context);
      },
    }
  );
};
export default useAwardRefereePoints;
