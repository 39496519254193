import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { SchedulerPayload, SchedulerResponse } from "./types";

const useComputeDates = <TData extends {} = JsonApiResponse<SchedulerResponse>>(
  payload: SchedulerPayload,
  options?: UseQueryOptions<
    JsonApiResponse<SchedulerResponse>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<SchedulerResponse>, PortalClientError, TData>(
    ["schedules", payload],
    () =>
      client.get(`/schedules`, {
        params: payload,
        paramsSerializer: (params) => {
          return Object.keys(params)
            .map((key) => {
              return `${key}=${encodeURI(JSON.stringify(params[key]))}`;
            })
            .join("&");
        },
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity,
      ...options,
    }
  );
};

export default useComputeDates;
