import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { Offer } from "./types";

const useGetOffer = <TData extends {} = JsonApiResponse<Offer>>(
  offerId: string,
  queryOptions?: UseQueryOptions<
    JsonApiResponse<Offer>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<Offer>, PortalClientError, TData>(
    ["offers", offerId],
    () => client.get(`/offers/${offerId}`),
    queryOptions
  );
};

export default useGetOffer;
