import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { ProfileMapping } from "./types";

const useProfileMappings = <
  TData extends {} = JsonApiResponse<ProfileMapping[]>
>(
  queryOptions?: UseQueryOptions<
    JsonApiResponse<ProfileMapping[]>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<ProfileMapping[]>, PortalClientError, TData>(
    ["profile_mappings"],
    () => client.get("/profile_mappings"),
    {
      refetchOnWindowFocus: false,
      ...queryOptions,
    }
  );
};

export default useProfileMappings;
