import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { OutcomeDiary, UpdateOutcomeDiary } from "./types";

const useUpdateOutcomeDiary = (
  outcomeDiaryID: string,
  options?: UseMutationOptions<
    JsonApiResponse<OutcomeDiary>,
    PortalClientError,
    UpdateOutcomeDiary
  >
) => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<
    JsonApiResponse<OutcomeDiary>,
    PortalClientError,
    UpdateOutcomeDiary
  >((payload) => client.put(`/outcome_diaries/${outcomeDiaryID}`, payload), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["outcome_diaries", outcomeDiaryID]);
      options?.onSuccess?.(data, variables, context);
    },
  });
};

export default useUpdateOutcomeDiary;
