import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { useClient, useQueryClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { CreateInsightType, InsightType } from "./types";

const useCreateInsightType = (
  options?: UseMutationOptions<
    JsonApiResponse<InsightType>,
    PortalClientError,
    CreateInsightType
  >
) => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<
    JsonApiResponse<InsightType>,
    PortalClientError,
    CreateInsightType
  >((payload) => client.post(`/insight_types`, payload), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["insight_types"]);
      options?.onSuccess?.(data, variables, context);
    },
  });
};

export default useCreateInsightType;
