import React from "react";

import { useAuthorizedState } from "./utils";

interface AuthorizedContentProps {
  environment?: string;
  error?: React.ReactElement;
  roles?: string[];
}

const AuthorizedContent: React.FunctionComponent<AuthorizedContentProps> = ({
  children,
  environment,
  error,
  roles = [],
  ...props
}) => {
  const [authorizedState] = useAuthorizedState([...roles, "superadmin"]);

  if (
    authorizedState !== "authorized" ||
    (environment && environment !== window.env.REACT_APP_PORTAL_ENVIRONMENT)
  ) {
    // TODO: consider other display options?
    return error ?? null;
  }

  if (!React.isValidElement(children)) {
    return null;
  }

  return React.cloneElement(children, props);
};

export default AuthorizedContent;
