import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { OfferLabel } from "./types";

const useLabels = <TData extends {} = JsonApiResponse<OfferLabel[]>>(
  queryOptions?: UseQueryOptions<
    JsonApiResponse<OfferLabel[]>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<OfferLabel[]>, PortalClientError, TData>(
    ["labels"],
    () => client.get(`/labels`),
    {
      refetchOnWindowFocus: false,
      ...queryOptions,
    }
  );
};

export default useLabels;
