import { Amplify } from "aws-amplify";
import * as amplifyAuth from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import React, { useMemo } from "react";

/**
 * Defines the subset of functions in use from `aws-amplify/auth`.
 */
export type AmplifyAuthSubset = Pick<
  typeof amplifyAuth,
  "signInWithRedirect" | "signOut" | "fetchAuthSession"
>;

/**
 * Defines the subset of functions in use from the Amplify `Hub` object.
 */
export type AmplifyHubSubset = Pick<typeof Hub, "listen">;

export type AmplifyContextValue = {
  /**
   * Wrapped functions from `aws-amplify/auth`. Some of these match the Amplify
   *   functions exactly, while others are there for convenience.
   */
  auth: {
    signInWithRedirect: () => Promise<void>;
    signOut: () => Promise<void>;
    /**
     * Calls `fetchAuthSession` and pulls out the access token, if present.
     */
    fetchAccessToken: () => Promise<string | null>;
    // Add more functions here as needed.
  };
  hub: {
    listen: AmplifyHubSubset["listen"];
  };
};

/**
 * Creates the "auth" context value from the real Amplify library (or a mock).
 */
const makeAmplifyAuth = (
  auth: AmplifyAuthSubset = amplifyAuth
): AmplifyContextValue["auth"] => ({
  signInWithRedirect: auth.signInWithRedirect,
  signOut: auth.signOut,
  fetchAccessToken: async () => {
    const session = await auth.fetchAuthSession();
    return session.tokens?.idToken?.toString() ?? null;
  },
});

/**
 * Creates the "hub" context value from the real Amplify library (or a mock).
 */
const makeAmplifyHub = (
  hub: AmplifyHubSubset = Hub
): AmplifyContextValue["hub"] => ({
  listen: hub.listen.bind(hub),
});

export const AmplifyContext = React.createContext<AmplifyContextValue>({
  auth: makeAmplifyAuth(),
  hub: makeAmplifyHub(),
});

export type Props = {
  children: React.ReactElement;
  poolId: string;
  clientId: string;
  // For easy mocking in tests:
  configure?: typeof Amplify.configure;
  auth?: AmplifyAuthSubset;
  hub?: AmplifyHubSubset;
};

/**
 * Configures Amplify with the given `poolId` and `clientId`, then renders the
 *   children inside a context that provides a wrapper over the parts of the
 *   Amplify library that we use for convenience and easy testing.
 */
const AmplifyManager: React.FC<Props> = ({
  children,
  poolId,
  clientId,
  configure = Amplify.configure,
  auth = amplifyAuth,
  hub = Hub,
}) => {
  const value = useMemo(() => {
    if (poolId && clientId) {
      // Configuring Amplify inside this `useMemo` block instead of a separate
      //   `useEffect` so that Amplify is configured immediately instead of
      //    after mount, to avoid race conditions and/or a flash of un-rendered
      //    content.
      var parsedUrl = new URL(window.location.toString());
      var baseUrl = parsedUrl.origin;

      configure({
        Auth: {
          Cognito: {
            userPoolId: poolId,
            userPoolClientId: clientId,
            loginWith: {
              oauth: {
                domain: window.env.REACT_APP_COGNITO_ADMIN,
                scopes: ["openid", "email", "profile"],
                redirectSignIn: [`${baseUrl}/login`],
                redirectSignOut: [`${baseUrl}/logout`],
                responseType: "code",
              },
            },
          },
        },
      });
    }
    return { auth: makeAmplifyAuth(auth), hub: makeAmplifyHub(hub) };
  }, [auth, clientId, configure, hub, poolId]);

  return (
    <AmplifyContext.Provider value={value}>{children}</AmplifyContext.Provider>
  );
};
export default AmplifyManager;
