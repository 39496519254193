import { UploadOutlined } from "@ant-design/icons";
import { Box } from "@evidation-shared/eve";
import { Affix, Button, Card, Col, Row, Space, Typography, Upload } from "antd";
import { Form, Input, InputNumber, Select } from "formik-antd";
import { serialise } from "kitsu-core";
import React from "react";

import {
  ButtonsOutcomeQuestionConfiguration,
  Image,
  OutcomeQuestion,
  OutcomeQuestionType,
  useUpdateOutcomeDiary,
} from "../../../client";
import useAttachOutcomeQuestionToDiary from "../../../client/hooks/outcomeDiaries/useAttachOutcomeQuestionToDiary";
import OutcomeDiaryCard from "../../../components/OutcomeDiaryCard";
import ProgramSelect from "../../../components/ProgramSelect";
import SelectImageButton from "../../../components/SelectImageButton/SelectImageButton";
import AddOutcomeQuestionModal from "../../../modals/AddOutcomeQuestionModal";
import EditOutcomeQuestionModal from "../../../modals/EditOutcomeQuestionModal";
import { getType, validateButtonAnswer } from "../../../utils/outcomeDiaries";
import { RecurringTabFormProps } from "../EditRecurringForm";

const EngagementFormTab: React.FC<RecurringTabFormProps> = ({
  outcomeDiary,
  formik,
}) => {
  const { mutate: updateOutcomeDiary } = useUpdateOutcomeDiary(outcomeDiary.id);
  const { mutateAsync: attachQuestion } = useAttachOutcomeQuestionToDiary(
    outcomeDiary.id
  );

  // surveymonkey_filter is any recently uploaded file that hasn't been saved yet
  // surveymonkey_response is the parsed file contents returned by the BE after saving
  const fileList = React.useMemo(() => {
    return formik.values.surveymonkey_filter
      ? [formik.values.surveymonkey_filter as any]
      : formik.values.surveymonkey_response?.user?.postal_code
      ? [
          {
            name:
              formik.values.surveymonkey_response.user.postal_code.length +
              " zip code(s) uploaded",
          },
        ]
      : undefined;
  }, [formik.values.surveymonkey_filter, formik.values.surveymonkey_response]);

  return (
    <Row>
      <Col span={14}>
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Card title="Engagement Content">
            <Form.Item label="Image" name="diary_card_image">
              <Space>
                <SelectImageButton
                  image={formik.values.diary_card_image?.data}
                  kind="DiaryCardImage"
                  onClearImage={() =>
                    formik.setFieldValue("diary_card_image.data.id", null)
                  }
                  onSelectImage={(image: Image) =>
                    formik.setFieldValue("diary_card_image.data", image)
                  }
                />
              </Space>
            </Form.Item>
            <Form.Item label="Body" name="card_body">
              <Input.TextArea
                autoSize={{ minRows: 6 }}
                maxLength={255}
                name="card_body"
                showCount
              />
            </Form.Item>
            <Form.Item
              label="Points"
              name="points_for_completion"
              tooltip="The points value is used to assign points to members when an outcome diary is completed."
            >
              <InputNumber name="points_for_completion" />
            </Form.Item>
            <Form.Item
              label="Reward"
              name="reward"
              style={{ marginBottom: "0px" }}
            >
              <Form.Item name="points_for_completion">
                <Input
                  disabled
                  name="points_for_completion"
                  placeholder=""
                  value={(formik.values.points_for_completion ?? 0) + " points"}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item
              label="Completion Time"
              name="completion_minutes"
              wrapperCol={{ span: 6 }}
            >
              <Input
                addonAfter="minutes"
                name="completion_minutes"
                type="number"
              />
            </Form.Item>
            <Form.Item label="Card Label" name="card_label">
              <Input maxLength={15} name="card_label" />
            </Form.Item>
            <Form.Item label="Call to Action" name="card_action_description">
              <Input name="card_action_description" />
            </Form.Item>
            <Form.Item label="Question" name="edit_survey_question">
              {formik.values.outcome_questions &&
              formik.values.outcome_questions.data &&
              formik.values.outcome_questions.data[0] ? (
                <EditOutcomeQuestionModal
                  disabled={!outcomeDiary.draft}
                  onSuccess={(updatedQuestion: OutcomeQuestion) => {
                    formik.setFieldValue(
                      "outcome_questions.data[0]",
                      updatedQuestion
                    );
                    if (validateButtonAnswer(formik.values, updatedQuestion)) {
                      updateOutcomeDiary(
                        serialise("outcome_diary", {
                          id: outcomeDiary.id,
                          identifier: outcomeDiary.identifier,
                          outcome_question_responses_for_screener: null,
                        })
                      );
                      formik.setFieldValue(
                        "outcome_question_responses_for_screener_value",
                        null
                      );
                    }
                  }}
                  outcomeQuestion={formik.values.outcome_questions.data[0]}
                  renderButton={(open: any) => (
                    <Button onClick={open} type="primary">
                      Edit Outcome Question
                    </Button>
                  )}
                />
              ) : (
                <AddOutcomeQuestionModal
                  onSuccess={(values: OutcomeQuestion) => {
                    attachQuestion({
                      data: { outcome_question_id: values.id },
                    });
                    formik.setFieldValue("outcome_questions.data[0]", values);
                  }}
                  renderButton={(open: any) => (
                    <Button
                      disabled={!outcomeDiary.draft}
                      onClick={open}
                      type="primary"
                    >
                      Add Outcome Question
                    </Button>
                  )}
                />
              )}
            </Form.Item>
            <Form.Item label="SurveyMonkey URL" name="surveymonkey_url">
              <Input name="surveymonkey_url" />
            </Form.Item>
          </Card>
          <Card title="Button Question Eligibility Criteria">
            <Form.Item
              label="Engagement Answer"
              name="outcome_question_responses_for_screener_value"
            >
              <Select
                allowClear
                disabled={
                  getType(formik.values) !== OutcomeQuestionType.Buttons
                }
                name="outcome_question_responses_for_screener_value"
                onChange={(value) => {
                  formik.setFieldValue(
                    "outcome_question_responses_for_screener_value",
                    value ? value : null
                  );
                }}
              >
                {(
                  formik.values.outcome_questions?.data?.[0]
                    ?.configuration as ButtonsOutcomeQuestionConfiguration
                )?.buttons?.map((button) => (
                  <Select.Option key={button.value} value={button.value}>
                    {button.label}
                  </Select.Option>
                )) ?? undefined}
              </Select>
            </Form.Item>
            <Form.Item
              label="Zip Codes"
              name="surveymonkey_filter"
              tooltip="Only eligible for button type questions"
            >
              <Upload
                accept=".csv"
                beforeUpload={() => false}
                fileList={fileList}
                itemRender={
                  formik.values.surveymonkey_filter
                    ? undefined
                    : (_element, file) => (
                        <Typography.Text>{file.name}</Typography.Text>
                      )
                }
                onChange={({ file }) => {
                  if (file.status !== "uploading") {
                    formik.setFieldValue("surveymonkey_filter", file);
                  }
                }}
              >
                <Button
                  disabled={
                    getType(formik.values) !== OutcomeQuestionType.Buttons
                  }
                  icon={<UploadOutlined />}
                >
                  Upload CSV
                </Button>
              </Upload>
            </Form.Item>
            {formik.values.surveymonkey_response?.user?.postal_code?.length && (
              <Row>
                <Col offset={6}>
                  <Button
                    danger
                    disabled={
                      getType(formik.values) !== OutcomeQuestionType.Buttons
                    }
                    onClick={() => {
                      const newVal = {
                        ...formik.values.surveymonkey_response,
                        user: null,
                      };
                      formik.setFieldValue("surveymonkey_response", newVal);
                    }}
                  >
                    Clear Zip Codes
                  </Button>
                </Col>
              </Row>
            )}
          </Card>
          <Card title="Program Association">
            <Form.Item label="Program" name="program.data.id">
              <ProgramSelect
                allowClear
                disabled={!outcomeDiary.draft}
                name="program.data.id"
                valueSelector="id"
              />
            </Form.Item>
          </Card>
        </Space>
      </Col>
      <Col span={10}>
        <Affix offsetTop={0}>
          <Box
            marginLeft={8}
            marginTop={4}
            maxWidth="343px"
            position="relative"
          >
            <OutcomeDiaryCard
              values={{ ...formik.values, color: outcomeDiary.card_color }}
            />
          </Box>
        </Affix>
      </Col>
    </Row>
  );
};
export default EngagementFormTab;
