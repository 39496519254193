import {
  ButtonsOutcomeQuestionConfiguration,
  OfferStatus,
  OutcomeDiary,
  OutcomeDiaryPayload,
  OutcomeQuestion,
  OutcomeQuestionType,
  Schedule,
} from "../client";

export const getSchedule = (outcomeDiary: OutcomeDiary): string => {
  if (!outcomeDiary.entry_schedule?.[0]?.every) {
    return "";
  }

  switch (outcomeDiary.entry_schedule[0].every) {
    case "day":
      return "Daily";
    case "week":
      return "Weekly";
    case "month":
      return "Monthly";
    case "year":
      return "Yearly";
    default:
      return "";
  }
};

export const getStatus = (outcomeDiary: OutcomeDiary): OfferStatus => {
  if (outcomeDiary.draft) {
    return OfferStatus.Draft;
  }
  if (outcomeDiary.expired) {
    return OfferStatus.Expired;
  }
  return OfferStatus.Live;
};

export const getExpiration = (expiration_hours: number) => {
  const d = new Date();
  d.setHours(d.getHours() + expiration_hours);
  return d.toString();
};

export const cleanOutcomeDiaryForSubmit = (
  original: OutcomeDiary,
  edited: Partial<OutcomeDiary>
): OutcomeDiaryPayload => {
  const {
    // Do not send:
    outcome_questions,
    surveymonkey_response,
    // Modify before sending:
    diary_card_image,
    diary_success_content_image,
    entry_schedule,
    outcome_question_responses_for_screener_value,
    program,
    // Remaining fields to send:
    ...payloadFields
  } = edited;

  const cleanOutcomeDiary: OutcomeDiaryPayload = {
    ...payloadFields,
    id: original.id,
    identifier: original.identifier,
    type: "outcome_diary",
  };

  if (diary_card_image?.data) {
    cleanOutcomeDiary.diary_card_image_id = diary_card_image.data.id;
  }

  if (diary_success_content_image?.data) {
    cleanOutcomeDiary.diary_success_content_image_id =
      diary_success_content_image.data.id;
  }

  if (entry_schedule?.[0]) {
    const cleanSchedule = cleanEntrySchedule(entry_schedule?.[0]);
    cleanOutcomeDiary.entry_schedule = cleanSchedule ? [cleanSchedule] : null;
  } else if (entry_schedule === null) {
    cleanOutcomeDiary.entry_schedule = null;
  }

  if (program?.data) {
    cleanOutcomeDiary.program_id = program?.data.id;
  }

  const questionIdentifier = getOutcomeQuestionIdentifier(original);
  if (outcome_question_responses_for_screener_value && questionIdentifier) {
    cleanOutcomeDiary.outcome_question_responses_for_screener = {
      [questionIdentifier]: {
        type: "string",
        enum: [outcome_question_responses_for_screener_value],
      },
    };
  } else if (outcome_question_responses_for_screener_value === null) {
    cleanOutcomeDiary.outcome_question_responses_for_screener = null;
  }

  if (
    !cleanOutcomeDiary.surveymonkey_filter &&
    surveymonkey_response?.user === null
  ) {
    cleanOutcomeDiary.surveymonkey_filter = null;
  }

  return cleanOutcomeDiary;
};

const cleanEntrySchedule = (schedule?: Schedule): Schedule | null => {
  if (!schedule) return null;

  const { interval, day } = schedule;

  // Remove interval if it's 0, "", or null
  if (!interval) {
    delete schedule.interval;
  }

  if (day && Array.isArray(day) && day.length === 0) {
    delete schedule.day;
  }

  return Object.keys(schedule).length === 0 ? null : schedule;
};

// Infinite circular reference breaks formik validation
export const removeCircularDiaryReferences = (outcomeDiary: OutcomeDiary) => {
  outcomeDiary.outcome_questions?.data.forEach((outcomeQuestion) => {
    delete outcomeQuestion.diary_questions;
  });
  outcomeDiary.diary_questions?.data.forEach((diaryQuestion) => {
    delete diaryQuestion.outcome_question;
  });
  return outcomeDiary;
};

// Infinite circular reference breaks formik validation
export const removeCircularQuestionReferences = (
  outcomeQuestion: OutcomeQuestion
) => {
  outcomeQuestion.outcome_diaries?.data.forEach((outcomeDiary) => {
    delete outcomeDiary.diary_questions;
    removeCircularDiaryReferences(outcomeDiary);
  });
  outcomeQuestion.diary_questions?.data.forEach((diaryQuestion) => {
    delete diaryQuestion.outcome_question;
  });
  return outcomeQuestion;
};

export const getOutcomeResponseScreenerValue = (outcomeDiary: OutcomeDiary) => {
  const questionIdentifier = getOutcomeQuestionIdentifier(outcomeDiary);
  if (!questionIdentifier) {
    return undefined;
  }

  return outcomeDiary.surveymonkey_response?.[questionIdentifier]?.enum?.[0];
};

export const getOutcomeQuestionIdentifier = (outcomeDiary: OutcomeDiary) =>
  outcomeDiary.outcome_questions?.data?.[0]?.identifier;

export const getType = (
  outcomeDiary?: OutcomeDiary
): OutcomeQuestionType | undefined => {
  return outcomeDiary?.outcome_questions?.data?.[0]?.ui_element;
};

// After updating an outcome question, check to see if outcome_question_responses_for_screener_value still matches a valid answer
export const validateButtonAnswer = (
  outcomeDiary: OutcomeDiary,
  updatedQuestion: OutcomeQuestion
): boolean => {
  return (
    updatedQuestion.ui_element === OutcomeQuestionType.Buttons &&
    !!outcomeDiary.outcome_question_responses_for_screener_value &&
    !(
      updatedQuestion.configuration as ButtonsOutcomeQuestionConfiguration
    ).buttons.find(
      (button) =>
        button.value ===
        outcomeDiary.outcome_question_responses_for_screener_value
    )
  );
};
