import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { useClient, useQueryClient } from "../../ClientContext";
import { PortalClientError } from "../../types";

const useDeleteInsightType = (
  insightTypeId: string,
  options?: UseMutationOptions<void, PortalClientError>
) => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<void, PortalClientError>(
    () => client.delete(`/insight_types/${insightTypeId}`),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["insight_types"]);
        options?.onSuccess?.(data, variables, context);
      },
    }
  );
};

export default useDeleteInsightType;
