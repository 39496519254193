import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { FraudsterUserPayload } from "./types";

const useDenylistUser = (
  options?: UseMutationOptions<void, PortalClientError, FraudsterUserPayload>
) => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<void, PortalClientError, FraudsterUserPayload>(
    ({ id, ...payload }) => client.post(`/users/${id}/denylist`, payload),
    {
      ...options,
      onSuccess: (data, payload, context) => {
        queryClient.invalidateQueries(["users", payload.id]);
        options?.onSuccess?.(data, payload, context);
      },
    }
  );
};
export default useDenylistUser;
