import { Modal } from "antd";
import React from "react";
import { RouteComponentProps } from "react-router-dom";

import AddProgramForm from "../forms/AddProgramForm";

const AddProgramView: React.FunctionComponent<RouteComponentProps> = ({
  history,
}) => {
  return (
    <Modal
      footer={null}
      onCancel={() => history.push("/programs")}
      title="Add Program"
      visible
    >
      <AddProgramForm
        onSuccess={(program) => history.push(`/programs/${program?.id ?? ""}`)}
      />
    </Modal>
  );
};
export default AddProgramView;
