import { Space, Table, Tag, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { memo } from "react";

import {
  AirshipEventsTableData,
  AirshipEventsTableProps,
} from "./AirshipEventsTable.types";
import { showPropertiesColumn } from "./AirshipEventsTable.utils";

const AirshipEventsTable: React.FunctionComponent<AirshipEventsTableProps> = ({
  data,
}) => {
  const tableColumns: ColumnsType<AirshipEventsTableData> = [
    {
      title: "Custom Event",
      dataIndex: "event",
      sorter: (a, b) => a.event.localeCompare(b.event),
      render: (value, record) => (
        <Space>
          {record.isTag && <Tag color="success">Tag</Tag>}
          <Typography.Text code>{value}</Typography.Text>
        </Space>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: (a, b) => a.description.localeCompare(b.description),
      width: "60%",
    },
  ];

  if (showPropertiesColumn(data)) {
    tableColumns.splice(1, 0, {
      title: "Properties",
      dataIndex: "properties",
      render: (values) => (
        <Space direction="vertical">
          {values?.map((value: string) => (
            <Typography.Text code key={value}>
              {value}
            </Typography.Text>
          ))}
        </Space>
      ),
    });
  }

  return (
    <Table
      columns={tableColumns}
      dataSource={data}
      pagination={false}
      rowKey="key"
    />
  );
};

export default memo(AirshipEventsTable);
