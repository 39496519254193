import styled from "styled-components";

const CardMeta = styled.div.attrs({
  "data-testid": "card-meta",
})`
  color: #fe7345;
  font-size: 12px;
  line-height: ${18 / 12};

  strong {
    font-family: PublicSans-SemiBold;
  }
`;

export default CardMeta;
