import { Alert, Space, Spin, Switch, Table, notification } from "antd";
import React from "react";

import Notifications from "../../assets/data/notifications";
import { Experiment, useExperiments, useUpdateExperiment } from "../../client";

const Features: React.FC = () => {
  const { data, isLoading, isError } = useExperiments();
  const { mutate: updateFeature, isLoading: updateIsLoading } =
    useUpdateExperiment({
      onSuccess: () => {
        notification.success({
          message: "Success",
          description: Notifications.UpdateFeature.success,
        });
      },
      onError: () => {
        notification.error({
          message: "Error",
          description: `${Notifications.UpdateFeature.error}`,
        });
      },
    });

  const columns = [
    {
      title: "Category",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Feature",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (value: boolean, row: Experiment) => {
        return (
          <Switch
            checked={value}
            disabled={updateIsLoading}
            onChange={(e) => updateFeature({ ...row, active: e })}
          />
        );
      },
    },
  ];
  return (
    <Space
      direction="vertical"
      size={16}
      style={{ width: "100%", padding: "24px" }}
    >
      {isLoading ? (
        <Spin size="large" tip="Loading" />
      ) : isError ? (
        <Alert message={Notifications.Features.error} type="error" />
      ) : (
        <Table columns={columns} dataSource={data?.data} key="features" />
      )}
    </Space>
  );
};

export default Features;
