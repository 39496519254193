import { DownOutlined } from "@ant-design/icons";
import { Button, Card, Dropdown, notification, Tag } from "antd";
import { Formik, FormikProps } from "formik";
import { Form } from "formik-antd";
import { serialise } from "kitsu-core";
import React, { useState } from "react";
import * as yup from "yup";

import Notifications from "../../assets/data/notifications";
import {
  OutcomeDiary,
  useRemoveSurveyMonkeyFilter,
  useUpdateOutcomeDiary,
  useUploadSurveyMonkeyFilter,
} from "../../client";
import { scheduleValidationSchema } from "../../components/ScheduleBuilder";
import { FormProps } from "../../types";
import { getChangedValues } from "../../utils/objects";
import { getTagColor } from "../../utils/offers";
import {
  cleanOutcomeDiaryForSubmit,
  getStatus,
} from "../../utils/outcomeDiaries";
import EditRecurringActions from "./EditRecurringActions";
import CompletionFormTab from "./EditRecurringTabs/CompletionFormTab";
import DeliveryFormTab from "./EditRecurringTabs/DeliveryFormTab";
import EngagementFormTab from "./EditRecurringTabs/EngagementFormTab";

interface EditRecurringFormProps extends FormProps {
  outcomeDiary: OutcomeDiary;
}
export interface RecurringTabFormProps {
  outcomeDiary: OutcomeDiary;
  formik: FormikProps<OutcomeDiary>;
}

const validationSchema = yup.object({
  card_body: yup.string().max(255).nullable(),
  completion_minutes: yup.number().nullable(),
  card_action_description: yup.string().nullable(),
  points_for_completion: yup.number().nullable(),
  expiration_hours: yup.number().nullable(),
  rateable: yup.boolean().nullable(),
  success_content_heading: yup.string().nullable(),
  success_content_text: yup.string().nullable(),
  tier: yup.string().nullable(),
  entry_schedule: yup.array().of(scheduleValidationSchema).max(1).nullable(),
  revenue_generating: yup.boolean().nullable(),
});

const recurringTabs = [
  {
    key: "engagement",
    tab: "Engagement",
  },
  {
    key: "completion",
    tab: "Completion",
  },
  {
    key: "delivery",
    tab: "Delivery",
  },
];

const formTabComponents: { [tab: string]: React.FC<RecurringTabFormProps> } = {
  engagement: EngagementFormTab,
  completion: CompletionFormTab,
  delivery: DeliveryFormTab,
};

const EditRecurringForm: React.FC<EditRecurringFormProps> = ({
  outcomeDiary,
  onSuccess,
}) => {
  const [activeTab, setActiveTab] = useState("engagement");
  const {
    mutateAsync: uploadSurveyMonkeyFilter,
    isLoading: isLoadingSurveyMonkeyFilter,
  } = useUploadSurveyMonkeyFilter(outcomeDiary.id, {
    onError: () => {
      notification.error({
        message: "Error",
        description: Notifications.UploadZipcodeCSV.error,
      });
    },
  });
  const { mutateAsync: removeSurveyMonkeyFilter } = useRemoveSurveyMonkeyFilter(
    outcomeDiary.id
  );
  const { mutateAsync: updateOutcomeDiary, isLoading } = useUpdateOutcomeDiary(
    outcomeDiary.id,
    {
      onSuccess: () => {
        notification.success({
          message: "Success",
          description: Notifications.UpdateRecurringEngagement.success,
        });
      },
      onError: () => {
        notification.error({
          message: "Error",
          description: Notifications.UpdateRecurringEngagement.error,
        });
      },
    }
  );

  const handleSubmit = async (values: OutcomeDiary) => {
    const changedFields: Partial<OutcomeDiary> = getChangedValues(
      outcomeDiary,
      values
    );
    const { surveymonkey_filter, ...cleanOutcomeDiary } =
      cleanOutcomeDiaryForSubmit(outcomeDiary, changedFields);
    const payload = serialise("outcome_diary", cleanOutcomeDiary);
    if (surveymonkey_filter) {
      await uploadSurveyMonkeyFilter({
        file: surveymonkey_filter,
        filter_field: "postal_code",
      });
    } else if (surveymonkey_filter === null) {
      await removeSurveyMonkeyFilter();
    }
    await updateOutcomeDiary(payload);
    onSuccess?.();
  };

  const FormTabComponent = formTabComponents[activeTab];
  const status = getStatus(outcomeDiary);
  return (
    <Card
      onTabChange={(key) => setActiveTab(key)}
      style={{ margin: "20px 0px" }}
      tabBarExtraContent={
        <React.Fragment>
          <Tag color={getTagColor(status)}>{status.toUpperCase()}</Tag>
          <Dropdown overlay={EditRecurringActions(outcomeDiary)}>
            <Button style={{ marginLeft: "10px" }} type="link">
              Actions <DownOutlined />
            </Button>
          </Dropdown>
        </React.Fragment>
      }
      tabList={recurringTabs}
    >
      <Formik<OutcomeDiary>
        enableReinitialize
        initialValues={outcomeDiary}
        key="edit_recurring_form"
        onSubmit={handleSubmit}
        validateOnChange
        validateOnMount
        validationSchema={validationSchema}
      >
        {(formik: FormikProps<OutcomeDiary>) => (
          <Form
            key="edit_recurring_form"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 14 }}
          >
            <FormTabComponent formik={formik} outcomeDiary={outcomeDiary} />
            <Form.Item name="buttons" style={{ marginTop: "24px" }}>
              <Button
                disabled={
                  !formik.isValid ||
                  formik.isSubmitting ||
                  !formik.dirty ||
                  isLoading ||
                  isLoadingSurveyMonkeyFilter
                }
                htmlType="submit"
                type="primary"
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        )}
      </Formik>
    </Card>
  );
};
export default EditRecurringForm;
