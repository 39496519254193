import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { SurveyQuestion, UpdateSurveyQuestion } from "./types";

const useUpdateSurveyQuestion = (
  offerId: string,
  queryOptions?: UseMutationOptions<
    JsonApiResponse<SurveyQuestion>,
    PortalClientError,
    UpdateSurveyQuestion
  >
) => {
  const client = useClient();

  return useMutation<
    JsonApiResponse<SurveyQuestion>,
    PortalClientError,
    UpdateSurveyQuestion
  >(
    (payload) => client.put(`/offers/${offerId}/survey_question`, payload),
    queryOptions
  );
};

export default useUpdateSurveyQuestion;
