import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { OutcomeDiary, SurveyMonkeyFilterPayload } from "./types";

const useUploadSurveyMonkeyFilter = (
  outcomeDiaryID: string,
  queryOptions?: UseMutationOptions<
    JsonApiResponse<OutcomeDiary>,
    PortalClientError,
    SurveyMonkeyFilterPayload
  >
) => {
  const client = useClient();

  return useMutation<
    JsonApiResponse<OutcomeDiary>,
    PortalClientError,
    SurveyMonkeyFilterPayload
  >((payload) => {
    const formData = new FormData();
    formData.append("file", payload.file);
    formData.append("filter_field", payload.filter_field);
    return client.patch(
      `/outcome_diaries/${outcomeDiaryID}/surveymonkey_response_filter`,
      formData
    );
  }, queryOptions);
};

export default useUploadSurveyMonkeyFilter;
