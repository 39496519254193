import { OutcomeQuestion, OutcomeQuestionType } from "../../client";

const defaultOutcomeQuestions: {
  [key in OutcomeQuestionType]: OutcomeQuestion;
} = {
  checkbox_group: {
    id: "",
    type: "outcome_question",
    configuration: {
      question: "",
      quick_tip: {
        title: "",
        body: "",
      },
      checkboxes: [
        {
          exclusive: false,
          label: "",
          value: "answer_1",
        },
        {
          exclusive: false,
          label: "",
          value: "answer_2",
        },
      ],
    },
    created_at: "",
    identifier: "",
    response_schema: {
      type: "array",
      items: {
        type: "string",
        enum: ["answer_1", "answer_2"],
      },
    },
    ui_element: OutcomeQuestionType.CheckboxGroup,
    updated_at: "",
  },
  radiobuttons: {
    id: "",
    type: "outcome_question",
    configuration: {
      question: "",
      quick_tip: {
        title: "",
        body: "",
      },
      checkboxes: [
        {
          exclusive: true,
          label: "",
          value: "answer_1",
        },
        {
          exclusive: true,
          label: "",
          value: "answer_2",
        },
      ],
    },
    created_at: "",
    identifier: "",
    response_schema: {
      type: "array",
      items: {
        type: "string",
        enum: ["answer_1", "answer_2"],
      },
    },
    ui_element: OutcomeQuestionType.RadioButtons,
    updated_at: "",
  },
  buttons: {
    id: "",
    type: "outcome_question",
    configuration: {
      question: "",
      quick_tip: {
        title: "",
        body: "",
      },
      buttons: [
        {
          label: "Yes",
          value: "yes",
        },
        {
          label: "No",
          value: "no",
        },
      ],
    },
    created_at: "",
    identifier: "",
    response_schema: {
      type: "string",
      enum: ["yes", "no"],
    },
    ui_element: OutcomeQuestionType.Buttons,
    updated_at: "",
  },
};

export default defaultOutcomeQuestions;
