// Support both -maestro and -admin host types. EHP-4194.
const engagementBackendDomain = `https://${window.location.hostname.replace("-admin", "-be").replace("-maestro", "-be")}/api/admin`

const config = {
  local: {
    domain: "https://kizuna-dev-be.platform.evidation.dev/api/admin",
  },
  development: {
    domain: engagementBackendDomain,
  },
  production: {
    domain: engagementBackendDomain,
  },
  staging: {
    domain: engagementBackendDomain,
  },
  test: {
    domain: "https://kizuna-qa-be.platform.evidation.com/api/admin",
  },
};

export default config;
