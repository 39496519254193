import { Select } from "formik-antd";
import type { SelectProps } from "formik-antd";
import React from "react";

import { usePrograms } from "../client";

interface ProgramSelectProps extends SelectProps {
  valueSelector: "id" | "identifier";
}
const ProgramSelect: React.FC<ProgramSelectProps> = ({
  valueSelector,
  ...props
}) => {
  const { data: programs } = usePrograms({
    refetchOnWindowFocus: false,
    staleTime: 10 * 60 * 1000, // 10 minutes
  });

  return (
    <Select {...props}>
      {programs &&
        programs.data.map((program) => (
          <Select.Option key={program.id} value={program[valueSelector]}>
            {program.name}
          </Select.Option>
        ))}
    </Select>
  );
};
export default ProgramSelect;
