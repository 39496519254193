import moment from "moment";

export const daysInWeek = [
  {
    key: "sunday",
    label: "Sun",
  },
  {
    key: "monday",
    label: "Mon",
  },
  {
    key: "tuesday",
    label: "Tue",
  },
  {
    key: "wednesday",
    label: "Wed",
  },
  {
    key: "thursday",
    label: "Thu",
  },
  {
    key: "friday",
    label: "Fri",
  },
  {
    key: "saturday",
    label: "Sat",
  },
];

/**
 * Returns an array from 1 to 31
 */
export const daysInMonth = Array.from(Array(31).keys(), (_a, i) => i + 1);

/**
 * Shorthand function for getting the ordinal name of a number.
 * 3 => "3rd"
 * @param n number to conver
 * @returns ordinal string of provided number
 */
export const getOrdinalName = moment.localeData().ordinal;

export const formatPacificTime = (date: moment.Moment) => {
  return date
    .utc()
    .utcOffset(date.isDST() ? -7 : -8)
    .format("YYYY-MM-DD hh:mma");
};
