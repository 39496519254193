import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { JsonApiResponse } from "../types";
import { Offer } from "./types";

const useSendTestEmail = (
  offerIdentifier: string,
  options?: UseMutationOptions<JsonApiResponse<Offer>, Error, string>
) => {
  const client = useClient();

  return useMutation<JsonApiResponse<Offer>, Error, string>(
    (admin_id: string) =>
      client.post(`/offers/${offerIdentifier}/send_test_emails`, {
        test_admin_ids: [admin_id],
      }),
    options
  );
};
export default useSendTestEmail;
