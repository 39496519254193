import { Card, Col, Row, Space } from "antd";
import { Form, Input, Switch } from "formik-antd";
import React from "react";

import { Image } from "../../../client";
import RatingCard from "../../../components/RatingCard";
import SelectImageButton from "../../../components/SelectImageButton/SelectImageButton";
import { RecurringTabFormProps } from "../EditRecurringForm";

const CompletionFormTab: React.FC<RecurringTabFormProps> = ({ formik }) => {
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Row gutter={[24, { xs: 16 }]} style={{ marginTop: 0 }}>
        <Col span={14}>
          <Card title="Feed Entry">
            <Form.Item label="Feed Entry" name="feed_description">
              <Input name="feed_description" />
            </Form.Item>
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, { xs: 16 }]} style={{ marginTop: 24 }}>
        <Col span={14}>
          <Card title="Completion Success Screen (Deprecated)">
            <Form.Item label="Image" name="diary_success_content_image">
              <SelectImageButton
                disabled
                image={formik.values.diary_success_content_image?.data}
                kind="DiarySuccessContentImage"
                onClearImage={() =>
                  formik.setFieldValue(
                    "diary_success_content_image.data.id",
                    null
                  )
                }
                onSelectImage={(image: Image) =>
                  formik.setFieldValue(
                    "diary_success_content_image.data",
                    image
                  )
                }
              />
            </Form.Item>
            <Form.Item label="Header" name="success_content_heading">
              <Input disabled name="success_content_heading" />
            </Form.Item>
            <Form.Item label="Body" name="success_content_text">
              <Input.TextArea
                autoSize={{ minRows: 6 }}
                disabled
                maxLength={255}
                name="success_content_text"
                showCount
              />
            </Form.Item>
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, { xs: 16 }]} style={{ marginTop: 24 }}>
        <Col span={14}>
          <Card title="Outcome Diary Feedback">
            <Form.Item label="Rating" name="rateable">
              <Switch name="rateable" />
            </Form.Item>
          </Card>
        </Col>
        <Col span={10}>{formik.values.rateable && <RatingCard />}</Col>
      </Row>
    </Space>
  );
};
export default CompletionFormTab;
