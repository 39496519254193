import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { IndexSearchProps, JsonApiResponse } from "../types";
import { User } from "./types";

export type UseUsersParams = {
  user_devices_device_id?: string;
  email?: string;
  first_name?: string;
  id?: number;
  identifier?: string;
  last_name?: string;
};

const useUsers = <TData extends {} = JsonApiResponse<User[]>>(
  params: IndexSearchProps<UseUsersParams>,
  queryOptions?: UseQueryOptions<
    JsonApiResponse<User[]>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<User[]>, PortalClientError, TData>(
    ["users", params],
    () =>
      client.get("/users", {
        params,
      }),
    {
      refetchOnWindowFocus: false,
      ...queryOptions,
    }
  );
};

export default useUsers;
