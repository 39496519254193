import { Button, Upload, UploadFile } from "antd";
const FileUpload = ({
  name,
  defaultValue,
  onChange,
  onRemove,
}: {
  name: string;
  onChange: (value: UploadFile<File>) => void;
  defaultValue: string;
  onRemove: () => void;
}) => {
  return (
    <Upload
      accept="image/jpg, image/png, image/jpeg"
      beforeUpload={() => false}
      defaultFileList={[
        {
          uid: name,
          name,
          status: "done",
          url: defaultValue,
        },
      ]}
      listType="picture-card"
      maxCount={1}
      name={name}
      onChange={({ file }) => onChange(file)}
      onRemove={onRemove}
    >
      <Button>Upload</Button>
    </Upload>
  );
};

export default FileUpload;
