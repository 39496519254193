import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { Admin, EditableAdminAttributes } from "./types";

const useUpdateAdmin = (
  id: string,
  queryOptions?: UseMutationOptions<
    JsonApiResponse<Admin>,
    PortalClientError,
    EditableAdminAttributes
  >
) => {
  const client = useClient();

  return useMutation<
    JsonApiResponse<Admin>,
    PortalClientError,
    EditableAdminAttributes
  >(
    ["admins", id],
    (payload) => client.put(`/admins/${id}`, payload),
    queryOptions
  );
};

export default useUpdateAdmin;
