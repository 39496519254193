import { notification } from "antd";
import React from "react";

import Notifications from "../assets/data/notifications";
import { OutcomeQuestion, useUpdateOutcomeQuestion } from "../client";
import OutcomeQuestionForm from "../forms/OutcomeQuestionForm";
import Modal, { ModalProps } from "./Modal";

interface EditOutcomeQuestionProps extends ModalProps {
  disabled?: boolean;
  onSuccess?: (values: OutcomeQuestion) => void;
  outcomeQuestion: OutcomeQuestion;
}

const EditOutcomeQuestionModal: React.FC<EditOutcomeQuestionProps> = ({
  disabled,
  onSuccess,
  outcomeQuestion,
  ...modalProps
}) => {
  const mutation = useUpdateOutcomeQuestion(outcomeQuestion.id, {
    onSuccess: () => {
      notification.success({
        message: "Success",
        description: Notifications.UpdateOutcomeQuestion.success,
      });
    },
    onError: () => {
      notification.error({
        message: "Error",
        description: Notifications.UpdateOutcomeQuestion.error,
      });
    },
  });

  return (
    <Modal
      destroyOnClose
      footer={null}
      title="Edit Outcome Question"
      width={1200}
      {...modalProps}
    >
      {(close: () => void) => (
        <OutcomeQuestionForm
          disabled={disabled}
          mutation={mutation}
          onSuccess={(response?: OutcomeQuestion) => {
            close();
            if (response && onSuccess) {
              onSuccess(response);
            }
          }}
          outcomeQuestion={outcomeQuestion}
        />
      )}
    </Modal>
  );
};
export default EditOutcomeQuestionModal;
