import { Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";
import { NavLink } from "react-router-dom";

import { OfferStatus, Program, usePrograms } from "../client";
import { formatDateTime } from "../utils/date";
import { getTagColor } from "../utils/offers";
import { getStatus } from "../utils/programs";
import { ColumnFilterProps, ColumnSearchProps } from "./filters";

interface TableProgram extends Program {
  status: OfferStatus;
}

const mapProgramFields = (program: Program): TableProgram => {
  return {
    ...program,
    status: getStatus(program),
  };
};

const getFilterOption = (column: string) => {
  return {
    text: column,
    value: column,
  };
};

const ProgramTable: React.FC = () => {
  const { data: programs, isLoading } = usePrograms({
    refetchOnWindowFocus: false,
  });

  const offerTableColumns: ColumnsType<TableProgram> = [
    {
      title: "ID",
      dataIndex: "id",
      defaultSortOrder: "descend",
      key: "id",
      sorter: (a, b) => parseInt(a.id) - parseInt(b.id),
      ...ColumnSearchProps("id"),
    },
    {
      title: "Identifier",
      dataIndex: "identifier",
      key: "identifier",
      sorter: (a, b) => a.identifier.localeCompare(b.identifier),
      render: (text, record) => (
        <NavLink to={`/programs/${record.id}`}>{text}</NavLink>
      ),
      ...ColumnSearchProps("identifier"),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <NavLink to={`/programs/${record.id}`}>{text}</NavLink>
      ),
      ...ColumnSearchProps("name"),
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
      sorter: (a, b) =>
        a.created_by ? a.created_by.localeCompare(b.created_by ?? "") : -1,
    },
    {
      title: "Start Date",
      dataIndex: "start_at",
      key: "start_at",
      render: (_text, record) =>
        record.start_at ? formatDateTime(new Date(record.start_at)) : "",
      responsive: ["lg"],
      sorter: (a, b) =>
        new Date(a.start_at ?? "").getTime() -
        new Date(b.start_at ?? "").getTime(),
    },
    {
      title: "End Date",
      dataIndex: "end_at",
      key: "end_at",
      render: (_text, record) =>
        record.end_at ? formatDateTime(new Date(record.end_at)) : "",
      responsive: ["lg"],
      sorter: (a, b) =>
        new Date(a.end_at ?? "").getTime() - new Date(b.end_at ?? "").getTime(),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) =>
        a.status ? a.status.localeCompare(b.status ?? "") : -1,
      render: (text, record) => (
        <Tag color={getTagColor(record.status)}>{text.toUpperCase()}</Tag>
      ),
      ...ColumnFilterProps(
        "status",
        Object.values(OfferStatus).map(getFilterOption)
      ),
    },
  ];

  return (
    <Table
      columns={offerTableColumns}
      dataSource={programs?.data?.map(mapProgramFields)}
      loading={isLoading}
      pagination={{
        defaultPageSize: 25,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} programs`,
      }}
      rowKey="id"
    />
  );
};

export default ProgramTable;
