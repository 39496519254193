import { SearchOutlined } from "@ant-design/icons";
import { Input, Button, Space } from "antd";
import React from "react";

/**
 * Antd column search filter. Use this to allow for Antd column searching.
 * @param {*} dataIndex The column data index (ex: 'id', 'name', etc..)
 */
export const ColumnSearchProps = (dataIndex) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => {
          confirm();
        }}
        placeholder={`Search ${dataIndex}`}
        style={{ width: 188, marginBottom: 8, display: "block" }}
        value={selectedKeys[0]}
      />
      <Space>
        <Button
          icon={<SearchOutlined />}
          onClick={() => {
            confirm();
          }}
          size="small"
          style={{ width: 90 }}
          type="primary"
        >
          Search
        </Button>
        <Button
          onClick={() => {
            clearFilters();
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
  onFilter: (value, record) =>
    record[dataIndex]
      ? record[dataIndex].toString().match(new RegExp(value, "i"))?.length > 0
      : false,
});

export const ColumnFilterProps = (dataIndex, filterOptions) => ({
  filters: filterOptions,
  onFilter: (value, record) =>
    record[dataIndex]
      ? record[dataIndex].toString().match(new RegExp(value, "i"))?.length > 0
      : false,
});

export const DefaultFilter = () => null;
