import styled from "styled-components";

const Card = styled.div`
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

export default Card;
