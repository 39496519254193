import { evidationTheme, NativeBaseProvider } from "@evidation-shared/eve";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Layout, Menu } from "antd";
import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { NavLink, Route, Switch, useHistory } from "react-router-dom";

import { ClientProvider, PortalClientError } from "./client";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import AuthorizedContent from "./components/Authorized/AuthorizedContent";
import AuthorizedRoute from "./components/Authorized/AuthorizedRoute";
import CognitoConfigManager from "./components/CognitoConfigManager";
import FaviconLoader from "./components/FaviconLoader";
import FeatureToggleDropdown from "./components/FeatureToggleDropdown";
import Head from "./components/Head";
import SessionManager from "./components/SessionManager";
import DeepLinkListView from "./views/DeepLinkListView";
import EditDeepLinkView from "./views/EditDeepLinkView";
import EditRecurringView from "./views/EditRecurringView";
import ProgramListView from "./views/ProgramListView";
import WorkspaceSettings from "./views/WorkspaceSettingsView";

const AdminListView = React.lazy(() => import("./views/AdminListView"));
const ApplicationsListView = React.lazy(
  () => import("./views/ApplicationsListView")
);
const EditAdminView = React.lazy(() => import("./views/EditAdminView"));
const EditApplicationView = React.lazy(
  () => import("./views/EditApplicationView")
);
const EditExperimentView = React.lazy(
  () => import("./views/EditExperimentView")
);
const EditOfferView = React.lazy(() => import("./views/EditOfferView"));
const EditProgramView = React.lazy(() => import("./views/EditProgramView"));
const EditUserView = React.lazy(
  () => import("./views/EditUserView/EditUserView")
);
const ExperimentListView = React.lazy(
  () => import("./views/ExperimentListView")
);
const HomeView = React.lazy(() => import("./views/HomeView"));
const ImageListView = React.lazy(() => import("./views/ImageListView"));
const InsightTypeListView = React.lazy(
  () => import("./views/InsightTypeListView")
);
const LoginView = React.lazy(() => import("./views/LoginView"));
const LogoutView = React.lazy(() => import("./views/LogoutView"));
const OfferListView = React.lazy(() => import("./views/OfferListView"));
const RecurringListView = React.lazy(() => import("./views/RecurringListView"));
const UserListView = React.lazy(() => import("./views/UserListView"));

const CLIENT_PROVIDER_ENVIRONMENT =
  window.env.REACT_APP_PORTAL_ENVIRONMENT ?? "staging";

// MAYBE? https://blog.logrocket.com/building-a-modal-module-for-react-with-react-router/
const App = () => {
  const history = useHistory();
  const portalAdminClient = React.useMemo(() => new QueryClient(), []);
  const accessTokenRef = React.useRef<string | null>(null);

  const getAccessToken = React.useCallback(() => accessTokenRef.current, []);

  const handleError = (error: unknown) => {
    const portalError = error as PortalClientError;
    if (portalError.status === 401) {
      history.push("/logout");
    }
  };

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <NativeBaseProvider theme={evidationTheme}>
        <QueryClientProvider client={portalAdminClient}>
          <ClientProvider
            environment={CLIENT_PROVIDER_ENVIRONMENT}
            getAccessToken={getAccessToken}
            queryClientOptions={{
              queries: {
                onError: handleError,
              },
              mutations: {
                onError: handleError,
              },
            }}
          >
            <FaviconLoader />
            <CognitoConfigManager>
              <SessionManager
                onChange={(session) => {
                  accessTokenRef.current = session.accessToken;
                }}
              >
                <HelmetProvider>
                  <Head />
                  <Switch>
                    <Route component={LogoutView} exact path="/logout" />
                    <Route component={LoginView} exact path="/login" />
                    <AuthenticatedRoute path="/">
                      <Layout>
                        <Layout.Sider
                          breakpoint="lg"
                          style={{
                            overflow: "auto",
                            height: "100vh",
                            position: "fixed",
                            left: 0,
                          }}
                        >
                          <Menu mode="inline" theme="dark">
                            <AuthorizedContent roles={["admins_admin"]}>
                              <Menu.Item key="1">
                                <NavLink to="/admins">Administrators</NavLink>
                              </Menu.Item>
                            </AuthorizedContent>
                            <AuthorizedContent roles={["application_admin"]}>
                              <Menu.Item key="2">
                                <NavLink to="/applications">
                                  Applications
                                </NavLink>
                              </Menu.Item>
                            </AuthorizedContent>
                            <AuthorizedContent
                              roles={["experiment_config_admin"]}
                            >
                              <Menu.Item key="3">
                                <NavLink to="/experiments">Experiments</NavLink>
                              </Menu.Item>
                            </AuthorizedContent>
                            <AuthorizedContent roles={["image_manager"]}>
                              <Menu.Item key="4">
                                <NavLink to="/images">Image Library</NavLink>
                              </Menu.Item>
                            </AuthorizedContent>
                            <AuthorizedContent roles={["insight_admin"]}>
                              <Menu.Item key="5">
                                <NavLink to="/insight_types">
                                  Insight Types
                                </NavLink>
                              </Menu.Item>
                            </AuthorizedContent>
                            <AuthorizedContent roles={["program_admin"]}>
                              <Menu.Item key="6">
                                <NavLink to="/programs">Programs</NavLink>
                              </Menu.Item>
                            </AuthorizedContent>
                            <AuthorizedContent roles={["offer_admin"]}>
                              <Menu.Item key="7">
                                <NavLink to="/offers">Offers</NavLink>
                              </Menu.Item>
                            </AuthorizedContent>
                            <AuthorizedContent roles={["offer_admin"]}>
                              <Menu.Item key="8">
                                <NavLink to="/recurring">Recurring</NavLink>
                              </Menu.Item>
                            </AuthorizedContent>
                            <AuthorizedContent roles={["user_manager"]}>
                              <Menu.Item key="9">
                                <NavLink to="/users">Users</NavLink>
                              </Menu.Item>
                            </AuthorizedContent>
                            <AuthorizedContent roles={["deepLinkAdmin"]}>
                              <Menu.Item key="10">
                                <NavLink to="/deep_links">Deep Links</NavLink>
                              </Menu.Item>
                            </AuthorizedContent>
                            <AuthorizedContent roles={["superAdmin"]}>
                              <Menu.Item key="11">
                                <NavLink to="/workspace_settings">
                                  Workspace Settings
                                </NavLink>
                              </Menu.Item>
                            </AuthorizedContent>
                            <Menu.Item key="12">
                              <NavLink to="/logout">Logout</NavLink>
                            </Menu.Item>
                          </Menu>
                          {window.env.REACT_APP_PORTAL_ENVIRONMENT ===
                            "staging" && <FeatureToggleDropdown />}
                        </Layout.Sider>
                        <Layout.Content
                          className="site-layout"
                          style={{ marginLeft: 200, minHeight: "100vh" }}
                        >
                          <Switch>
                            <Route component={HomeView} exact path="/" />
                            <AuthorizedRoute
                              component={AdminListView}
                              exact
                              path={["/admins", "/admins/add"]}
                              roles={["admins_admin"]}
                            />
                            <AuthorizedRoute
                              component={EditAdminView}
                              path="/admins/:id"
                              roles={["admins_admin"]}
                            />
                            <AuthorizedRoute
                              component={ApplicationsListView}
                              exact
                              path={["/applications", "/applications/add"]}
                              roles={["application_admin"]}
                            />
                            <AuthorizedRoute
                              component={EditApplicationView}
                              path="/applications/:id"
                              roles={["application_admin"]}
                            />
                            <AuthorizedRoute
                              component={ExperimentListView}
                              exact
                              path={["/experiments", "/experiments/add"]}
                              roles={["experiment_config_admin"]}
                            />
                            <AuthorizedRoute
                              component={EditExperimentView}
                              path="/experiments/:id"
                              roles={["experiment_config_admin"]}
                            />
                            <AuthorizedRoute
                              component={ImageListView}
                              path="/images"
                              roles={["image_manager"]}
                            />
                            <AuthorizedRoute
                              component={InsightTypeListView}
                              exact
                              path={["/insight_types", "/insight_types/add"]}
                              roles={["insight_admin"]}
                            />
                            <AuthorizedRoute
                              component={OfferListView}
                              exact
                              path={["/offers", "/offers/add"]}
                              roles={["offer_admin"]}
                            />
                            <AuthorizedRoute
                              component={EditOfferView}
                              path="/offers/:identifier"
                              roles={["offer_admin"]}
                            />
                            <AuthorizedRoute
                              component={ProgramListView}
                              exact
                              path={["/programs", "/programs/add"]}
                              roles={["program_admin"]}
                            />
                            <AuthorizedRoute
                              component={EditProgramView}
                              path="/programs/:id"
                              roles={["program_admin"]}
                            />
                            <AuthorizedRoute
                              component={RecurringListView}
                              exact
                              path={["/recurring", "/recurring/add"]}
                              roles={["offer_admin"]}
                            />
                            <AuthorizedRoute
                              component={EditRecurringView}
                              path="/recurring_engagement/:id"
                              roles={["offer_admin"]}
                            />
                            <AuthorizedRoute
                              component={EditUserView}
                              path="/users/:id"
                              roles={["user_manager"]}
                            />
                            <AuthorizedRoute
                              component={UserListView}
                              path="/users"
                              roles={["user_manager"]}
                            />
                            <AuthorizedRoute
                              component={DeepLinkListView}
                              exact
                              path={["/deep_links", "/deep_links/add"]}
                              roles={["deepLinkAdmin"]}
                            />
                            <AuthorizedRoute
                              component={EditDeepLinkView}
                              exact
                              path="/deep_links/:id"
                              roles={["deepLinkAdmin"]}
                            />
                            <AuthorizedRoute
                              component={WorkspaceSettings}
                              exact
                              path={[
                                "/workspace_settings",
                                "/workspace_settings/features",
                              ]}
                              roles={["superAdmin"]}
                            />
                          </Switch>
                        </Layout.Content>
                      </Layout>
                    </AuthenticatedRoute>
                  </Switch>
                </HelmetProvider>
              </SessionManager>
            </CognitoConfigManager>
          </ClientProvider>
        </QueryClientProvider>
      </NativeBaseProvider>
    </React.Suspense>
  );
};

export default App;
