import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { OfferKind } from "./types";

const useGetKinds = <TData extends {} = JsonApiResponse<OfferKind[]>>(
  queryOptions?: UseQueryOptions<
    JsonApiResponse<OfferKind[]>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<OfferKind[]>, PortalClientError, TData>(
    ["kinds"],
    () => client.get("/offers/kinds"),
    {
      staleTime: 5 * 60 * 1000,
      ...queryOptions,
    }
  );
};

export default useGetKinds;
