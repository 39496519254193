import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { IndexSearchProps, JsonApiResponse } from "../types";
import {
  PointsExpirationHistory,
  UsePointsExpirationHistoriesParams,
} from "./types";

const usePointsExpirationHistories = <
  TData extends {} = JsonApiResponse<PointsExpirationHistory[]>
>(
  params: IndexSearchProps<UsePointsExpirationHistoriesParams>,
  queryOptions?: UseQueryOptions<
    JsonApiResponse<PointsExpirationHistory[]>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<
    JsonApiResponse<PointsExpirationHistory[]>,
    PortalClientError,
    TData
  >(
    ["points_expiration_histories", params],
    () =>
      client.get("/points_expiration_histories", {
        params,
      }),
    {
      refetchOnWindowFocus: false,
      ...queryOptions,
    }
  );
};

export default usePointsExpirationHistories;
