import {
  DefaultOptions,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { AxiosInstance } from "axios";
import React from "react";

import { MaybePromise } from "../utils/types";
import config from "./config";
import createClient from "./createClient";

export type ClientProviderEnvironment =
  | "development"
  | "production"
  | "staging"
  | "test";

export interface ClientProviderProps {
  appAgent?: string;
  environment?: ClientProviderEnvironment;
  getAccessToken: () => MaybePromise<string | null>;
  queryClientOptions?: DefaultOptions;
  version?: "v1";
}

export interface ClientContextValue {
  client: AxiosInstance;
  queryClient: QueryClient;
}

const ClientContext = React.createContext<ClientContextValue | undefined>(
  undefined
);

const queryClient = new QueryClient();

export const ClientProvider: React.FunctionComponent<ClientProviderProps> = ({
  appAgent = "Portal Admin (v4)",
  children,
  environment = "development",
  getAccessToken = () => null,
  queryClientOptions,
  version = "v1",
}) => {
  const contextValue = React.useMemo(() => {
    const client = createClient({
      appAgent,
      axiosRequestConfig: {
        baseURL: `${config[environment].domain}/${version}`,
      },
      getAuthorization: async () => {
        const token = await getAccessToken();
        if (!token) {
          return undefined;
        }
        return `Bearer ${token}`;
      },
    });

    return { client, queryClient };
  }, [appAgent, environment, getAccessToken, version]);

  React.useEffect(() => {
    if (queryClientOptions) {
      queryClient.setDefaultOptions(queryClientOptions);
    }
  }, [queryClientOptions]);

  return (
    <QueryClientProvider client={queryClient}>
      <ClientContext.Provider value={contextValue}>
        {children}
      </ClientContext.Provider>
    </QueryClientProvider>
  );
};

export const useClientContext = () => {
  const context = React.useContext(ClientContext);

  if (!context) {
    throw new Error("useClientContext must be used within ClientProvider");
  }

  return context;
};

export const useClient = (): AxiosInstance => {
  const context = React.useContext(ClientContext);

  if (!context) {
    throw new Error("useClient must be used within ClientProvider");
  }

  return context.client;
};

export const useQueryClient = (): QueryClient => {
  const context = React.useContext(ClientContext);

  if (!context) {
    throw new Error("useClient must be used within ClientProvider");
  }

  return context.queryClient;
};

export default ClientContext;
