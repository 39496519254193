import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { User } from "./types";

const useSearchUser = (
  options?: UseMutationOptions<JsonApiResponse<User>, PortalClientError, string>
) => {
  const client = useClient();
  return useMutation<JsonApiResponse<User>, PortalClientError, string>(
    ["user"],
    (id: string) => client.get(`/users/${id}`),
    options
  );
};
export default useSearchUser;
