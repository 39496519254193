import {
  Card,
  Col,
  Image as AntImage,
  List,
  Row,
  Typography,
  Input,
  Select,
  Space,
} from "antd";
import { ListGridType } from "antd/lib/list";
import React from "react";

import { Image, useImages } from "../client";
import { formatDate, timeSorter } from "../utils/date";

interface ImageListTableProps {
  grid?: ListGridType;
  selectedImageKind: string;
  selectImage?: (image: Image) => void;
  extra?: React.ReactElement;
}

const defaultGrid = {
  gutter: 16,
  xs: 1,
  sm: 2,
  md: 2,
  lg: 3,
  xl: 4,
  xxl: 6,
};

const ImageListTable: React.FC<ImageListTableProps> = ({
  grid,
  selectedImageKind,
  selectImage,
  extra,
}) => {
  const [filter, setFilter] = React.useState("");
  const [sort, setSort] = React.useState("desc");

  React.useEffect(() => {
    setFilter("");
  }, [selectedImageKind]);

  const { data: imageList, isLoading } = useImages(selectedImageKind, {
    select: (data) => data.data,
  });

  const filteredImages = React.useMemo(() => {
    if (!imageList || imageList.length === 0) {
      return [];
    }

    const filteredImages = filter
      ? imageList.filter((image) =>
          image.name?.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
        )
      : imageList;

    filteredImages.sort((a, b) => timeSorter(a.created_at, b.created_at));
    if (sort === "desc") {
      filteredImages.reverse();
    }

    return filteredImages;
  }, [filter, imageList, sort]);

  return (
    <List
      dataSource={filteredImages}
      grid={grid ?? defaultGrid}
      header={
        <Space direction="vertical" size="middle">
          <Row align="middle" gutter={16}>
            {extra && <Col>{extra}</Col>}
            <Col>
              Showing <b>{imageList?.length}</b> images
            </Col>
          </Row>
          <Row gutter={16}>
            <Col>
              <Input.Search
                onChange={(event) => setFilter(event.target.value)}
                placeholder="Search image name"
                style={{ width: 300 }}
                value={filter}
              />
            </Col>
            <Col>
              <Select
                defaultValue="desc"
                onChange={setSort}
                options={[
                  {
                    value: "desc",
                    label: "Sort: Date added (newest first)",
                  },
                  {
                    value: "asc",
                    label: "Sort: Date added (oldest first)",
                  },
                ]}
                style={{ width: 300 }}
              />
            </Col>
          </Row>
        </Space>
      }
      loading={isLoading}
      renderItem={(image: Image) => (
        <List.Item
          key={image.id}
          onClick={() => selectImage?.(image)}
          style={{ cursor: selectImage ? "pointer" : "default" }}
        >
          <Card>
            <AntImage
              height={200}
              onError={(event) => event.currentTarget.remove()}
              placeholder
              preview={!selectImage}
              src={image.src}
              style={{
                objectFit: "cover",
              }}
              width="100%"
            />
            <Row style={{ marginTop: 16, marginBottom: 8 }}>
              {image.name ? (
                <Typography.Text style={{ fontWeight: "bold" }}>
                  {image.name}
                </Typography.Text>
              ) : (
                <Typography.Text type="secondary">
                  Add image name
                </Typography.Text>
              )}
            </Row>
            <Row>
              <Typography.Text>
                {formatDate(new Date(image.created_at))}
              </Typography.Text>
            </Row>
          </Card>
        </List.Item>
      )}
    />
  );
};
export default ImageListTable;
