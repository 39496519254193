import { Input, FormControl } from "@evidation-shared/eve";

import { TextInputProps } from "./Survey.types";

const TextInput: React.FunctionComponent<TextInputProps> = ({
  placeholder,
}) => {
  return (
    <FormControl>
      <Input placeholder={placeholder} />
    </FormControl>
  );
};

export default TextInput;
