import { Box, Checkbox } from "@evidation-shared/eve";

import OptionGroup from "./OptionGroup";
import type { Option, CheckboxOptionGroupProps } from "./OptionGroup.types";

const CheckboxOptionGroup = (props: CheckboxOptionGroupProps) => {
  const { disabled, options, variant = "default", value = [] } = props;

  return (
    <OptionGroup {...props}>
      <Checkbox.Group value={value}>
        {options.map((option: Option, index) => {
          return (
            <Box key={index.toString() + option.label.toString()} w="100%">
              <Checkbox
                isDisabled={disabled || option.disabled}
                my={variant === "bordered" ? 1 : 2}
                value={option.value}
                variant={variant}
              >
                {option.label}
              </Checkbox>
            </Box>
          );
        })}
      </Checkbox.Group>
    </OptionGroup>
  );
};

export default CheckboxOptionGroup;
