import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { createFormData } from "../../utils";
import { JsonApiResponse } from "../types";
import { Image, CreateImagePayload } from "./types";

const useCreateImage = (
  queryOptions?: UseMutationOptions<
    JsonApiResponse<Image>,
    PortalClientError,
    CreateImagePayload
  >
) => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<
    JsonApiResponse<Image>,
    PortalClientError,
    CreateImagePayload
  >((payload) => client.post(`/images`, createFormData(payload)), {
    ...queryOptions,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["images", variables.kind]);
      queryOptions?.onSuccess?.(data, variables, context);
    },
  });
};

export default useCreateImage;
