import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import type { PortalClientError } from "../..";
import { useClient } from "../../ClientContext";
import { serialize } from "../../utils";
import type { JsonApiResponse, PortalPayload } from "../types";
import type { WorkspaceSettings } from "./useWorkspaceSettings";

type UpdatePayload = PortalPayload<"workspace_setting", WorkspaceSettings>;

const useUpdateWorkspaceSettings = (
  queryOptions?: UseMutationOptions<
    JsonApiResponse<WorkspaceSettings>,
    PortalClientError,
    WorkspaceSettings
  >
) => {
  const client = useClient();

  return useMutation(async (payload) => {
    const data = serialize("workspace_setting", payload);
    try {
      return await client.patch<
        any,
        JsonApiResponse<WorkspaceSettings>,
        UpdatePayload
      >("/workspace_settings", data);
    } catch (error) {
      if ((error as PortalClientError).status === 404) {
        // 404 means the settings haven't been created yet, so we need to POST
        //   to create the relevant records.
        return client.post<
          any,
          JsonApiResponse<WorkspaceSettings>,
          UpdatePayload
        >("/workspace_settings", data);
      }
      throw error;
    }
  }, queryOptions);
};

export default useUpdateWorkspaceSettings;
