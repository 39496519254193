import { isPast } from "date-fns";

import { Program, UpdateProgramPayload } from "../client";
import { OfferStatus } from "../types";

export const cleanProgramForSubmit = (
  originalProgram: Program,
  editedProgram: Program
): UpdateProgramPayload => {
  const {
    // Modify before sending:
    summary_image,
    interstitial_screen,
    // Remaining fields to send:
    ...payloadFields
  } = editedProgram;

  const cleanProgram: UpdateProgramPayload = {
    ...payloadFields,
  };

  if (summary_image?.data) {
    cleanProgram.summary_image_id = summary_image.data.id;
  }

  if (
    interstitial_screen?.data?.card_header ||
    interstitial_screen?.data?.card_text
  ) {
    cleanProgram.interstitial_screen = interstitial_screen.data;
  } else if (originalProgram.interstitial_screen?.data) {
    cleanProgram.interstitial_screen = null;
  }

  return cleanProgram;
};

export const getStatus = (program: Program): OfferStatus => {
  if (program.end_at && isPast(new Date(program.end_at))) {
    return OfferStatus.Expired;
  }
  return program.is_active ? OfferStatus.Live : OfferStatus.Draft;
};
