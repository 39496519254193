import { Badge, Box, Button, Heading, Text } from "@evidation-shared/eve";
import { formatDistanceStrict } from "date-fns";
import React from "react";

import DefaultImage from "../OfferCardWeb/DefaultImage";
import Icon from "../OfferCardWeb/Icon";
import CheckboxGroup from "../Survey/CheckboxGroup";
import RadioGroup from "../Survey/RadioGroup";

export const formatExpiryDateUTC = (date) => {
  const utcDate = new Date(date);
  return formatDistanceStrict(
    new Date(utcDate.valueOf() + utcDate.getTimezoneOffset() * 60 * 1000),
    new Date()
  );
};

const SurveyOfferCardWeb = ({
  action,
  body,
  compensation,
  expiryDate,
  image,
  survey,
}) => {
  const renderSurvey = (survey) => {
    switch (survey.ui_element) {
      case "checkbox_group":
        return survey.configuration.checkboxes ? (
          <CheckboxGroup
            options={survey.configuration.checkboxes}
            variant="default"
          />
        ) : undefined;
      case "radiobuttons":
        return survey.configuration.checkboxes ? (
          <RadioGroup
            options={survey.configuration.checkboxes}
            variant="default"
          />
        ) : undefined;
      default:
        return <React.Fragment>Invalid Component</React.Fragment>;
    }
  };

  return (
    <Box height="100%" shadow={0}>
      {image ? (
        <Box height={140}>
          <img alt="" height="100%" src={image} width="100%" />
        </Box>
      ) : (
        <DefaultImage />
      )}
      <Box backgroundColor="white" flex={1} padding="16px">
        {compensation && (
          <Badge
            colorScheme="success"
            left={14}
            position="absolute"
            size="lg"
            top={-11}
            variant="solid"
          >
            {"+ " + compensation}
          </Badge>
        )}
        <Box
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
          marginTop={2}
          width="100%"
        >
          {/* Typescript is expecting optional props to be defined */}
          {/*@ts-ignore */}
          <Heading size="heading-5">{survey.configuration.question}</Heading>
          <Box alignItems="center" marginLeft={2}>
            <Icon.Menu />
          </Box>
        </Box>
        {/* Typescript is expecting optional props to be defined */}
        {/*@ts-ignore*/}
        <Text color="secondary" fontSize={14} lineHeight={22} marginTop={2}>
          {body}
        </Text>

        <Box mb={4} mt={4}>
          {renderSurvey(survey)}
        </Box>

        <Box
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
        >
          {/* in-line style to satisfy design requirements */}
          {/* @ts-ignore */}
          {expiryDate && (
            <Text style={{ color: "#fe7345" }}>
              {"Expires in " + formatExpiryDateUTC(expiryDate)}
            </Text>
          )}
          <Button height="40px" isDisabled>
            {action}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SurveyOfferCardWeb;
