import { Banner } from "@evidation-shared/eve";
import React from "react";

import CheckboxGroup from "./CheckboxGroup";
import RadioGroup from "./RadioGroup";
import {
  CheckboxConfiguration,
  RadioConfiguration,
  TextInputConfiguration,
  SurveyProps,
} from "./Survey.types";
import TextInput from "./TextInput";

const Survey: React.FunctionComponent<SurveyProps> = ({
  configuration,
  type,
}) => {
  switch (type) {
    case "checkbox":
      return (
        <CheckboxGroup
          options={(configuration as CheckboxConfiguration).options ?? []}
        />
      );
    case "radio":
      return (
        <RadioGroup
          options={(configuration as RadioConfiguration).options ?? []}
        />
      );
    case "textbox":
      return (
        <TextInput
          placeholder={(configuration as TextInputConfiguration).placeholder}
        />
      );
    case "one-click":
    case "program-enrollment":
      return null;
    default:
      return (
        <Banner
          borderRadius={2}
          colorScheme="error"
          description={`Invalid component type: ${type}`}
          variant="light"
        />
      );
  }
};

export default Survey;
