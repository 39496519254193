import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { Admin } from "./types";

const useAdmin = <TData extends {} = JsonApiResponse<Admin>>(
  id: string,
  queryOptions?: UseQueryOptions<
    JsonApiResponse<Admin>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<Admin>, PortalClientError, TData>(
    ["admins", id],
    () => client.get(`/admins/${id}`),
    queryOptions
  );
};

export default useAdmin;
