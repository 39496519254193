import { Box } from "@evidation-shared/eve";
import { Alert, Segmented } from "antd";
import React from "react";
import { ThemeProvider } from "styled-components";

import { getExpiration, getType } from "../../utils/outcomeDiaries";
import Card from "../Card";
import theme from "../OfferCardWeb/theme";
import OneClickOfferCardWeb from "../OneClickOfferCardWeb";
import SurveyOfferCardWeb from "../SurveyOfferCardWeb";
import { OutcomeDiaryCardProps } from "./OutcomeDiaryCard.types";
import {
  formatDuration,
  formatExpiryDate,
  getButtons,
  getReward,
  getSurvey,
} from "./OutcomeDiaryCard.utils";

const OutcomeDiaryCard: React.FunctionComponent<OutcomeDiaryCardProps> = ({
  values,
}) => {
  const [active, setActive] = React.useState<string>("Native");

  const renderOutcomeDiaryCard = (values: any) => {
    const offer = {
      body: values.card_body,
      compensation: values.points_for_completion ?? 0,
      expiryDate: values.expiration_hours
        ? getExpiration(values.expiration_hours)
        : "",
      image: values.diary_card_image?.data?.src,
      title: values.outcome_questions?.data?.[0]?.configuration.question,
    };

    switch (getType(values)) {
      case "buttons":
        return (
          <OneClickOfferCardWeb
            actions={values.outcome_questions?.data[0].configuration?.buttons?.map(
              (button: any) => ({ children: button.label })
            )}
            offer={offer}
          />
        );
      case "checkbox_group":
      case "radiobuttons":
        return (
          <SurveyOfferCardWeb
            {...offer}
            action={values.card_action_description}
            compensation={offer.compensation + " points"}
            survey={values.outcome_questions?.data[0] ?? undefined}
          />
        );
      default:
        return (
          <Alert
            description={
              <React.Fragment>
                <p>
                  The outcome question selected is not currently supported on
                  web. The following outcome questions are supported:
                </p>
                <ul>
                  <li>buttons</li>
                  <li>checkboxes</li>
                  <li>radio buttons</li>
                </ul>
              </React.Fragment>
            }
            message="Invalid outcome question"
            type="error"
          />
        );
    }
  };

  return (
    <React.Fragment>
      <Box flexDirection="row" marginBottom={2}>
        {/* @ts-ignore no ability to override SegmentedValue type */}
        <Segmented
          // @ts-ignore
          onChange={(value) => setActive(value)}
          options={["Native", "Web"]}
          value={active}
        />
      </Box>
      <Box>
        {active === "Web" && (
          <ThemeProvider theme={theme}>
            {renderOutcomeDiaryCard(values)}
          </ThemeProvider>
        )}
        {active === "Native" && (
          <Card
            body={values.card_body}
            buttons={getButtons(values)}
            color={values.color}
            duration={formatDuration(values)}
            expiryDate={formatExpiryDate(values)}
            header={values.outcome_questions?.data?.[0]?.configuration.question}
            image={values.diary_card_image?.data?.src}
            label={values.card_label}
            reward={getReward(values)}
            survey={getSurvey(values)}
          />
        )}
      </Box>
    </React.Fragment>
  );
};

export default OutcomeDiaryCard;
