import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { Role } from "./types";

const useAdminRoles = <TData extends {} = JsonApiResponse<Role[]>>(
  queryOptions?: UseQueryOptions<
    JsonApiResponse<Role[]>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<Role[]>, PortalClientError, TData>(
    ["adminRoles"],
    () => client.get("/admins/roles"),
    queryOptions
  );
};

export default useAdminRoles;
