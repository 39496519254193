import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { Offer } from "./types";

const useCreateOfferCSV = (
  offerId: string,
  queryOptions?: UseMutationOptions<
    JsonApiResponse<Offer>,
    PortalClientError,
    BinaryType
  >
) => {
  const client = useClient();

  return useMutation<JsonApiResponse<Offer>, PortalClientError, BinaryType>(
    (payload) => {
      const formData = new FormData();
      formData.append("file", payload);
      return client.post(`/offers/${offerId}/upload_targeting_csv`, formData);
    },
    queryOptions
  );
};

export default useCreateOfferCSV;
