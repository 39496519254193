import { Space, Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";
import { NavLink } from "react-router-dom";

import { useDeepLinks } from "../client/hooks/deepLinks";
import { DeepLink } from "../client/hooks/deepLinks";

const DeepLinkTable: React.FunctionComponent = () => {
  const { data: deepLinks, isLoading } = useDeepLinks(
    {},
    {
      select: (data) => data.data,
    }
  );

  const columns: ColumnsType<DeepLink> = [
    {
      title: "ID",
      dataIndex: "id",
      defaultSortOrder: "descend",
      sorter: (a, b) => parseInt(a.id) - parseInt(b.id),
      render: (text, record) => (
        <NavLink to={`/deep_links/${record.id}`}>{text}</NavLink>
      ),
      width: 70,
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        <NavLink to={`/deep_links/${record.id}`}>{text}</NavLink>
      ),
    },
    {
      title: "URL",
      dataIndex: "url",
    },
    {
      title: "Min App Version",
      dataIndex: "min_app_version",
    },
    {
      title: "Max App Version",
      dataIndex: "max_app_version",
    },
    {
      title: "Active",
      dataIndex: "active",
      render: (_text, record) =>
        record.active ? (
          <Tag color="success">Active</Tag>
        ) : (
          <Tag color="error">Inactive</Tag>
        ),
    },
  ];

  return (
    <Space direction="vertical" size={16} style={{ width: "100%" }}>
      <Table
        bordered
        columns={columns}
        dataSource={deepLinks}
        loading={isLoading}
        pagination={{
          hideOnSinglePage: true,
          defaultPageSize: 10,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} outcome diaries`,
        }}
        rowKey="id"
      />
    </Space>
  );
};

export default DeepLinkTable;
