import * as yup from "yup";

const scheduleValidationSchema = yup
  .object({
    at: yup
      .array()
      .of(
        yup
          .array()
          .of(yup.number())
          .test({
            name: "daylight-test",
            message: "Can not schedule between 2:00am and 2:59am",
            test(value: number[]) {
              return value[0] !== 2;
            },
          })
      )
      .nullable(),
    day: yup.object().nullable(),
    mday: yup.array().of(yup.number().min(1).max(31)),
    month: yup.array().of(yup.number().min(1).max(12)),
    every: yup.string().oneOf(["day", "week", "month", "year"]).nullable(),
    starts: yup.date().nullable(),
    until: yup.date().nullable(),
    total: yup.number().min(1).nullable(),
    interval: yup.number().min(1).nullable(),
  })
  .required();

export default scheduleValidationSchema;
