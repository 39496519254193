import React from "react";

import { Image } from "../client";
import ImageListTable from "../tables/ImageListTable";
import Modal, { ModalProps } from "./Modal";

interface SelectImageModalProps extends ModalProps {
  kind: string;
  onSelectImage: (image: Image) => void;
}
const SelectImageModal: React.FC<SelectImageModalProps> = ({
  kind,
  onSelectImage,
  ...modalProps
}) => {
  return (
    <Modal footer={null} title="Select Image" {...modalProps} width={800}>
      {(close: () => void) => (
        <div
          style={{
            border: "1px solid #f0f0f0",
            height: "400px",
            overflowX: "hidden",
            overflowY: "auto",
            padding: "15px",
          }}
        >
          <ImageListTable
            grid={{
              gutter: 16,
              column: 3,
            }}
            selectImage={(image) => {
              onSelectImage(image);
              close();
            }}
            selectedImageKind={kind}
          />
        </div>
      )}
    </Modal>
  );
};
export default SelectImageModal;
