import { CloseOutlined } from "@ant-design/icons";
import { Button, Card, Space } from "antd";
import { FieldArray, useFormikContext } from "formik";
import { Form, Input, Switch } from "formik-antd";
import React from "react";

import {
  CheckboxOutcomeQuestionConfiguration,
  OutcomeQuestion,
  OutcomeQuestionType,
} from "../../../client";
import { MAX_OPTIONS, MIN_OPTIONS } from "../outcomeQuestionValidation";
import { OutcomeQuestionConfigurationProps } from "./OutcomeQuestionConfiguration";

const CheckboxOutcomeQuestion: React.FunctionComponent<
  OutcomeQuestionConfigurationProps
> = ({ outcomeQuestion, disabled = false }) => {
  const { values } = useFormikContext<OutcomeQuestion>();
  const configuration =
    values.configuration as CheckboxOutcomeQuestionConfiguration;
  return (
    <FieldArray
      name="configuration.checkboxes"
      render={(arrayHelpers) => (
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          {configuration &&
            configuration.checkboxes &&
            configuration.checkboxes.map((_answer, index) => (
              <Card
                extra={
                  !disabled &&
                  configuration &&
                  configuration.checkboxes.length > MIN_OPTIONS && (
                    <CloseOutlined
                      onClick={() => {
                        arrayHelpers.remove(index);
                        configuration.checkboxes.forEach(
                          (checkbox, index) =>
                            (checkbox.value = `answer_${index + 1}`)
                        );
                      }}
                    />
                  )
                }
                key={index}
                title={`Option ${index + 1}`}
              >
                <Form.Item
                  label="Checkbox Text"
                  name={`configuration.checkboxes.[${index}].label`}
                >
                  <Input
                    disabled={disabled}
                    name={`configuration.checkboxes.[${index}].label`}
                    placeholder={`Option ${index + 1}`}
                  />
                </Form.Item>
                <Form.Item
                  label="Exclusive"
                  name={`configuration.checkboxes.[${index}].exclusive`}
                >
                  <Switch
                    disabled={
                      disabled ||
                      outcomeQuestion.ui_element ===
                        OutcomeQuestionType.RadioButtons
                    }
                    name={`configuration.checkboxes.[${index}].exclusive`}
                  />
                </Form.Item>
              </Card>
            ))}
          <Form.Item name="add_answer">
            <Button
              disabled={
                disabled ||
                (configuration &&
                  configuration.checkboxes.length >= MAX_OPTIONS)
              }
              onClick={() => {
                arrayHelpers.push({
                  exclusive:
                    outcomeQuestion.ui_element ===
                    OutcomeQuestionType.RadioButtons,
                  label: "",
                  value: `answer_${configuration.checkboxes.length + 1}`,
                });
              }}
              type="primary"
            >
              Add Answer
            </Button>
          </Form.Item>
        </Space>
      )}
    />
  );
};

export default CheckboxOutcomeQuestion;
