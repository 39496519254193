import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { IssuePROPayload } from "./types";

const useIssuePRO = (
  options?: UseMutationOptions<void, PortalClientError, IssuePROPayload>
) => {
  const client = useClient();

  return useMutation<void, PortalClientError, IssuePROPayload>(
    ({ userId, outcomeDiaryId }) =>
      client.post(`/outcome_diaries/${outcomeDiaryId}/issue_pro`, {
        user_id: userId,
      }),
    {
      ...options,
    }
  );
};
export default useIssuePRO;
