import { addHours, formatDistanceToNowStrict } from "date-fns";
import { isNil } from "lodash";

import { ButtonsOutcomeQuestionConfiguration } from "../../client";
import { SurveyConfiguration } from "../Survey/Survey.types";
import { EditableOutcomeDiaryValues } from "./OutcomeDiaryCard.types";

// component color will be determined by back end
export const offerCardColor = ["#c890d5", "#faa438", "#ffd747"];

/**
 * Used to format a duration value for an offer card
 * @param values Formik offer values
 * @returns {string | undefined} X min or Y mins
 */
export const formatDuration = (
  values: Pick<EditableOutcomeDiaryValues, "completion_minutes">
) => {
  if (!values.completion_minutes) {
    return undefined;
  }

  return `${values.completion_minutes} min${
    values.completion_minutes === 1 ? "" : "s"
  }`;
};

/**
 * Used to format an expiry date in a human readable format
 * @param values Formik offer values
 * @returns {string | undefined} X days from now
 */
export const formatExpiryDate = (
  values: Pick<EditableOutcomeDiaryValues, "expiration_hours">
) => {
  if (!values.expiration_hours) {
    return undefined;
  }

  return formatDistanceToNowStrict(
    addHours(new Date(), values.expiration_hours),
    {
      addSuffix: true,
    }
  );
};

/**
 * Used to select a set of buttons to display on an offer card
 * @param values Formik offer values
 * @returns {Button[]} an array of card buttons
 */
export const getButtons = (
  values: Pick<
    EditableOutcomeDiaryValues,
    "outcome_questions" | "card_action_description"
  >
) => {
  const configuration = values.outcome_questions?.data?.[0]
    ?.configuration as ButtonsOutcomeQuestionConfiguration;
  if (configuration?.buttons) {
    return configuration.buttons.map((button) => ({
      description: button.label,
    }));
  }

  if (values.card_action_description) {
    return [{ description: values.card_action_description }];
  }

  return [];
};

/**
 * Used to determine the display of the points rewarded to the user
 * @param values Formik offer values
 * @returns {string} a reward string
 */
export const getReward = (
  values: Pick<EditableOutcomeDiaryValues, "points_for_completion">
) => {
  if (isNil(values.points_for_completion)) {
    return undefined;
  }

  return `+${values.points_for_completion} pt${
    values.points_for_completion === 1 ? "" : "s"
  }`;
};

/**
 * Used to transform an outcome diary into a Card survey object
 * @param values Formik outcome diary values
 * @returns a survey object
 */
export const getSurvey = (
  values: EditableOutcomeDiaryValues
): SurveyConfiguration | undefined => {
  if (!values.outcome_questions?.data[0]) {
    return undefined;
  }

  const survey = values.outcome_questions?.data[0];

  if (survey.ui_element === "checkbox_group") {
    return {
      configuration: {
        question: survey.configuration.question,
        // @ts-ignore https://achievements.atlassian.net/browse/APLUS-2180
        options: survey.configuration.checkboxes,
      },
      type: "checkbox",
    };
  } else if (survey.ui_element === "radiobuttons") {
    return {
      configuration: {
        question: survey.configuration.question,
        // @ts-ignore https://achievements.atlassian.net/browse/APLUS-2180
        options: survey.configuration.checkboxes,
      },
      type: "radio",
    };
  }

  return undefined;
};
