import { Col, Row, Tag } from "antd";
import { useField } from "formik";
import React from "react";

import { ScheduleBuilderProps } from "../ScheduleBuilder";
import { daysInWeek } from "../utils";

const WeekView: React.FC<ScheduleBuilderProps> = ({ path }) => {
  const [field, , helpers] = useField<number[]>(`${path}.day`);
  return (
    <Row className="ant-row-middle ant-form-item">
      <Col className="ant-form-item-label" span={6}>
        <label>Repeat On</label>
      </Col>
      <Col span={18}>
        {daysInWeek.map((day, index) => (
          <Tag
            color={field.value?.includes(index) ? "#162D6D" : "#EBEFFF"}
            key={day.key}
            onClick={() => {
              if (field.value?.includes(index)) {
                helpers.setValue(field.value?.filter((val) => val !== index));
              } else {
                helpers.setValue([...(field.value ?? []), index]);
              }
            }}
            style={{
              borderRadius: 20,
              color: field.value?.includes(index) ? "#fff" : "#162D6D",
              cursor: "pointer",
            }}
          >
            {day.label}
          </Tag>
        ))}
      </Col>
    </Row>
  );
};

export default WeekView;
