import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { JsonApiResponse } from "../types";
import { Offer } from "./types";

const useSendEmail = (
  offerIdentifier: string,
  options?: UseMutationOptions<JsonApiResponse<Offer>>
) => {
  const client = useClient();

  return useMutation<JsonApiResponse<Offer>>(
    () => client.post(`/offers/${offerIdentifier}/send_emails`),
    options
  );
};
export default useSendEmail;
