import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { UserApplication } from "./types";

const useUserApplications = <
  TData extends {} = JsonApiResponse<UserApplication[]>
>(
  userId: string,
  options?: UseQueryOptions<
    JsonApiResponse<UserApplication[]>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<UserApplication[]>, PortalClientError, TData>(
    ["userApplications", userId],
    () => client.get("/applications", { params: { user_id: userId } }),
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export default useUserApplications;
