import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { OutcomeDiary } from "./types";

const useRemoveSurveyMonkeyFilter = (
  outcomeDiaryID: string,
  queryOptions?: UseMutationOptions<
    JsonApiResponse<OutcomeDiary>,
    PortalClientError
  >
) => {
  const client = useClient();

  return useMutation<JsonApiResponse<OutcomeDiary>, PortalClientError>(
    () =>
      client.patch(
        `/outcome_diaries/${outcomeDiaryID}/clear_surveymonkey_response_filter`
      ),
    queryOptions
  );
};

export default useRemoveSurveyMonkeyFilter;
