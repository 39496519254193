export const getAirshipTableData = (programIdentifier?: string) => [
  {
    key: "1",
    event: "patient_reported_outcome_scheduled_email",
    description:
      "An email that will be scheduled when the PRO is scheduled to send.",
  },
  {
    key: "2",
    event: "patient_reported_outcome_scheduled_push",
    description:
      "A push notification that will be scheduled when the PRO is scheduled to send. ",
  },
  {
    key: "3",
    event: "patient_reported_outcome_completed",
    description: "PRO is completed by user",
  },
  {
    key: "4",
    event: `hpa_${programIdentifier ?? "<program_identifier>"}__enrolled`,
    description:
      "Airship tag added to user when they enroll into a program (can be used to target airship notification to program users)",
    isTag: true,
  },
];
