import { Option } from "./Survey.types";

export const isExclusive = (value: string, options: Option[]): boolean => {
  return options.find((option) => option.value === value)?.exclusive || false;
};

export const getUpdatedValue = (
  values: string[],
  options: Option[]
): string[] => {
  if (values.length > 0) {
    const newSelectedValue = values[values.length - 1];

    if (values.find((value) => isExclusive(value, options))) {
      return [newSelectedValue];
    }
  }

  return values;
};
