import { Card, Col, PageHeader, Result, Row, Spin } from "antd";
import React from "react";
import { RouteComponentProps } from "react-router-dom";

import { useDeepLink } from "../client";
import EditDeepLinkForm from "../forms/EditDeepLinkForm";

interface EditProgramProps {
  id: string;
}

const EditDeepLinkView: React.FC<RouteComponentProps<EditProgramProps>> = ({
  history,
  match,
}) => {
  const {
    data: deepLink,
    isLoading,
    isError,
  } = useDeepLink(match.params.id, {
    refetchOnWindowFocus: false,
    select: (data) => data.data,
  });

  if (isLoading) {
    return (
      <Row style={{ marginTop: 20 }}>
        <Col flex="auto" push={11} span={2}>
          <Spin size="large" spinning />
        </Col>
      </Row>
    );
  } else if (isError || !deepLink) {
    return (
      <Result
        status="error"
        subTitle="There was a problem loading this deep link, please try again."
        title={`Error loading deep link (ID: ${match.params.id})`}
      />
    );
  }

  return (
    <PageHeader
      onBack={() => history.push("/deep_links")}
      subTitle={`${deepLink.id} - ${deepLink.name}`}
      title="Edit Deep Link"
    >
      <Card>
        <EditDeepLinkForm deepLink={deepLink} />
      </Card>
    </PageHeader>
  );
};
export default EditDeepLinkView;
