import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { UserReferral } from "./types";

const useUserReferrals = <TData extends {} = JsonApiResponse<UserReferral[]>>(
  id: string,
  queryOptions?: UseQueryOptions<
    JsonApiResponse<UserReferral[]>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<JsonApiResponse<UserReferral[]>, PortalClientError, TData>(
    ["userReferrals", id],
    () => client.get(`/users/${id}/referrals`),
    queryOptions
  );
};

export default useUserReferrals;
