import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { Offer, UpdateOffer } from "./types";

const useUpdateOffer = (
  offerId: string,
  queryOptions?: UseMutationOptions<
    JsonApiResponse<Offer>,
    PortalClientError,
    UpdateOffer
  >
) => {
  const client = useClient();

  return useMutation<JsonApiResponse<Offer>, PortalClientError, UpdateOffer>(
    (payload) => client.put(`/offers/${offerId}`, payload),
    queryOptions
  );
};

export default useUpdateOffer;
