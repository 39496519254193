const color = {
  base: {
    darker: "#061c31",
    dark: "#2a3f53",
    medium: "#5b6b7d",
    light: "#e3e3e3",
    lighter: "#f8f8f8",
    lightest: "#fff",
  },
  primary: {
    dark: "#0f5fbc",
    medium: "#20ceff",
    light: "#92ccee",
    lighter: "#d8ecf7",
  },
  secondary: {
    medium: "#37d596",
    lighter: "#c4f0df",
  },
  tertiary: {
    dark: "#ed7b52",
    medium: "#f5ba48",
  },
  error: "#eb3f54",
  success: "#45c071",
  announcement: "#0F5FBC",
};

export const brand = {
  primary: `#0f5fbc`,
};

const theme = {
  breakpoints: ["32em", "48em", "64em", "78em"],
  gridSize: 960,
  space: [0, 4, 8, 16, 24, 32, 64, 128, 256],
  font: {
    primarySerif: `'SourceSerifPro', serif`,
    primarySans: `'PublicSans', sans-serif`,
  },
  breakpoint: {
    small: 720,
    medium: 1070,
    large: 1280,
  },
  brand,
  palette: color,
  color: {
    error: "#eb3f54",
  },
  card: {
    backgroundColor: color.base.lightest,
    borderRadius: 5,
    padding: "1rem",
  },
  dropdown: {
    activeColor: color.base.lighter,
    backgroundColor: color.base.lightest,
    borderColor: color.base.light,
    color: color.base.medium,
    fontSize: 12,
  },
  input: {
    borderColor: color.base.light,
    color: color.base.dark,
  },
  label: {
    color: color.base.medium,
  },
  select: {
    borderColor: color.base.light,
    color: color.base.dark,
  },
};

export default theme;
