import { get } from "lodash";
import styled, { css } from "styled-components";

const CardMedia = styled.div`
  background-color: ${(props) => get(props, "theme.palette.base.lighter")};
  background-position: center center;
  background-size: cover;
  height: ${(props) => `${props.height}px`};
  min-height: ${(props) => `${props.height}px`};
  position: relative;

  ${(props) =>
    props.image &&
    css`
      background-image: url("${props.image}");
    `};
`;

export default CardMedia;
