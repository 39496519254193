import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { PortalClientError } from "../../../client";
import { useClient } from "../../ClientContext";
import { JsonApiResponse } from "../types";
import { User, UserUpdatePayload } from "./types";

const useUpdateUser = (
  id: string,
  queryOptions?: UseMutationOptions<
    JsonApiResponse<User>,
    PortalClientError,
    UserUpdatePayload
  >
) => {
  const queryClient = useQueryClient();
  const client = useClient();

  return useMutation<
    JsonApiResponse<User>,
    PortalClientError,
    UserUpdatePayload
  >(
    (payload) => {
      return client.patch(`/users/${id}`, payload);
    },
    {
      ...queryOptions,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["users", id]);
        queryOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};

export default useUpdateUser;
