import { notification } from "antd";
import { serialise } from "kitsu-core";
import React from "react";

import Notifications from "../assets/data/notifications";
import { useUpdateOutcomeDiary } from "../client";
import Modal, { ModalProps } from "./Modal";

interface ActivateProgramProps extends ModalProps {
  outcomeDiaryId: string;
  activate: boolean;
}

type ModalState = "activate" | "deactivate";

type ModalOptions = {
  [key in ModalState]: {
    title: string;
    body: string;
    successNotificaion?: string;
    errorNotification?: string;
  };
};

const modalText: ModalOptions = {
  activate: {
    title: "Confirm Activation",
    body: "Are you sure you want to activate this recurring engagement?",
    successNotificaion: Notifications.ActivateRecurring.success,
    errorNotification: Notifications.ActivateRecurring.error,
  },
  deactivate: {
    title: "Confirm Deactivation",
    body: "Are you sure you want to deactivate this recurring engagement?",
    successNotificaion: Notifications.DeactivateRecurring.success,
    errorNotification: Notifications.DeactivateRecurring.error,
  },
};

const ActivateRecurringModal: React.FC<ActivateProgramProps> = ({
  outcomeDiaryId,
  activate = true,
  ...modalProps
}) => {
  let modalContent = activate ? modalText.activate : modalText.deactivate;

  const {
    mutateAsync: updateOutcomeDiary,
    isLoading,
    isError,
  } = useUpdateOutcomeDiary(outcomeDiaryId, {
    onSuccess: () => {
      notification.success({
        message: "Success",
        description: modalContent.successNotificaion,
      });
    },
    onError: () => {
      notification.error({
        message: "Error",
        description: modalContent.errorNotification,
      });
    },
  });
  const handleClick = async (close: () => void) => {
    const payload = {
      draft: !activate,
    };
    await updateOutcomeDiary(serialise("outcome_diary", payload));
    close();
  };
  return (
    <Modal
      cancelButtonProps={{
        disabled: isLoading,
      }}
      cancelText="No"
      okButtonProps={{
        loading: isLoading,
      }}
      okText={isError ? "Retry" : "Yes"}
      onClickOk={handleClick}
      title={modalContent.title}
      {...modalProps}
    >
      <p>{modalContent.body}</p>
    </Modal>
  );
};
export default ActivateRecurringModal;
