import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { useClient, useQueryClient } from "../../ClientContext";
import { PortalClientError } from "../../types";

const useDeleteUser = (
  id: string,
  queryOptions?: UseMutationOptions<void, PortalClientError>
) => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<void, PortalClientError>(
    () => client.delete(`/users/${id}`),
    {
      ...queryOptions,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["users"]);
        queryOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};
export default useDeleteUser;
