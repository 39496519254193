import styled from "styled-components";

const PointsBadgeContainer = styled.div`
  top: 0;
  left: 24px;
  position: absolute;
  transform: translateY(-50%);
`;

const OfferCard = {
  PointsBadgeContainer,
};

export default OfferCard;
