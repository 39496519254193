import * as React from "react";

const Menu = (props) => (
  <svg height={5} width={21} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="#5b6b7d" fillRule="evenodd">
      <circle cx={2.5} cy={2.5} r={2.5} />
      <circle cx={10.5} cy={2.5} r={2.5} />
      <circle cx={18.5} cy={2.5} r={2.5} />
    </g>
  </svg>
);

export default Menu;
