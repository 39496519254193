import { PageHeader, Button } from "antd";
import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";

import AddDeepLinkModal from "../modals/AddDeepLinkModal";
import DeepLinkTable from "../tables/DeepLinkTable";

const DeepLinkListView: React.FC<RouteComponentProps> = ({ history }) => {
  return (
    <React.Fragment>
      <PageHeader
        extra={
          <Button
            onClick={() => history.push("/deep_links/add")}
            type="primary"
          >
            Add
          </Button>
        }
        title="Deep Links"
      >
        <DeepLinkTable />
      </PageHeader>
      <Switch>
        <Route component={AddDeepLinkModal} path="/deep_links/add" />
      </Switch>
    </React.Fragment>
  );
};
export default DeepLinkListView;
