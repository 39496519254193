import { AxiosError } from "axios";
import { serialise } from "kitsu-core";

import { PortalPayload } from "./hooks";
import { PortalClientError, PortalErrorResponse } from "./types";

const defaultErrorMessage = "The application has encountered an unknown error.";

/**
 * Creates an error based on a provided Axios error response
 * @param axiosError
 */
export const createError = (axiosError: AxiosError<PortalErrorResponse>) => {
  const error: PortalClientError = new Error(defaultErrorMessage);

  error.errors = getPortalErrors(axiosError);
  error.status = axiosError.response?.status;
  error.statusText = axiosError.response?.statusText;

  error.toJSON = () => ({
    errors: error.errors,
    message: error.message,
    name: error.name,
    status: error.status,
    statusText: error.statusText,
  });

  return error;
};

/**
 * Returns a Portal server error response
 * @param axiosError
 */
export const getPortalError = (axiosError: AxiosError<PortalErrorResponse>) => {
  const errors = getPortalErrors(axiosError);

  if (errors && errors.length > 0) {
    return errors[0];
  }

  return undefined;
};

/**
 * Returns a list of Portal server error responses
 * @param axiosError
 */
const getPortalErrors = (axiosError: AxiosError<PortalErrorResponse>) => {
  if (
    axiosError.response &&
    axiosError.response.data &&
    axiosError.response.data.errors
  ) {
    return axiosError.response.data.errors;
  }

  return undefined;
};

export const createFormData = (
  payload: any,
  form?: FormData,
  parentKey?: string
) => {
  const formData = form || new FormData();
  let formDataKey;
  Object.keys(payload).forEach((key) => {
    const value = payload[key];
    formDataKey = parentKey ? `${parentKey}[${key}]` : key;
    if (typeof payload[key] === "object" && !(payload[key] instanceof File)) {
      if (value) {
        createFormData(value, formData, formDataKey);
      } else {
        formData.append(formDataKey, value);
      }
    } else {
      if (value || value === false || value === "") {
        formData.append(formDataKey, value);
      }
    }
  });

  return formData;
};

/**
 * Type safe wrapper over Kitsu's `serialise` function.
 */
export const serialize = <Type extends string, Data extends {}>(
  type: Type,
  data: Data,
  method?: string
) => serialise(type, data, method) as PortalPayload<Type, Data>;
