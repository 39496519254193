import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { deserialise } from "kitsu-core";

import { MaybePromise } from "../utils/types";
import { PortalErrorResponse } from "./types";
import { createError } from "./utils";

interface ClientOptions {
  appAgent?: string;
  axiosRequestConfig?: AxiosRequestConfig;
  getAuthorization?: () => MaybePromise<string | undefined>;
}

const createClient = (options: ClientOptions) => {
  const client = axios.create(options.axiosRequestConfig);

  client.interceptors.request.use(async (config) => {
    if (options.appAgent) {
      config.headers = Object.assign(
        {
          "App-Agent": options.appAgent,
        },
        config.headers
      );
    }

    if (typeof options.getAuthorization === "function") {
      const authorization = await options.getAuthorization();

      if (authorization) {
        config.headers = Object.assign(
          {
            Authorization: authorization,
          },
          config.headers
        );
      }
    }

    return config;
  });

  client.interceptors.response.use(
    (response: AxiosResponse) => deserialise(response?.data),
    (axiosError: AxiosError<PortalErrorResponse>) => {
      const error = createError(axiosError);
      throw error;
    }
  );

  return client;
};

export default createClient;
