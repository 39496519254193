import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { Program, UpdateProgram } from "./types";

const useUpdateProgram = (
  programId: string,
  options?: UseMutationOptions<
    JsonApiResponse<Program>,
    PortalClientError,
    UpdateProgram
  >
) => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<
    JsonApiResponse<Program>,
    PortalClientError,
    UpdateProgram
  >((payload) => client.put(`/programs/${programId}`, payload), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["programs", programId]);
      options?.onSuccess?.(data, variables, context);
    },
  });
};

export default useUpdateProgram;
