import { Box, Radio, FormControl, VStack } from "@evidation-shared/eve";
import React from "react";

import { RadioGroupProps } from "./Survey.types";

const RadioGroup = ({ options, variant = "bordered" }: RadioGroupProps) => {
  const [formValue, setFormValue] = React.useState();

  // @ts-ignore
  const handleChange = (value) => {
    setFormValue(value);
  };

  return (
    <FormControl>
      <Radio.Group name="checkbox" onChange={handleChange} value={formValue}>
        <VStack space={variant === "default" ? 4 : 2} width="100%">
          {options.map((option) => {
            return (
              <Box key={option.label} w="100%">
                <Radio value={option.value} variant={variant}>
                  {option.label}
                </Radio>
              </Box>
            );
          })}
        </VStack>
      </Radio.Group>
    </FormControl>
  );
};

export default RadioGroup;
