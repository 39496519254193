import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { JsonApiResponse } from "../types";
import { UserProgramEnrollment } from "./types";

const useUserPrograms = <
  TData extends {} = JsonApiResponse<UserProgramEnrollment[]>
>(
  id: string,
  queryOptions?: UseQueryOptions<
    JsonApiResponse<UserProgramEnrollment[]>,
    PortalClientError,
    TData
  >
) => {
  const client = useClient();

  return useQuery<
    JsonApiResponse<UserProgramEnrollment[]>,
    PortalClientError,
    TData
  >(
    ["userPrograms", id],
    () => client.get(`/users/${id}/program_enrollments`),
    {
      refetchOnWindowFocus: false,
      ...queryOptions,
    }
  );
};

export default useUserPrograms;
