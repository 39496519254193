import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { DeepLinkData } from "./types";
import deepLinkKeys from "./utils";

const useDeepLink = <TData extends {} = DeepLinkData>(
  id: string,
  queryOptions?: UseQueryOptions<DeepLinkData, PortalClientError, TData>
) => {
  const client = useClient();

  return useQuery<DeepLinkData, PortalClientError, TData>(
    deepLinkKeys.one(id),
    () => client.get(`/deep_links/${id}`),
    {
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
      ...queryOptions,
    }
  );
};

export default useDeepLink;
