import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { serialise } from "kitsu-core";

import { useClient, useQueryClient } from "../../ClientContext";
import { PortalClientError } from "../../types";
import { ProgramUnenrollmentPayload } from "./types";

const useUnenrollProgram = (
  options?: UseMutationOptions<
    void,
    PortalClientError,
    ProgramUnenrollmentPayload
  >
) => {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation<void, PortalClientError, ProgramUnenrollmentPayload>(
    ({ id, program_enrollment_id, ...payload }) =>
      client.patch(
        `/users/${id}/program_enrollments/${program_enrollment_id}`,
        serialise("program_enrollment", payload)
      ),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["userPrograms", variables.id]);
        options?.onSuccess?.(data, variables, context);
      },
    }
  );
};
export default useUnenrollProgram;
