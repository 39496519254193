import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { useClient } from "../../ClientContext";
import { PortalClientError } from "../../types";

const useDeleteAdmin = (
  id: string,
  queryOptions?: UseMutationOptions<void, PortalClientError>
) => {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation<void, PortalClientError>(
    ["admins", id],
    () => client.delete(`/admins/${id}`),
    {
      ...queryOptions,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["admins"]);
        queryOptions?.onSuccess?.(data, variables, context);
      },
    }
  );
};

export default useDeleteAdmin;
